import React, { useState } from 'react';
import StyledButton from '../../../Styled/PrimaryStyledButton';
import StyledInput from '../../../Styled/FormInputStyled';
import UploadSectionData from './UploadSectionData';
import Image from '../ImageWithUpload';

import styled from 'styled-components';

const StyledAddSection = styled.div`
  display: grid;
  grid-template-columns: 150px 120px 200px 250px 250px;
  align-items: center;
`;

interface ISubmitData {
  name: string;
  data: string;
  image: string;
}

interface IProps {
  onSubmit: (submitData: ISubmitData) => void;
}

const CreateSection = (props: IProps) => {
  const [name, setName] = useState('');
  const [data, setData] = useState('');
  const [image, setImage] = useState('');

  const onSubmit = () => {
    if (!data) {
      console.log('data was not uploaded!');
      return;
    }
    setName('');
    setData('');
    setImage('');
    props.onSubmit({ name, data, image });
  };

  const handleUploadFile = (file: File) => {
    const newImageUrl = URL.createObjectURL(file);
    setImage(newImageUrl);
  };

  return (
    <StyledAddSection>
      <h2>Create section</h2>
      <Image image={image} onChange={handleUploadFile} />
      <StyledInput
        value={name}
        onChange={(e) => setName(e.currentTarget.value)}
        placeholder="name..."
      />
      <UploadSectionData data={data} onSubmit={(v) => setData(v)} />
      <StyledButton onClick={onSubmit} disabled={!data}>
        ADD
      </StyledButton>
    </StyledAddSection>
  );
};

export default CreateSection;
