import InputV1 from '../../../../Common/Inputs/InputV1/InputV1';

import s from './ProductsDynamicForm.module.scss';

function ProductsDynamicForm(props) {
  if (props.productsInfoList.length === 0) {
    return <p className={s.noProductsMessage}>No products added yet ...</p>;
  }

  return (
    <div className={s.container}>
      <div className={s.labelContainer}>
        <p className={s.productName}>Product Name</p>
        <p className={s.statementDescriptor}>Statement descriptor</p>
        <p className={s.price}>Price</p>
        <p className={s.currency}>Currency</p>
      </div>
      {props.productsInfoList.map((object, index) => {
        return (
          <div key={index} className={s.box}>
            <div className={`${s.input} ${s.productInput}`}>
              <InputV1
                readonly
                width={230}
                placeholder={'My product name'}
                value={object.name}
                setState={(v) => {}}
                type={'text'}
                name={'name'}
                index={index}
              />
            </div>
            <div className={`${s.input} ${s.statementInput}`}>
              <InputV1
                readonly
                width={300}
                placeholder={'My statement descriptor'}
                value={object.descriptor}
                setState={(v) => {}}
                type={'text'}
                name={'descriptor'}
                index={index}
                maxLength={22}
              />
            </div>
            <div className={`${s.input} ${s.priceInput}`}>
              <InputV1
                readonly
                width={80}
                placeholder={'100'}
                value={object.price}
                setState={(v) => {}}
                type={'text'}
                name={'price'}
                index={index}
                allowOnlyNumbers={true}
                error={props.hasSavePressed && object.price.length < 1}
              />
            </div>
            <div className={`${s.input} ${s.priceInput}`}>
              <InputV1
                readonly
                width={80}
                placeholder={'USD'}
                value={object.currency}
                setState={(v) => {}}
                type={'text'}
                name={'currency'}
                index={index}
                allowOnlyNumbers={true}
                error={props.hasSavePressed && object.price.length < 1}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ProductsDynamicForm;
