import clsx from 'clsx';
import {
  useMemo,
  useState,
  ReactNode,
  HTMLAttributes,
  DetailedHTMLProps,
} from 'react';
import { ReactComponent as Arrow } from 'Assets/icons/arrow.svg';
import { ReactComponent as CheckMarkIcon } from 'Assets/icons/checkMark.svg';
import useElementClickedOutside from 'Hooks/useElementClickedOutside';

import styles from './Dropdown.module.scss';

interface IProps<T>
  extends Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    'onChange'
  > {
  value?: T;
  label: string;
  type?: 'stroke' | 'one-line';
  theme?: 'light' | 'dark';
  height?: number;
  options: { label: ReactNode; value: T }[];
  onChange: (newValue: T) => void;
  hasErrors?: boolean;
}

const Dropdown = <T extends ReactNode>({
  hasErrors,
  className,
  onChange,
  options,
  height = 50,
  theme = 'light',
  label,
  value,
  type = 'one-line',
  ...rest
}: IProps<T>) => {
  const [open, setOpen] = useState(false);

  const { ref } = useElementClickedOutside(() => setOpen(false));

  const toggleDropdown = () => {
    setOpen(!open);
  };

  const handleSelect = (option: T) => {
    setOpen(false);
    onChange(option);
  };

  const selectedOptionLabel = useMemo(() => {
    const option = options.find((opt) => opt.value === value);
    if (option) return option.label;

    return value;
  }, [value, options]);

  return (
    <div ref={ref} className={clsx(styles.dropdown, className)} {...rest}>
      <span
        className={clsx(
          styles.selected,
          { [styles.openState]: open },
          {
            [styles.error]: hasErrors,
          },
          styles[`border-${type}`],
          styles[theme]
        )}
        onClick={toggleDropdown}
        style={{ height }}
      >
        {selectedOptionLabel || <span>{label}</span>} <Arrow />
      </span>
      {open && (
        <ul className={clsx(styles.optionsList, styles[theme])}>
          {options.map((option, index) =>
            option.label !== '<split>' ? (
              <li
                key={index}
                onClick={() => handleSelect(option.value)}
                style={{ height }}
              >
                {option.value === value && <CheckMarkIcon />}
                {option.label}
              </li>
            ) : (
              <div className={styles.optionSplit} key={`split-${index}`}></div>
            )
          )}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
