import React from 'react';
import s from './Common.module.scss';

interface IProps {
  name: string;
  value: string;
  onChange: (newValue: string) => void;

  hasError?: boolean;
}

const AiInput = (props: IProps) => {
  return (
    <div className={s.inputBlock}>
      <label>{props.name}</label>
      <input
        type="text"
        placeholder={props.name}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
      />
    </div>
  );
};

export default AiInput;
