import { ElementType } from 'react';
import { ReactComponent as LeftIcon } from 'Assets/icons/arrowLeft.svg';
import FunnelPageIcon from '../FunnelPageIcon/FunnelPageIcon';

import styles from './FunnelPageFlow.module.scss';

interface IProps {
  pages: {
    Icon: ElementType;
    color: string;
    title: string;
    subtitle: string;
  }[];
}

const FunnelPageFlow = ({ pages }: IProps) => {
  return (
    <div className={styles.container}>
      {pages.map((page, index) => (
        <div className={styles.card} key={index}>
          <FunnelPageIcon Icon={page.Icon} color={page.color} size={40} />
          <div className={styles.textWrapper}>
            <div className={styles.title}>{page.title}</div>
            <div className={styles.subtitle}>{page.subtitle}</div>
          </div>
          {index !== pages.length - 1 && (
            <div className={styles.iconWrapper}>
              <LeftIcon />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FunnelPageFlow;
