import Button from '../Common/DomainButton/DomainButton';
import closeSvg from '../../Assets/icons/close.svg';
import sadSmileSvg from '../../Assets/icons/sadSmile.svg';

import s from './ConfirmArchivationPopup.module.scss';

interface IProps {
  title?: string;
  description: string;
  onClose: () => void;
  open: boolean;
  onDonePressed: () => void;
}

const ConfirmArchivationPopup = ({
  open,
  title = 'Are You Sure?',
  description,
  onClose,
  onDonePressed,
}: IProps) => {
  return open ? (
    <div className={s.confirmArchiveFunnelPopupWrapper}>
      <div className={s.modal}>
        <img className={s.closeIcon} src={closeSvg} alt="x" onClick={onClose} />
        <img className={s.worldIcon} src={sadSmileSvg} alt="O" />
        <h1 className={s.headerText}>{title}</h1>
        <p className={s.deleteDomainText}>{description}</p>
        <div className={s.buttonsBlock}>
          <Button name="Yes" onClick={onDonePressed} isBlackBtn={true} />
          <Button name="No" onClick={onClose} />
        </div>
      </div>
    </div>
  ) : null;
};

export default ConfirmArchivationPopup;
