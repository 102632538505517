import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IUserProduct } from 'types';
import { RootState } from '../rootReducer';

export const UPDATE_SETTINGS_POPUP = 'UPDATE_POPUP';

type DispatchType = ThunkDispatch<RootState, void, Action>;

export const bookSettingsPopupAction = (payload: {
  open?: boolean;
  initialState?: IUserProduct;
}) => async (dispatch: DispatchType) => {
  const stateAction = {
    type: UPDATE_SETTINGS_POPUP,
    payload,
  };
  dispatch(stateAction);
};
