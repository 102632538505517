import React from 'react';
import { ReactComponent as TagIcon } from 'Assets/icons/Tag.svg';
import { ReactComponent as ArrowDown } from 'Assets/icons/ArrowDown.svg';
import { ReactComponent as MailIcon } from 'Assets/icons/email.svg';
import { ReactComponent as CalendarIcon } from 'Assets/icons/calendarContact.svg';

import s from '../TableHeaderTag/TableHeaderTag.module.scss';

export function TableHeaderTag() {
  return (
    <>
      <div className={s.personTable}>
        <input type="checkbox" className={s.selected} />
        <div className={s.tagName}>
          <TagIcon /> Tag Name
          <div className={s.points}>
            <ArrowDown className={s.rotated} />
            <ArrowDown className={s.poiner} />
          </div>
        </div>
        <div className={s.tagName}>
          <MailIcon /> Contacts
          <div className={s.points}>
            <ArrowDown className={s.rotated} />
            <ArrowDown className={s.poiner} />
          </div>
        </div>
        <div className={s.tagName}>
          <CalendarIcon /> Created
          <div className={s.points}>
            <ArrowDown className={s.rotated} />
            <ArrowDown className={s.poiner} />
          </div>
        </div>
      </div>
    </>
  );
}
