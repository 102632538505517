import clsx from 'clsx';
import { ReactComponent as CloseSvg } from 'Assets/icons/book/close.svg';

import styles from './Popup.module.scss';

interface IProps {
  onClose: () => void;
  children: React.ReactNode;
  popupClassName?: string;
  wrapperClassName?: string;
}

const Popup = ({
  onClose,
  children,
  popupClassName,
  wrapperClassName,
}: IProps) => {
  return (
    <div className={clsx(styles.popup, popupClassName)}>
      <div className={styles.overlay} onClick={onClose} />
      <div className={clsx(styles.wrapper, wrapperClassName)}>
        <CloseSvg className={styles.closeIcon} onClick={onClose} />
        {children}
      </div>
    </div>
  );
};

export default Popup;
