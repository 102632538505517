import React from 'react';
import Chart from 'chart.js/auto';
import { ChartData, CategoryScale, ChartOptions } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import s from './AdminStatistic.module.scss';

Chart.register(CategoryScale);

interface IProps {
  data: number[];
  labels: string[];
  unit: string;
}

const ChartComponent = (props: IProps) => {
  const data: ChartData<'bar'> = {
    labels: props.labels,
    datasets: [
      {
        label: props.unit,
        data: props.data,
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    elements: {
      bar: {
        backgroundColor: 'rgba(77, 135, 167, 0.3',
        hoverBackgroundColor: 'rgba(77, 135, 167, 0.6',
        borderWidth: { top: 6, right: 0, bottom: 0, left: 0 },
        borderColor: '#4D87A7',
      },
    },

    responsive: true,
    scales: {
      x: {
        ticks: {
          maxRotation: 0,
          autoSkip: false,
          callback: function (value: any, index: number, values: any) {
            if (index === 0 || index === values.length - 1) {
              return props.labels[index];
            } else {
              return '';
            }
          },
          color: '#4D87A7',
          font: {
            size: 12,
            family: 'Inter',
            weight: '500',
          },
        },
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        xAlign: 'center',
        caretSize: 0,
        backgroundColor: '#4D87A7',
        titleAlign: 'center',
        titleFont: {
          size: 14,
          family: 'Inter',
          weight: '400',
        },
        bodyAlign: 'center',
        bodyFont: {
          size: 12,
          family: 'Inter',
          weight: '600',
        },

        displayColors: false,
        callbacks: {
          label: (tooltipItem) => {
            return `${tooltipItem.formattedValue}`;
          },
        },
      },
    },
  };
  return (
    <div className={s.chardContent}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default ChartComponent;
