import styled from 'styled-components';

interface IProps {
  disabled?: boolean;
}

export default styled.button`
  width: 200px;
  max-width: 100%;
  height: 40px;
  border: 1px #bdbdbd solid;
  background: transparent;
  border-radius: 2px;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  cursor: ${(props: IProps) => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background: #fff;
  }
`;
