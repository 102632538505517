import {
  SET_USER_PRODUCTS,
  PRODUCTS_LOADING,
  UPDATE_POPUP,
} from './productActions';
import { PayloadAction } from '@reduxjs/toolkit';
import { IUserProduct } from 'types';

export interface IProductsState {
  items: IUserProduct[];
  loading: boolean;
  popup: {
    open: boolean;
    initialState?: IUserProduct;
  };
}

const initialState: IProductsState = {
  items: [],
  loading: false,
  popup: {
    open: false,
    initialState: undefined,
  },
};

const productReducer = (
  state = initialState,
  action: PayloadAction<IUserProduct[] | boolean>
): IProductsState => {
  switch (action.type) {
    case SET_USER_PRODUCTS:
      const items = action.payload as IUserProduct[];
      return {
        ...state,
        items,
      };
    case PRODUCTS_LOADING:
      return {
        ...state,
        loading: action.payload as boolean,
      };
    case UPDATE_POPUP:
      let newPopup = { ...initialState.popup };
      if (typeof action.payload !== 'boolean') {
        newPopup = { ...newPopup, ...action.payload };
      }
      return {
        ...state,
        popup: newPopup,
      };
    default:
      return state;
  }
};

export { initialState, productReducer };
