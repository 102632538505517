import React, { useState, useEffect } from 'react';
import { RootState } from '../../store/rootStore';
import { connect } from 'react-redux';
import s from './Mailchimp.module.scss';
import PrimaryButton from '../Common/PrimaryButton/PrimaryButton';
import InputV1 from '../Common/Inputs/InputV1/InputV1';
import {
  fetchUserCustomData,
  updateUserCustomData,
} from '../../store/user/userActions';
import { IUserDetails } from 'types';
import MailchimpIntegrationSuccesfullPopup from 'Components/Domains/MailchimpIntegrationSuccesfullPopup';
import Spinner from 'Components/Common/Spinner/Spinner';
import { ReactComponent as MailchimpLogoSvg } from '../../Assets/icons/mailchimpLogo.svg';

interface IProps {
  userDetails: IUserDetails;
  isLoading: boolean;
  isMailchimpIntegrationSuccesfull: true | null | false;
  fetchUserDetails: () => void;
  updateUserMailchimp: (apiKey: string) => void;
}

const MailchimpComponent = (props: IProps) => {
  const [mailchimpApiKey, setMailchimpApiKey] = useState('');
  const [
    mailchimpIntegrationSuccesfullPopupOpen,
    setMailchimpIntegrationSuccesfullPopupOpen,
  ] = useState(false);
  const [
    isMailchimpIntegrationSuccesfull,
    setIsMailchimpIntegrationSuccesfull,
  ] = useState<boolean>(false);
  const [keyError, setKeyError] = useState(false);

  const handleSave = () => {
    setKeyError(false);
    const reg = /^[a-zA-Z0-9]{32}-\w{4,7}$/;
    if (!reg.test(mailchimpApiKey)) {
      setKeyError(true);
      return;
    }
    props.updateUserMailchimp(mailchimpApiKey);
  };

  useEffect(() => {
    if (props.isMailchimpIntegrationSuccesfull) {
      setMailchimpIntegrationSuccesfullPopupOpen(true);
      setIsMailchimpIntegrationSuccesfull(true);
    }
    if (props.isMailchimpIntegrationSuccesfull === false) {
      setMailchimpIntegrationSuccesfullPopupOpen(true);
      setIsMailchimpIntegrationSuccesfull(false);
    }
  }, [props.isMailchimpIntegrationSuccesfull]);

  useEffect(() => {
    props.fetchUserDetails();
  }, []);
  useEffect(() => {
    setMailchimpApiKey(props.userDetails.customData?.mailchimpApiKey || '');
  }, [props.userDetails.customData]);

  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <h2 className={s.mainHeader}>Email Marketing</h2>
        <MailchimpLogoSvg className={s.mailchimpLogo} />
        <h3 className={s.contentHeader}>Mailchimp integration</h3>
        <div className={s.input}>
          <InputV1
            width={330}
            placeholder={'Mailchimp API Key'}
            value={mailchimpApiKey}
            setState={setMailchimpApiKey}
            type={'text'}
          />
          {keyError && <span className={s.error}>validation error</span>}
        </div>
        <div className={s.helpBlock}>
          Need an API Key for Mailchimp?{' '}
          <a href="https://mailchimp.com/help/about-api-keys/" target="_blank">
            Learn More Here
          </a>
        </div>
        <div className={s.buttonBlock}>
          {props.isLoading ? (
            <Spinner size={50} />
          ) : (
            <PrimaryButton
              width={158}
              onClick={() => handleSave()}
              isBlackBtn={true}
              borderRadius={25}
            >
              Save
            </PrimaryButton>
          )}
        </div>
        <MailchimpIntegrationSuccesfullPopup
          isOpen={mailchimpIntegrationSuccesfullPopupOpen}
          onClose={() => setMailchimpIntegrationSuccesfullPopupOpen(false)}
          isMailchimpIntegrationSuccesfull={isMailchimpIntegrationSuccesfull}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  userDetails: state.user.details,
  isLoading: state.user.isLoading,
  isMailchimpIntegrationSuccesfull: state.user.isMailchimpIntegrationSuccesfull,
});

const mapDispatchToProps = {
  fetchUserDetails: () => fetchUserCustomData(),
  updateUserMailchimp: (apiKey: string) =>
    updateUserCustomData('mailchimpApiKey', apiKey),
};

export default connect(mapStateToProps, mapDispatchToProps)(MailchimpComponent);
