export const FETCH_FUNNELS_WITH_PAGINATION_QUERY = {
  operationName: 'fetchFunnelsWithPagination',
  query: `query fetchFunnelsWithPagination ($skip: Float!, $limit: Float!, $search: String, $sortBy: String, $sortAsc: Boolean) {
    fetchFunnelsWithPagination(skip: $skip, limit: $limit, search: $search, sortBy: $sortBy, sortAsc: $sortAsc) {
      funnels {
        _id
        publicId
        name
        description
        title
        url
        archived
        mailchimpAPI
        mailchimpAudienceId
        mailchimpUsername
        domain
        certificateArn
        headerScript
        footerScript
        stripePublicKey
        stripeSecretKey
        createdAt
        customSubDomain 
        thumbnail
       }
       total
    }
  }`,
};
