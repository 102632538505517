import { useHistory } from 'react-router-dom';
import Button from 'UILib/Button/Button';
import ProductsDynamicForm from './ProductsDynamicForm/ProductsDynamicForm';

import s from './ProductsTab.module.scss';

const ProductsTab = (props) => {
  const history = useHistory();

  return (
    <div className={s.container}>
      <span>
        We've improved this feature and made it global! <br />
        Now you can create a product and add it into any funnel.
      </span>
      <ProductsDynamicForm productsInfoList={props.productsInfoList} />
      <span className={s.improveText}>
        Products moved to a separate section in the sidebar.
      </span>
      <Button
        appearance="solid"
        onClick={() => history.push('/console/settings/products')}
      >
        Go to Products
      </Button>
    </div>
  );
};

export default ProductsTab;
