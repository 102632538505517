import { PayloadAction } from '@reduxjs/toolkit';
import { IUserProduct } from 'types';
import { UPDATE_SETTINGS_POPUP } from './booksActions';

export interface IBooksState {
  settingsPopup: {
    open: boolean;
    initialState?: IUserProduct;
  };
}

const initialState: IBooksState = {
  settingsPopup: {
    open: false,
    initialState: undefined,
  },
};

const booksReducer = (
  state = initialState,
  action: PayloadAction<{
    open: boolean;
    initialState?: IUserProduct;
  }>
): IBooksState => {
  switch (action.type) {
    case UPDATE_SETTINGS_POPUP:
      let newPopup = { ...initialState.settingsPopup };
      if (typeof action.payload !== 'boolean') {
        newPopup = { ...newPopup, ...action.payload };
      }
      return {
        ...state,
        settingsPopup: newPopup,
      };
    default:
      return state;
  }
};

export { initialState, booksReducer };
