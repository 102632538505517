import React, { useState, useEffect } from 'react';
import QUERIES from 'graphql/queries';
import { graphQlCall } from 'graphql/utils';
import s from './AdminBookRequests.module.scss';

interface IBookRequest {
  _id: string;
  title: string;
  description: string;
  tone: string;
  createdAt: number;
}

const AdminBookRequests = () => {
  const [showAllText, setShowAllText] = useState<string | null>(null);
  const [bookRequests, setBookRequests] = useState<IBookRequest[]>([]);

  useEffect(() => {
    fetchBookRequests();
  }, []);

  const fetchBookRequests = async () => {
    const res = await graphQlCall({
      queryTemplateObject: QUERIES.GET_ALL_BOOK_REQUESTS,
      headerType: 'USER-AUTH',
    });

    const result = res.requests as IBookRequest[];
    setBookRequests(result);
  };

  const maxTextSize = 300;

  return (
    <div>
      <h2 className={s.header}>Book Requests</h2>
      <div className={s.table}>
        <div className={s.item + ' ' + s.tableHeader}>
          <span>Title</span>
          <span>Description</span>
          <span>Tone</span>
          <span>Created</span>
        </div>
        {bookRequests.map((bookRequest) => (
          <div key={bookRequest._id} className={s.item}>
            <span>{bookRequest.title}</span>
            <span>
              <span>
                {bookRequest._id === showAllText
                  ? bookRequest.description
                  : bookRequest.description.length > maxTextSize
                  ? bookRequest.description.substring(0, maxTextSize) + '...'
                  : bookRequest.description}
              </span>
              {bookRequest.description.length > maxTextSize && (
                <span>
                  {bookRequest._id === showAllText ? (
                    <button
                      onClick={() => setShowAllText(null)}
                      className={s.showButton}
                    >
                      Hide long text
                    </button>
                  ) : (
                    <button
                      onClick={() => setShowAllText(bookRequest._id)}
                      className={s.showButton}
                    >
                      Show All
                    </button>
                  )}
                </span>
              )}
            </span>
            <span>{bookRequest.tone}</span>
            <span>{new Date(bookRequest.createdAt).toDateString()}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminBookRequests;
