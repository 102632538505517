import { useState, useEffect } from 'react';
import md5 from 'crypto-js/md5';

import s from '../PageContacts.module.scss';

export interface CircleAvatarProps {
  name: string | undefined;
  email: string;
}

const getInitials = (
  fullName: string | undefined,
  email: string | undefined
) => {
  if (fullName) {
    const words = fullName.split(' ');
    return words
      .map((word) => word.charAt(0))
      .slice(0, 2)
      .join('')
      .toUpperCase();
  } else if (email) {
    return email[0].toUpperCase();
  }
};

const CircleAvatar = ({ name, email }: CircleAvatarProps) => {
  const generateRandomColor = (): string => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const [backgroundColor] = useState(generateRandomColor);
  const [avatarUrl, setAvatarUrl] = useState('');

  useEffect(() => {
    const emailHash = md5(email.trim().toLowerCase()).toString();
    const gravatarUrl = `https://www.gravatar.com/avatar/${emailHash}?d=404`;

    const fetchGravatar = async () => {
      try {
        const response = await fetch(gravatarUrl);
        if (response.ok) {
          setAvatarUrl(gravatarUrl);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchGravatar();
  }, [email]);

  return (
    <div
      className={s.img}
      style={{ backgroundColor: avatarUrl ? 'transparent' : backgroundColor }}
    >
      {avatarUrl ? (
        <img src={avatarUrl} alt={name} className={s.img} />
      ) : (
        getInitials(name, email)
      )}
    </div>
  );
};

export default CircleAvatar;
