import React, { useState, useEffect } from 'react';
import s from './AdminStatistic.module.scss';
import QUERIES from 'graphql/queries';
import { graphQlCall } from 'graphql/utils';
import { toLocalISOString } from 'utils/helpers';
import Pagination from './Pagination';
import Select from '../AdminAiTemplates/Common/Select';
import ChartComponent from './Chart';

interface IPageStatistic {
  _id: string;
  pageId: string;
  total: number;
}

interface IGraphData {
  _id: string;
  total: number;
  count: number;
  createdAt: number;
}

const selectOptions = [
  {
    value: 1,
    label: '24 hours',
  },
  {
    value: 7,
    label: '7 days',
  },
  {
    value: 30,
    label: '30 days',
  },
  {
    value: 90,
    label: '90 days',
  },
  {
    value: 365,
    label: '365 days',
  },
];

const AdminStatistic = () => {
  const [totalViews, SetTotalViews] = useState(0);
  const [pageViews, setPageViews] = useState<IPageStatistic[]>([]);
  const [totalPagesCount, setTotalPagesCount] = useState(0);
  const [inPage, setInPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [graphPeriod, setGraphPeriod] = useState(7);
  const [graphData, setGraphData] = useState<IGraphData[]>([]);

  useEffect(() => {
    fetchTotalViews();
    fetchPageViews(1);
    calculateGraph(7);
  }, []);

  const fetchTotalViews = async () => {
    const response = await graphQlCall({
      queryTemplateObject: QUERIES.GET_TOTAL_COMMON_VIEWS,
    });
    SetTotalViews(response.total);
  };

  const fetchPageViews = async (page: number) => {
    const response = await graphQlCall({
      queryTemplateObject: QUERIES.GET_TOTAL_PAGE_VIEWS,
      values: {
        limit: inPage,
        skip: (page - 1) * inPage,
      },
    });
    setTotalPagesCount(Math.ceil(response.count / inPage));
    setPageViews(response.statistic);
  };

  const handleNextPage = () => {
    const newPage = currentPage + 1;
    fetchPageViews(newPage);
    setCurrentPage(newPage);
  };

  const handlePreviousPage = () => {
    const newPage = currentPage - 1;
    fetchPageViews(newPage);
    setCurrentPage(newPage);
  };

  const calculateGraph = (days: number) => {
    const { from, to } = generatePeriodInterval(days);
    fetchGraphData(from, to);
  };

  const fetchGraphData = async (from: number, to: number) => {
    const response = await graphQlCall({
      queryTemplateObject: QUERIES.GET_TOTAL_GRAPH_DATA,
      values: {
        from,
        to,
      },
    });
    setGraphData(response);
  };

  const getTimestamp = (data: Date) => {
    return Math.ceil(data.valueOf() / 1000);
  };

  const generatePeriodInterval = (days: number) => {
    const fromDate = new Date();
    const toDate = new Date();
    fromDate.setDate(fromDate.getDate() - days);
    return {
      from: getTimestamp(fromDate),
      to: getTimestamp(toDate),
    };
  };

  const handleChangePeriod = (days: number) => {
    const fromDate = new Date();
    const toDate = new Date();
    fromDate.setDate(fromDate.getDate() - days);
    const from = getTimestamp(fromDate);
    const to = getTimestamp(toDate);
    fetchGraphData(from, to);
    setGraphPeriod(days);
  };

  const getLabel = (timeStamp: number) => {
    const date = new Date(timeStamp * 1000);
    if (graphPeriod === 1) {
      const hours = date.getHours();
      const hoursStr = hours > 9 ? `${hours}` : `0${hours}`;
      const min = date.getMinutes();
      const minStr = min > 9 ? `${min}` : `0${min}`;
      return `${hoursStr}:${minStr}`;
    }
    return toLocalISOString(date).substring(0, 10);
  };

  const generateChartData = () => {
    let values: number[] = [];
    let labels: string[] = [];
    graphData.forEach((data) => {
      values.push(data.count);
      labels.push(getLabel(data.createdAt));
    });

    return {
      values,
      labels,
    };
  };

  const { values, labels } = generateChartData();

  return (
    <div className={s.content}>
      <h2 className={s.header}>Statistic</h2>
      <div>
        Total all page views: &nbsp;
        <strong>{totalViews}</strong>
      </div>
      <main className={s.sections}>
        <section className={s.pageTable}>
          <Pagination
            page={currentPage}
            pages={totalPagesCount}
            onNext={handleNextPage}
            onPrevious={handlePreviousPage}
          />
          <div className={s.row + ' ' + s.tableHeader}>
            <span>Page id</span>
            <span>total views</span>
          </div>
          {pageViews.map((pageView) => (
            <div key={pageView._id} className={s.row}>
              <span>{pageView.pageId}</span>
              <span>{pageView.total}</span>
            </div>
          ))}
          <Pagination
            page={currentPage}
            pages={totalPagesCount}
            onNext={handleNextPage}
            onPrevious={handlePreviousPage}
          />
        </section>
        <section>
          <h3>Daily chart</h3>
          <Select
            value={graphPeriod}
            options={selectOptions}
            onChange={handleChangePeriod}
          />
          <ChartComponent data={values} labels={labels} unit="views" />
        </section>
      </main>
    </div>
  );
};

export default AdminStatistic;
