import clsx from 'clsx';
import { JSX, useState } from 'react';
import { ReactComponent as RemoveIcon } from 'Assets/icons/remove-icon.svg';
import { ReactComponent as ArrowRightIcon } from 'Assets/icons/arrowTiny.svg';
import Loader from '../Loader/Loader';

import styles from './Carousel.module.scss';

interface IProps {
  cards: { label?: string; image: string | JSX.Element }[];
  loading?: boolean;
  removable?: boolean;
  selectedCardIndex?: number;
  cardsPerSlide?: number;
  onCardSelect: (index: number) => void;
  onCardRemove?: (index: number) => void;
}

const Carousel = ({
  loading,
  removable,
  cards,
  selectedCardIndex,
  cardsPerSlide = 3,
  onCardSelect,
  onCardRemove,
}: IProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + cardsPerSlide, cards.length - cardsPerSlide)
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - cardsPerSlide, 0));
  };

  const showArrows = cards.length > cardsPerSlide;

  return (
    <div className={styles.container}>
      {showArrows && (
        <button className={styles.leftArrow} onClick={prevSlide}>
          <ArrowRightIcon />
        </button>
      )}
      <div className={styles.overflowWrapper}>
        <div
          className={styles.sliderWrapper}
          style={{
            transform: `translateX(calc(-${
              (currentIndex * 100) / cardsPerSlide
            }% - ${(10 * currentIndex) / cardsPerSlide}px))`,
          }}
        >
          {cards.map((card, index) => (
            <div
              key={index}
              className={clsx(styles.card, {
                [styles.selected]: index === selectedCardIndex,
              })}
              style={{
                flex: `0 0 calc((100% - ${
                  (cardsPerSlide - 1) * 10
                }px) /${cardsPerSlide})`,
              }}
              onClick={() => onCardSelect(index)}
            >
              <div className={styles.label}>{card.label}</div>
              {removable && (
                <div
                  className={styles.removeCard}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (onCardRemove) onCardRemove(index);
                  }}
                >
                  <RemoveIcon />
                </div>
              )}
              {typeof card.image === 'string' ? (
                <img src={card.image} alt={'image option ' + index} />
              ) : (
                card.image
              )}
            </div>
          ))}
          {loading &&
            Array.from(
              { length: cardsPerSlide - cards.length },
              (_, i) => i + 1
            ).map((el) => (
              <div
                key={el}
                className={clsx(styles.card, styles.loading)}
                style={{
                  flex: `0 0 calc((100% - ${
                    (cardsPerSlide - 1) * 10
                  }px)/${cardsPerSlide})`,
                }}
              >
                <Loader size={24} color="#5a5a5a" />
              </div>
            ))}
        </div>
      </div>
      {showArrows && (
        <button className={styles.rightArrow} onClick={nextSlide}>
          <ArrowRightIcon />
        </button>
      )}
      <div className={styles.indicatorContainer}>
        {Array.from(
          { length: Math.ceil(cards.length / cardsPerSlide) },
          (_, i) => i
        ).map((el) => (
          <div
            key={el}
            className={clsx(styles.currentSlideIndicator, {
              [styles.selected]: el === Math.ceil(currentIndex / cardsPerSlide),
            })}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
