import { useEffect, useState } from 'react';
import Button from 'UILib/Button/Button';

import styles from './SelectGenresForm.module.scss';

const genres = [
  { name: 'Hip Hop', value: 'hip-hop' },
  { name: 'Soulful', value: 'soulful' },
  { name: 'Trap', value: 'trap' },
  { name: 'Rock', value: 'rock' },
  { name: 'Piano', value: 'piano' },
  { name: 'Downtempo', value: 'downtempo' },
  { name: 'Ambient', value: 'ambient' },
  { name: 'Techno', value: 'techno' },
  { name: 'Trance', value: 'trance' },
  { name: 'Pop', value: 'pop' },
  { name: 'UK Garage', value: 'uk-garage' },
  { name: 'Acoustic', value: 'acoustic' },
  { name: "Drum'n'Bass", value: 'drum&bass' },
  { name: 'Funk', value: 'funk' },
  { name: 'House', value: 'house' },
];

interface IProps {
  handleChange: (key: string, value: string[]) => void;
  validation: { validate: () => boolean };
  data: { [key: string]: string | string[] };
}

const SelectGenresForm = ({ data, handleChange, validation }: IProps) => {
  const [error, setError] = useState({
    genre: '',
    music: '',
  });

  useEffect(() => {
    validation.validate = () => {
      let errorsObj = {
        genre: '',
        music: '',
      };

      if (!data.selectedGenres?.length) {
        errorsObj = {
          ...errorsObj,
          genre: 'Please Select A Genre',
        };
      } else if (!data.musicSample) {
        errorsObj = {
          ...errorsObj,
          music: 'Please Select A Music',
        };
      }

      setError(errorsObj);
      return !!data.selectedGenres?.length && !!data.musicSample;
    };
  }, [validation, data.selectedGenres, data.musicSample]);

  const handleGenreClick = (genre: string) => {
    const selectedGenres = data.selectedGenres ? [...data.selectedGenres] : [];
    if (typeof selectedGenres === 'string') return;

    const index = selectedGenres.findIndex((el) => el === genre);
    let newGenres: string[] = [];
    if (index < 0) {
      newGenres = [genre];
    }
    handleChange('selectedGenres', newGenres);
  };

  return (
    <div className={styles.wrapper}>
      Select genres
      <div className={styles.genresContainer}>
        {genres.map((genre, index) => (
          <Button
            key={index}
            width={100}
            height={30}
            appearance={
              data.selectedGenres?.includes(genre.value)
                ? 'highlighted'
                : 'stroke'
            }
            onClick={() => handleGenreClick(genre.value)}
          >
            {genre.name}
          </Button>
        ))}
      </div>
      {(error.genre || error.music) && (
        <div className={styles.errorMessage}>{error.genre || error.music}</div>
      )}
    </div>
  );
};

export default SelectGenresForm;
