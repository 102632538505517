import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';

const StyledAdminNavigation = styled.div`
  width: max-content;
  padding: 20px;
  height: 100%;
  min-height: 100vh;
  border-right: 1px #777 solid;

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none;
  }
`;

interface IProps {
  onChangeTab: (tabName: string) => void;
}

const AdminNavigation = (props: IProps) => {
  return (
    <StyledAdminNavigation>
      <h2>ADMIN PANEL</h2>
      <Menu>
        <Menu.Item key="templates">
          <Link
            onClick={() => props.onChangeTab('templates')}
            to="/console/admin"
          >
            Templates
          </Link>
        </Menu.Item>
        <Menu.Item key="sections-menu">
          <Link
            onClick={() => props.onChangeTab('sections-menu')}
            to="/console/admin"
          >
            Sections Menu
          </Link>
        </Menu.Item>
        <Menu.Item key="book-requests">
          <Link
            onClick={() => props.onChangeTab('book-requests')}
            to="/console/admin"
          >
            Book Requests
          </Link>
        </Menu.Item>
        <Menu.Item key="ai-templates">
          <Link
            onClick={() => props.onChangeTab('ai-templates')}
            to="/console/admin"
          >
            AI Templates
          </Link>
        </Menu.Item>
        <Menu.Item key="statistic">
          <Link
            onClick={() => props.onChangeTab('statistic')}
            to="/console/admin"
          >
            Statistic
          </Link>
        </Menu.Item>
        <Menu.Item key="d-1">
          <hr />
        </Menu.Item>
        <Menu.Item key="projects">
          <Link to="/console/projects">Return to projects</Link>
        </Menu.Item>
      </Menu>
    </StyledAdminNavigation>
  );
};

export default AdminNavigation;
