import React from 'react';
import s from './AdminStatistic.module.scss';

interface IProps {
  page: number;
  pages: number;
  onNext: () => void;
  onPrevious: () => void;
}

const Pagination = (props: IProps) => {
  const handleNext = () => {
    if (props.page === props.pages) {
      return;
    }
    props.onNext();
  };

  const handlePrev = () => {
    if (props.page === 1) {
      return;
    }
    props.onPrevious();
  };

  return (
    <div className={s.pagination}>
      <button onClick={() => handlePrev()}>Prev</button>
      <span>
        {props.page} / {props.pages}
      </span>
      <button onClick={() => handleNext()}>Next</button>
    </div>
  );
};

export default Pagination;
