import React, { useEffect, useState } from 'react';
import s from './GeneralTab.module.scss';
import PrimaryButton from '../../../Common/PrimaryButton/PrimaryButton';
import Textarea from '../../../Common/Textarea/Textarea';
import InputV1 from '../../../Common/Inputs/InputV1/InputV1';
import InputSubdomain from '../../../Common/Inputs/InputSubdomain/InputSubdomain2';
import { subdomainBlackList } from './blackList';

interface IProps {
  name: string;
  setName: (name: string) => void;
  title: string;
  setTitle: (title: string) => void;
  description: string;
  setDescription: (text: string) => void;
  handleSave: (newName?: string) => void;
  customSubDomain: string;
  setSubDomain: (text: string) => void;
  onClose: () => void;
  setProjectNameAction: (id: string, newName: string) => void;
  id: string;
}

const GeneralTab = (props: IProps) => {
  const [nameInput, setNameInput] = useState('');
  const [customSubDomainError, setCustomSubDomainError] = useState<
    null | string
  >(null);

  useEffect(() => {
    setNameInput(props.name);
  }, [props.name]);

  const handleSaveRequiredField = (e: any) => {
    if (e.target.value.trim()) {
      props.setName(nameInput);
      props.setProjectNameAction(props.id, nameInput);
      props.handleSave(nameInput);
    }
  };

  const handleSaveAndClose = () => {
    if (customSubDomainError) {
      return;
    }
    if (nameInput.trim()) {
      props.setName(nameInput);
      props.setProjectNameAction(props.id, nameInput);
      props.handleSave(nameInput);
      props.onClose();
    }
  };

  const validateCustomSubDomain = (value: string) => {
    const regex = /^(\w|\d|-)*$/gi;
    setCustomSubDomainError(null);

    let badWordFound = false;
    for (const badWord of subdomainBlackList) {
      if (value.toLowerCase().includes(badWord.toLowerCase())) {
        badWordFound = true;
      }
    }

    if (badWordFound) {
      return false;
    }

    return regex.test(value as string);
  };

  const handleSetSubDomain = (subDomain: string) => {
    const domainValidated = validateCustomSubDomain(subDomain);
    if (!domainValidated) {
      setCustomSubDomainError('Please use a valid subdomain.');
    }
    props.setSubDomain(subDomain);
  };

  const handleSave = () => {
    if (customSubDomainError) {
      return;
    }
    props.handleSave();
  };

  return (
    <div className={s.container}>
      <div className={s.input}>
        <InputV1
          width={760}
          label={'Project Name'}
          placeholder={'My Project'}
          value={nameInput}
          setState={setNameInput}
          type={'text'}
          onBlur={(e: any) => handleSaveRequiredField(e)}
        />
      </div>
      <div className={s.input}>
        <InputV1
          width={760}
          label={'Project Title'}
          placeholder={'My Title'}
          value={props.title ?? ''}
          setState={props.setTitle}
          type={'text'}
          onBlur={() => props.handleSave()}
        />
      </div>
      <div className={s.textArea}>
        <Textarea
          width={760}
          label={'Project Description'}
          placeholder={'My Description'}
          value={props.description ?? ''}
          setState={props.setDescription}
          onBlur={() => props.handleSave()}
        />
      </div>
      <div className={s.input}>
        <InputSubdomain
          name="customSubdomain"
          label="Custom subdomain"
          width={760}
          placeholder={'mysubdomain'}
          value={props.customSubDomain ?? ''}
          setState={handleSetSubDomain}
          onBlur={() => handleSave()}
          error={customSubDomainError}
        />
      </div>
      <div className={s.buttonBlock}>
        <PrimaryButton onClick={handleSaveAndClose}>Save</PrimaryButton>
      </div>
    </div>
  );
};

export default GeneralTab;
