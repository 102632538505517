import { useState, useEffect, useRef } from 'react';
import { ReactComponent as ArrowSvg } from 'Assets/icons/chevronDown.svg';
import { ReactComponent as CheckMarkSvg } from 'Assets/icons/checkMark.svg';
import { DateType, calculateDateRange, dateRangeArr } from './helpers';
import clsx from 'clsx';
import Calendar from './Calendar/Calendar';

import s from './DatePicker.module.scss';

interface IProps {
  startDate: Date | null;
  endDate: Date | null;
  startRange?: DateType;
  onChange: (start: Date | null, end: Date | null) => void;
  isSmall?: boolean;
  dropdownClassName?: string;
  className?: string;
}

const DatePicker = (props: IProps) => {
  const [open, setOpen] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);
  const [selectDateRangeType, setSelectDateRangeType] = useState<DateType>(
    'Custom'
  );
  const selectDateRangeRef: React.LegacyRef<HTMLDivElement> = useRef(null);

  useEffect(() => {
    if (!startDate || !endDate) {
      handleSelectDateRangeItemClick(props.startRange ?? 'Last 7 Days');
    }
  }, [startDate, endDate, props.startRange]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectDateRangeRef.current &&
      !selectDateRangeRef.current.contains(event.target as any)
    ) {
      setOpen(false);
    }
  };

  const onDateChanged = (startDate: Date | null, endDate: Date | null) => {
    setStartDate(startDate);
    setEndDate(endDate);
    props.onChange(startDate, endDate);
  };

  const formatDate = (date: Date) => {
    if (date === null) return 'MM:DD';

    return date.toLocaleString('en-US', { month: 'short', day: 'numeric' });
  };

  const handleSelectDateRangeItemClick = (dateRange: DateType) => {
    setSelectDateRangeType(dateRange);
    setOpen(false);
    if (dateRange === 'Custom') {
      setOpenCalendar(true);
      return;
    }
    const { startDate, endDate } = calculateDateRange(dateRange);
    onDateChanged(startDate, endDate);
  };

  return (
    <div className={s.container}>
      <div
        className={clsx(
          props.isSmall
            ? s.selectDateRangeBlockSmallWrapper
            : s.selectDateRangeBlockWrapper,
          props.className
        )}
      >
        <div
          onClick={() => setOpen(!open)}
          className={clsx(
            s.selectDateRangeBlock,
            { [s.openDropDown]: open },
            props.dropdownClassName
          )}
          ref={selectDateRangeRef}
        >
          {selectDateRangeType}
          <ArrowSvg
            width={14}
            height={14}
            className={`${s.arrow} ${open ? s.open : null}`}
          />
        </div>
        {startDate && endDate && !props.isSmall && (
          <div className={s.selectDateRangeBlockInfo}>
            {formatDate(startDate)} - {formatDate(endDate)}
          </div>
        )}
        {open && (
          <div className={s.selectDateRangeItems}>
            {dateRangeArr.map((dateRange, index) => {
              return (
                <div
                  onClick={() => {
                    handleSelectDateRangeItemClick(dateRange);
                  }}
                  key={index}
                >
                  {dateRange === selectDateRangeType && <CheckMarkSvg />}
                  {dateRange}
                </div>
              );
            })}
          </div>
        )}
        <Calendar
          startDate={startDate}
          endDate={endDate}
          setDate={onDateChanged}
          isOpen={openCalendar}
          onClose={() => setOpenCalendar(false)}
        />
      </div>
    </div>
  );
};

export default DatePicker;
