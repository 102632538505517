import React from 'react';
import { Question } from '../aiTemplateTypes';
import AiInput from './AiInput';
import xIcon from 'Assets/icons/book/x.svg';
import burgerSvg from '../Assets/burger.svg';
import s from './Common.module.scss';

interface IProps {
  question: Question;
  onChange: (newQuestion: Question) => void;
  onDelete: () => void;
  index: number;
}

const QuestionComponent = (props: IProps) => {
  const handleChangeId = (newValue: string) => {
    const newQuestion: Question = { ...props.question, id: newValue };
    props.onChange(newQuestion);
  };

  const handleChangeLabel = (newValue: string) => {
    const newQuestion: Question = { ...props.question, label: newValue };
    props.onChange(newQuestion);
  };

  return (
    <div className={s.listItem}>
      <h3 className={s.header}>
        <img src={burgerSvg} />
        <span>Question {props.index}</span>
      </h3>
      <AiInput name="ID" value={props.question.id} onChange={handleChangeId} />
      <AiInput
        name="Label"
        value={props.question.label}
        onChange={handleChangeLabel}
      />
      <div onClick={() => props.onDelete()} className={s.closeButton}>
        <img src={xIcon} alt="" />
      </div>
    </div>
  );
};

export default QuestionComponent;
