import { ENVIRONMENT } from 'Constants';
import { IGeneralProject } from 'types';

export const checkSpecialCharacters = (text: string) => {
  const regex = /[!@#$%^~` &*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  return regex.test(text);
};

export const toLocalISOString = (date: Date) => {
  const offset = date.getTimezoneOffset();
  const localDate = new Date(date.getTime() - offset * 60000);
  return localDate.toISOString().slice(0, -1) + formatOffset(offset);
};

const formatOffset = (minutes: number) => {
  const sign = minutes > 0 ? '-' : '+';
  const absOffset = Math.abs(minutes);
  const hours = Math.floor(absOffset / 60);
  const mins = absOffset % 60;
  return `${sign}${padZero(hours)}:${padZero(mins)}`;
};

const padZero = (number: number) => {
  return number < 10 ? '0' + number : number.toString();
};

export const truncateString = (string: string | undefined, maxLength = 70) => {
  if (!string) return '';
  return string.length > maxLength
    ? string.substring(0, maxLength) + '...'
    : string;
};

export const generateProjectUrl = (
  project: IGeneralProject,
  connectedDomain?: { name: string }
) => {
  let rootDomain = 'autofunnel.ai';
  if (ENVIRONMENT === 'DEV') {
    rootDomain = 'dev.autofunnel.ai';
  }
  if (connectedDomain) {
    return `https://${connectedDomain.name}`;
  }

  if (project.customSubDomain) {
    return `https://${project.customSubDomain}.${rootDomain}`;
  }

  return `https://live.${rootDomain}/${project._id}`;
};
