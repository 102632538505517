import React, { useRef, useState } from 'react';
import s from './Category.module.scss';
import TemplatePreview from './TemplatePreview/TemplatePreview';
import { TEMPLATES_URL, DATA_URL } from '../../../Constants';
import altTemplate from '../../../Assets/images/funnelPreview.png';
import { ITemplate } from 'types';

interface IProps {
  templates: ITemplate[];
  onSubmit: (template: ITemplate) => void;
  onClose: () => void;
  handleSpinnerOnRedirect: () => void;
}

const Category = (props: IProps) => {
  const [firstLoading, setFirstLoading] = useState(true);
  const handleSubmit = (template: ITemplate) => {
    props.onSubmit(template);
    props.onClose();
  };

  interface IGetTemplatePayload {
    schemeImage?: string;
    image?: string;
    bigImage?: string;
    smallImage?: string;
    type: string;
  }

  const getTemplateImage = ({
    schemeImage,
    image,
    bigImage,
    smallImage,
    type,
  }: IGetTemplatePayload) => {
    const templateImage = schemeImage || image || bigImage || smallImage;
    if (templateImage) {
      if (templateImage.includes('http')) {
        return templateImage;
      }
      if (!templateImage.includes('pageImages')) {
        return `${TEMPLATES_URL}/${templateImage}`;
      } else {
        return `${DATA_URL}/${templateImage}`;
      }
    } else {
      return altTemplate;
    }
  };

  return (
    <div className={s.container}>
      <div>
        {' '}
        {/* TODO: Without this DIV templates will not be rendered fully. Need to figure out WHY  */}
      </div>

      {props.templates &&
        props.templates.map((template, index) => (
          <TemplatePreview
            handleSpinnerOnRedirect={props.handleSpinnerOnRedirect}
            getTemplateImage={getTemplateImage}
            handleSubmit={handleSubmit}
            setFirstLoading={setFirstLoading}
            key={template.name + index}
            template={template}
          />
        ))}
    </div>
  );
};

export default Category;
