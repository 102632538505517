import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import ModalStyled from '../../../Styled/ModalStyled';
import BigStyledButton from '../../../Styled/BigStyledButton';
import SecondaryButton from '../../../Styled/SecondaryStyledButton';
import { ReactComponent as CloseIcon } from '../../../Assets/icons/close.svg';
import { colors } from '../../../Styled/constants';
import { Input } from 'antd';

const { TextArea } = Input;

interface IStyledModalProps {
  visible?: boolean;
}

const StyledUploadModal = styled.div<IStyledModalProps>`
  .upload-button {
    width: 200px;
    height: 40px;
    border: none;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    visibility: ${(props) => (!props.visible ? 'hidden' : 'visible')};

    &.change {
      background: yellow;
      border: 1px #000 solid;
      color: #000;
    }

    &.new {
      background: ${colors.primary};
      color: #fff;
      border: 1px ${colors.primary} solid;
    }

    &:hover {
      background: #fff;
      color: #000;
      border: 1px #000 solid;
    }
  }

  .buttons-block {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

interface IProps {
  onSubmit: (data: any) => void;
  data: any;
  hidden?: boolean;
}

const UploadSectionData = (props: IProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [newData, setNewData] = useState('');
  const [show, setShow] = useState(false);

  const handleSubmit = () => {
    try {
      props.onSubmit(JSON.parse(newData));
      setNewData('');
      setShow(false);
    } catch (error) {
      console.log('PARSE ERROR', error);
    }
  };

  const turnOnModal = () => {
    if (props.data) {
      if (typeof props.data === 'string') {
        setNewData(props.data);
      } else {
        setNewData(JSON.stringify(props.data));
      }
    } else {
      setNewData('');
    }
    setShow(true);
  };

  const closeModal = () => {
    setNewData('');
    setShow(false);
  };

  const startUpload = () => {
    if (!inputRef?.current) {
      return;
    }
    inputRef.current.click();
  };

  const handleFileUpload = () => {
    if (!inputRef?.current) {
      return;
    }
    const files = inputRef.current.files;
    if (!files?.length) {
      return;
    }
    let file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (res) => {
        const content = String(reader.result);
        if (content.indexOf('application/json') === -1) {
          return;
        }
        const parts = content.split(',');
        const json = atob(parts[1]);
        setNewData(json);
      };
      inputRef.current.value = '';
    }
  };

  const hasData = !!props.data;

  return (
    <StyledUploadModal visible={show}>
      <button
        className={hasData ? 'upload-button change' : 'upload-button new'}
        onClick={() => turnOnModal()}
        style={{ visibility: props.hidden ? 'hidden' : 'visible' }}
      >
        {hasData ? 'change data' : 'add data'}
      </button>
      <ModalStyled visible={show}>
        <div className="modal-block">
          <div className="modal-header">
            <h2 className="modal-title">Section Data</h2>
            <CloseIcon className="close-icon" onClick={closeModal} />
          </div>
          {show && (
            <div className="modal-body">
              <TextArea
                value={newData}
                onChange={(e) => setNewData(e.target.value)}
                rows={10}
              />
              <div className="buttons-block">
                <SecondaryButton onClick={startUpload}>
                  upload file
                </SecondaryButton>
                <BigStyledButton onClick={() => handleSubmit()}>
                  Submit
                </BigStyledButton>
              </div>
            </div>
          )}
        </div>
      </ModalStyled>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={inputRef}
        accept="application/json"
        onChange={() => handleFileUpload()}
      />
    </StyledUploadModal>
  );
};

export default UploadSectionData;
