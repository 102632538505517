import { ReactComponent as CrossIcon } from 'Assets/icons/cross.svg';

import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';
import s from 'Pages/PageContacts/SmartList/SmartList.module.scss';
import { SmartList } from '../Helper/types';

interface SmartListComponentProps {
  smartLists: SmartList[];
  selectedAll: boolean;
  selectedSmartList: string | null;
  setSelectedAll: (value: boolean) => void;
  setSearchText: (value: string) => void;
  setSelectedSmartList: (value: string | null) => void;
  setCurrentPage: (value: number) => void;
  setSmartLists: (value: SmartList[]) => void;
}
const SmartListComponent = (props: SmartListComponentProps) => {
  const handleAllClick = () => {
    props.setSelectedAll(true);
    props.setSearchText('');
    props.setSelectedSmartList(null);
    props.setCurrentPage(1);
  };

  const handleSmartListClick = (listId: string) => {
    props.setSelectedAll(false);
    props.setSearchText('');
    props.setSelectedSmartList(listId);
    props.setCurrentPage(1);
  };

  const handleDeleteSmartList = async (id: string) => {
    try {
      const response = await graphQlCall({
        queryTemplateObject: QUERIES.DELETE_SMART_LIST,
        values: { id },
        headerType: 'USER-AUTH',
      });
      const updatedSmartLists = props.smartLists.filter(
        (list: any) => list._id !== id
      );
      props.setSmartLists(updatedSmartLists);
    } catch (error) {
      console.error('Error deleting smart list:', error);
    }
  };

  return (
    <div className={s.smart}>
      <div className={s.pageAll} onClick={handleAllClick}>
        All
        <span
          className={props.selectedAll ? s.selectedOption : s.pageOption}
        ></span>
      </div>
      {props.smartLists.map((list: any) => (
        <div
          key={list._id}
          className={s.pageNew}
          onClick={() => handleSmartListClick(list._id)}
        >
          <div className={s.pageNewInner}>
            {list.name}
            <button
              className={s.cross}
              onClick={() => handleDeleteSmartList(list._id)}
            >
              <CrossIcon />
            </button>
          </div>
          <span
            className={
              props.selectedSmartList === list._id
                ? s.selectedOption
                : s.pageOption
            }
          ></span>
        </div>
      ))}
    </div>
  );
};

export default SmartListComponent;
