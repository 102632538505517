import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

const StyledImageUpload = styled.div`
  cursor: pointer;

  .image {
    width: 100px;
    height: 50px;
    margin-right: 10px;
    border: 1px #777 solid;
    border-radius: 5px;
    overflow: hidden;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

interface IProps {
  image?: string;
  onChange: (file: File) => void;
}

const ImageWithUpload = (props: IProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    if (props.image && props.image.length) {
      setUrl(props.image);
    } else {
      setUrl('/console/assets/template.png');
    }
  }, [props.image]);

  const handleImageClick = () => {
    if (!inputRef?.current) {
      return;
    }
    inputRef.current.click();
  };

  const handleFileUpload = () => {
    if (!inputRef?.current) {
      return;
    }
    const files = inputRef.current.files;
    if (!files?.length) {
      return;
    }
    let file = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (res) => {
      const content = reader.result;
      if (reader.result) {
        setUrl(String(content));
      }
    };

    if (file) {
      props.onChange(file);
    }
  };

  return (
    <StyledImageUpload>
      <div className="image" onClick={() => handleImageClick()}>
        <img src={url} alt="uploaded" />
      </div>
      <input
        type="file"
        style={{ display: 'none' }}
        accept="image/*"
        ref={inputRef}
        onChange={(e) => handleFileUpload()}
      />
    </StyledImageUpload>
  );
};

export default ImageWithUpload;
