import { useEffect, useRef, useState } from 'react';
import { IGeneralProject, ITemplate } from 'types';
import { CONSULT_URL } from 'Constants';
import { graphQlCall } from 'graphql/utils';
import { getUserId } from 'utils/Utils';
import QUERIES from 'graphql/queries';
import Spinner from 'Components/Common/Spinner/Spinner';
import Loader from 'UILib/Loader/Loader';
import Modal from 'Components/Common/Modal/Modal';
import Tabs from './Tabs/Tabs';
import Category from './Category/Category';

import s from './Templates.module.scss';

interface IProps {
  firstFunnelLoad: boolean;
  onClose: (projectId?: string) => void;
  project: IGeneralProject;
  onSelect: (template: ITemplate) => void;
  handleSpinnerOnRedirect: () => void;
  show: boolean;
}

const Templates = (props: IProps) => {
  const [templatesLoading, setTemplatesLoading] = useState(true);
  const [templates, setTemplates] = useState<Record<string, ITemplate[]>>({});

  const modalRef = useRef<HTMLDivElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [
    isOpenModalCreatePageWithAI,
    setIsOpenModalCreatePageWithAI,
  ] = useState(false);
  const [isPageWithAiSaving, setIsPageWithAiSaving] = useState(false);

  const userId = getUserId();

  useEffect(() => {
    setIsOpenModalCreatePageWithAI(false);
    setIsPageWithAiSaving(false);

    fetchTemplatesList();

    window.addEventListener('message', function (event) {
      const data = event.data;

      if (data.status === 'success page create') {
        setIsPageWithAiSaving(true);
        if (iframeRef.current) {
          iframeRef.current.contentWindow?.postMessage(
            { message: 'need save' },
            CONSULT_URL
          );
        }
      }
      if (data.status === 'saveComplete') {
        window.location.replace(data.url);
      }
    });
  }, []);

  const fetchTemplatesList = async () => {
    setTemplatesLoading(true);
    const fetchedTemplates: ITemplate[] = await graphQlCall({
      queryTemplateObject: QUERIES.GET_TEMPLATES_WITH_CATEGORIES,
      headerType: 'USER-AUTH',
    });

    const blankPage = fetchedTemplates.find(
      (temp) => temp.name === 'Blank Page'
    );

    const groupedTemplates: Record<
      string,
      ITemplate[]
    > = fetchedTemplates.reduce((obj, temp) => {
      if (!temp.category) return obj;

      if (!obj[temp.category]) obj[temp.category] = [];
      obj[temp.category].push(temp);

      return obj;
    }, Object.create(null));

    Object.keys(groupedTemplates).forEach((key) => {
      groupedTemplates[key].sort((temp) => (temp.type === 'aipage' ? -1 : 1));
      if (blankPage) groupedTemplates[key].push(blankPage);
    });

    setTemplates(groupedTemplates);
    setTemplatesLoading(false);
  };

  // const handleClickOutside = () => {
  // if (!props.firstFunnelLoad && (props.project.pages.length > 0)) {
  //   props.onClose()
  // }
  // };
  // UseOnClickOutside(modalRef, () => handleClickOutside());

  const handleSubmit = (template: ITemplate) => {
    if (template.type === 'template') {
      props.onSelect(template);
    } else {
      const templateLink = `${CONSULT_URL}/edit/ai-page/${template.page}?projectId=${props.project._id}`;
      window.location.assign(templateLink);
    }
  };

  // const getTemplates = (categoryId: string) => {
  //   let output = props.templates.filter(
  //     (template) => template.category === categoryId
  //   );
  //   // let index = output.findIndex((element) => { //TODO: Workaround to place AI Page in front of Template list
  //   //   return element.name == 'AI Page';
  //   // })
  //   // if (index > 0) {
  //   //   let blank = output[index];
  //   //   output[index] = output[0];
  //   //   output[0] = blank;
  //   // }
  //   return output;
  // };

  // const handleBlankPage = () => {
  //   const blankTemplate = props.templates.find((t) => t.name == 'Blank Page');
  //   if (blankTemplate) {
  //     props.onSelect(blankTemplate);
  //     props.onClose();
  //     props.handleSpinnerOnRedirect();
  //   }
  // };
  const handleAIPage = () => {
    setIsOpenModalCreatePageWithAI(true);
  };
  const iframeSrc = `${CONSULT_URL}/edit/generate/${userId}/new/optin?projectId=${props.project._id}`;

  const handleCloseIframe = () => {
    props.onClose(props.project._id);
  };

  return (
    <Modal displayModal={props.show}>
      {!isOpenModalCreatePageWithAI ? (
        <div className={s.modalBlock} ref={modalRef}>
          <div
            className={s.modalHeader}
            style={{ marginTop: props.firstFunnelLoad ? '40px' : '' }}
          >
            {/* {props.firstFunnelLoad ? null : <button className={s.closeIcon} onClick={() => props.onClose()}/>} */}
            <button className={s.closeIcon} onClick={() => props.onClose()} />
          </div>
          <div className={s.modalBody}>
            {templatesLoading ? (
              <div className={s.loader}>
                <Loader size={32} color="#4957d8" />
              </div>
            ) : (
              Object.keys(templates).length > 0 && (
                <Tabs
                  tabs={Object.keys(templates)
                    .sort((temp) => (temp === 'Lead Pages' ? -1 : 1))
                    .map((key) => ({
                      title: key,
                      content: (
                        <Category
                          handleSpinnerOnRedirect={
                            props.handleSpinnerOnRedirect
                          }
                          onClose={props.onClose}
                          onSubmit={handleSubmit}
                          templates={templates[key]}
                        />
                      ),
                    }))}
                  contentClassName={s.tabsContent}
                />
              )
            )}
          </div>
        </div>
      ) : (
        <div className={s.modalBlock} ref={modalRef}>
          <div className={s.iframeWrapper}>
            <button
              className={s.closeIcon}
              onClick={() => {
                setIsOpenModalCreatePageWithAI(false);
                handleCloseIframe();
              }}
            />
            <iframe
              src={iframeSrc}
              width="100%"
              height="100%"
              ref={iframeRef}
              style={isPageWithAiSaving ? { opacity: '0' } : {}}
            ></iframe>
            <div
              className={s.iframeSpinnerBlock}
              style={{ display: isPageWithAiSaving ? 'block' : 'none' }}
            >
              <Spinner size={100} />
            </div>
          </div>
        </div>
      )}
      {/* <Modal displayModal={isOpenModalCreatePageWithAI} closeModal={()=>setIsOpenModalCreatePageWithAI(false)}>
              <div className={s.iframeModalWrapper} onClick={()=>setIsOpenModalCreatePageWithAI(false)}>
                <div className={s.iframeWrapper}>
                  <button className={s.closeIcon} onClick={() => setIsOpenModalCreatePageWithAI(false)}/>

                  <iframe src={iframeSrc} width='100%' height='755px' ref={iframeRef} style={isPageWithAiSaving ? {opacity:'0'} : {}}></iframe>
                  <div className={s.iframeSpinnerBlock} style={isPageWithAiSaving ? {display:'block'} : {display:'none'}}>
                    <Spinner size={100} />
                  </div>
                </div>
              </div>
              
      </Modal> */}
    </Modal>
  );
};

export default Templates;
