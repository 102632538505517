import React, { useState, useEffect } from 'react';
import s from './AiTemplateBuilder.module.scss';
import { AiTemplate, Prompt, Question, Section } from '../aiTemplateTypes';
import QuestionComponent from '../Common/Question';
import AddButton from '../Common/AddButton';
import PromptComponent from '../Common/Prompt';
import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';
import { ReactComponent as EyeIcon } from '../Assets/eye.svg';
import sCommon from '../Common/Common.module.scss';
import { api } from 'utils/Utils';
import { PAGECRAFT_API_URL } from 'Constants';
import { ISection } from 'types';
import SectionComponent from '../Common/Section';
import leftIcon from '../Assets/left.svg';
import Select from '../Common/Select';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

function updatePositionById<T>(
  arr: T[],
  oldPosition: number,
  newPosition: number
): T[] {
  if (oldPosition === newPosition) {
    return arr;
  }
  const newArr: T[] = [];
  arr.forEach((item, index) => {
    if (index !== oldPosition && oldPosition < newPosition) {
      newArr.push(item);
    }
    if (index === newPosition) {
      newArr.push(arr[oldPosition]);
    }
    if (index !== oldPosition && oldPosition > newPosition) {
      newArr.push(item);
    }
  });

  return newArr;
}

interface IProps {
  aiTemplate: AiTemplate;
  onClose: () => void;
  onSave: (newAiTemplate: AiTemplate) => void;
}

const AiTemplateBuilder = (props: IProps) => {
  const [aiTemplate, setAiTemplate] = useState<AiTemplate>(props.aiTemplate);
  const [dbSections, setDbSections] = useState<ISection[]>([]);
  const [allIds, setAllIds] = useState<string[]>([]);

  useEffect(() => {
    fetchStoredSections();
  }, []);

  useEffect(() => {
    setAiTemplate(props.aiTemplate);
  }, [props.aiTemplate]);

  const handleAddQuestion = () => {
    const newTemplate = { ...aiTemplate };
    const newQuestions = [...newTemplate.questions];
    newQuestions.push({
      id: '',
      label: '',
    });
    newTemplate.questions = newQuestions;
    setAiTemplate(newTemplate);
  };

  const handleDeleteQuestion = (i: number) => {
    const newTemplate = { ...aiTemplate };
    const newQuestions = newTemplate.questions.filter(
      (question, index) => index !== i
    );
    newTemplate.questions = newQuestions;
    setAiTemplate(newTemplate);
  };

  const handleChangeQuestion = (i: number, newQuestion: Question) => {
    const newTemplate = { ...aiTemplate };
    const newQuestions = newTemplate.questions.map((question, index) => {
      if (index === i) {
        question = { ...newQuestion };
      }
      return question;
    });
    newTemplate.questions = newQuestions;
    setAiTemplate(newTemplate);
  };

  const handleAddPrompt = () => {
    const initialPrompt: Prompt = {
      id: '',
      prompt: '',
      type: 'text',
    };
    let newPrompts: Prompt[][] = [...aiTemplate.prompts];
    if (newPrompts.length === 0) {
      newPrompts = [[initialPrompt]];
    } else {
      newPrompts[0].push(initialPrompt);
    }
    const newAiTemplate = { ...aiTemplate };
    newAiTemplate.prompts = newPrompts;
    setAiTemplate(newAiTemplate);
  };

  const handleUpdatePrompt = (newPrompt: Prompt, i: number, j: number) => {
    const newPrompts = [...aiTemplate.prompts];
    newPrompts[i][j] = newPrompt;
    const newAiTemplate = { ...aiTemplate };
    newAiTemplate.prompts = newPrompts;
    setAiTemplate(newAiTemplate);
  };

  const handleChangeGroup = (groupId: number, i: number, j: number) => {
    const prompt = aiTemplate.prompts[i][j];
    const newPrompts = aiTemplate.prompts.map((group, groupIndex) => {
      if (groupIndex === i) {
        group = group.filter((_currentPrompt, index) => index !== j);
      }
      if (groupIndex + 1 === groupId) {
        group.push(prompt);
      }
      return group;
    });

    if (groupId > aiTemplate.prompts.length) {
      newPrompts.push([prompt]);
    }
    const newAiTemplate = { ...aiTemplate };
    newAiTemplate.prompts = newPrompts;
    setAiTemplate(newAiTemplate);
  };

  const handleDeletePrompt = (i: number, j: number) => {
    const newPrompts = aiTemplate.prompts.map((group, groupIndex) => {
      if (groupIndex === i) {
        group = group.filter((_currentPrompt, index) => index !== j);
      }
      return group;
    });
    const newAiTemplate = { ...aiTemplate };
    newAiTemplate.prompts = newPrompts;
    setAiTemplate(newAiTemplate);
  };

  const fetchStoredSections = async () => {
    const newDbSections = await api(`${PAGECRAFT_API_URL}/section`);
    newDbSections.sort(sortFunction);
    setDbSections(newDbSections);
  };

  const sortFunction = (a: ISection, b: ISection) => {
    var keyA = a.name.toLowerCase(),
      keyB = b.name.toLowerCase();
    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  };

  const handleAddSection = () => {
    const newSection: Section = {
      content: [],
      templateName: '',
    };
    const newSections = [...aiTemplate.sections];
    newSections.push(newSection);
    const newAiTemplate = { ...aiTemplate };
    newAiTemplate.sections = newSections;
    setAiTemplate(newAiTemplate);
  };

  const handleDeleteSection = (indexToDelete: number) => {
    const newSections = aiTemplate.sections.filter(
      (_section, index) => index !== indexToDelete
    );
    const newAiTemplate = { ...aiTemplate };
    newAiTemplate.sections = newSections;
    setAiTemplate(newAiTemplate);
  };

  const handleChangeSectionTemplate = (
    newTemplateName: string,
    updatingIndex: number
  ) => {
    const newSections = aiTemplate.sections.map((section, index) => {
      if (index === updatingIndex) {
        section.templateName = newTemplateName;
        section.content = [];
      }
      return section;
    });
    const newAiTemplate = { ...aiTemplate };
    newAiTemplate.sections = newSections;
    setAiTemplate(newAiTemplate);
  };

  const getAllIds = () => {
    let ids: string[] = [];
    aiTemplate.prompts.forEach((group) =>
      group.map((prompt) => {
        if (prompt.id.trim().length) {
          ids.push(prompt.id);
        }
      })
    );
    aiTemplate.questions.map((question) => {
      if (question.id.trim().length) {
        ids.push(question.id);
      }
    });
    setAllIds(ids);
  };

  useEffect(() => {
    getAllIds();
  }, [aiTemplate]);

  const handleSetAiField = (
    aiField: string,
    contentId: string,
    index: number
  ) => {
    const section = aiTemplate.sections[index];
    let contentFound = false;
    const content = section.content.map((content) => {
      if (content.aiField === aiField) {
        contentFound = true;
        content.content = contentId;
      }
      return content;
    });

    if (!contentFound) {
      content.push({
        aiField,
        content: contentId,
      });
    }
    section.content = content;
    const newSections = aiTemplate.sections.map(
      (currentSection, currentIndex) => {
        if (index === currentIndex) {
          currentSection = section;
        }
        return currentSection;
      }
    );
    const newAiTemplate = { ...aiTemplate };
    newAiTemplate.sections = newSections;
    setAiTemplate(newAiTemplate);
  };

  const handleSetTemplateModel = (newModel: string) => {
    const newAiTemplate = { ...aiTemplate };
    newAiTemplate.settings.chaptGptModel = newModel;
    setAiTemplate(newAiTemplate);
  };

  const handleSetTemplateImageServer = (newServer: string) => {
    const newAiTemplate = { ...aiTemplate };
    newAiTemplate.settings.imageServer = newServer;
    setAiTemplate(newAiTemplate);
  };

  const handleSave = () => {
    props.onSave(aiTemplate);
  };

  const handleQuestionsSorting = (res: DropResult) => {
    const { destination, source } = res;
    if (!destination) {
      console.log('no destination');
      return;
    }

    const newQuestions = updatePositionById(
      [...aiTemplate.questions],
      source.index,
      destination.index
    );

    const newAiTemplate = { ...aiTemplate };
    newAiTemplate.questions = newQuestions;
    setAiTemplate(newAiTemplate);
  };

  const handleSectionsSorting = (res: DropResult) => {
    const { destination, source } = res;
    if (!destination) {
      console.log('no destination');
      return;
    }

    const newSections = updatePositionById(
      [...aiTemplate.sections],
      source.index,
      destination.index
    );

    const newAiTemplate = { ...aiTemplate };
    newAiTemplate.sections = newSections;
    setAiTemplate(newAiTemplate);
  };

  const handlePreview = (templateId: string) => {
    window.open(`/edit/ai-template/${templateId}`, '_self');
  };

  let promptIndex = 0;

  const modelOptions = [
    {
      label: 'gpt4',
      value: 'gpt4',
    },
    {
      label: 'gpt3.5',
      value: '3.5',
    },
  ];

  const imageServerOptions = [
    {
      label: 'MJ',
      value: 'mjm',
    },
    {
      label: 'CUSTOM',
      value: 'custom',
    },
  ];

  return (
    <>
      <header className={s.mainHeader}>
        <h2>
          <img src={leftIcon} onClick={() => props.onClose()} />
          {aiTemplate.name}
        </h2>
        <div>
          <Select
            options={imageServerOptions}
            onChange={handleSetTemplateImageServer}
            value={aiTemplate.settings.imageServer}
            prefix="Server:"
          />
        </div>
        <div>
          <Select
            options={modelOptions}
            onChange={handleSetTemplateModel}
            value={aiTemplate.settings.chaptGptModel}
            prefix="Model:"
          />
        </div>
        <button
          className={sCommon.addButton}
          onClick={() => handlePreview(aiTemplate._id)}
        >
          <EyeIcon />
          &nbsp; Preview
        </button>
        <button className={sCommon.addButton} onClick={() => handleSave()}>
          Save
        </button>
      </header>
      <main className={s.mainContent}>
        <section className={s.section}>
          <h2 className={s.header}>
            <span>Questions</span>
            <button
              onClick={() => handleAddQuestion()}
              className={sCommon.addButton}
            >
              <PlusIcon fill="#6c68ff" />
              &nbsp;&nbsp; Add
            </button>
          </h2>
          <div className={s.list}>
            <DragDropContext onDragEnd={handleQuestionsSorting}>
              <Droppable droppableId="questionsList">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {aiTemplate.questions.map((question, index) => (
                      <Draggable
                        key={index}
                        draggableId={`question-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <QuestionComponent
                              key={index}
                              index={index + 1}
                              onChange={(newQuestion) =>
                                handleChangeQuestion(index, newQuestion)
                              }
                              onDelete={() => handleDeleteQuestion(index)}
                              question={question}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <AddButton name="Add" onClick={() => handleAddQuestion()} />
          </div>
        </section>
        <section className={s.section}>
          <h2 className={s.header}>
            <span>Prompts</span>
            <button
              onClick={() => handleAddPrompt()}
              className={sCommon.addButton}
            >
              <PlusIcon fill="#6c68ff" />
              &nbsp;&nbsp; Add
            </button>
          </h2>
          <div className={s.list}>
            {aiTemplate.prompts.map((promptGroup, groupIndex) =>
              promptGroup.map((prompt, index) => {
                promptIndex++;
                return (
                  <PromptComponent
                    key={`${groupIndex}-${index}`}
                    index={promptIndex}
                    prompt={prompt}
                    groupId={groupIndex + 1}
                    onChange={(prompt) =>
                      handleUpdatePrompt(prompt, groupIndex, index)
                    }
                    onChangeGroup={(newGroupId) =>
                      handleChangeGroup(newGroupId, groupIndex, index)
                    }
                    totalGroups={aiTemplate.prompts.length}
                    onDelete={() => handleDeletePrompt(groupIndex, index)}
                  />
                );
              })
            )}
            <AddButton name="Add" onClick={() => handleAddPrompt()} />
          </div>
        </section>
        <section className={s.section}>
          <h2 className={s.header}>
            <span>Sections</span>
            <button
              onClick={() => handleAddSection()}
              className={sCommon.addButton}
            >
              <PlusIcon fill="#6c68ff" />
              &nbsp;&nbsp; Add
            </button>
          </h2>
          {dbSections.length && (
            <div className={s.list}>
              <DragDropContext onDragEnd={handleSectionsSorting}>
                <Droppable droppableId="sectionsList">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {aiTemplate.sections.map((section, index) => (
                        <Draggable
                          key={index}
                          draggableId={`section-${index}`}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <SectionComponent
                                key={index}
                                allSections={dbSections}
                                allIds={allIds}
                                index={index + 1}
                                onDelete={() => handleDeleteSection(index)}
                                selectedSectionName={section.templateName}
                                onSelectTemplate={(newTemplateName) =>
                                  handleChangeSectionTemplate(
                                    newTemplateName,
                                    index
                                  )
                                }
                                content={section.content}
                                onChangeAiField={(aiField, id) =>
                                  handleSetAiField(aiField, id, index)
                                }
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <AddButton name="Add" onClick={() => handleAddSection()} />
            </div>
          )}
        </section>
      </main>
    </>
  );
};

export default AiTemplateBuilder;
