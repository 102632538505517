import React, { useState } from 'react';
import styled from 'styled-components';
import ModalStyled from '../../../Styled/ModalStyled';
import BigStyledButton from '../../../Styled/BigStyledButton';
import { ReactComponent as CloseIcon } from '../../../Assets/icons/close.svg';
import { Input } from 'antd';

interface IPropsAdd {
  visible: boolean;
}

const StyledAddMenu = styled.div`
  visibility: ${(props: IPropsAdd) => (props.visible ? 'visible' : 'hidden')};
  .buttons-block {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

interface IProps {
  onSubmit: (name: string) => void;
  onClose: () => void;
  open: boolean;
}

const AddMenu = (props: IProps) => {
  const [name, setName] = useState('');

  const handleSubmit = () => {
    props.onSubmit(name);
    setName('');
    props.onClose();
  };

  const closeModal = () => {
    setName('');
    props.onClose();
  };

  return (
    <StyledAddMenu visible={props.open}>
      <ModalStyled visible={props.open}>
        <div className="modal-block">
          <div className="modal-header">
            <h2 className="modal-title">Menu name</h2>
            <CloseIcon className="close-icon" onClick={closeModal} />
          </div>
          {props.open && (
            <div className="modal-body">
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="name..."
              />
              <div className="buttons-block">
                <BigStyledButton
                  onClick={() => handleSubmit()}
                  disabled={!name.length}
                >
                  Submit
                </BigStyledButton>
              </div>
            </div>
          )}
        </div>
      </ModalStyled>
    </StyledAddMenu>
  );
};

export default AddMenu;
