import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import { ChartData, CategoryScale, ChartOptions } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import s from './statisticGraph.module.scss';
import { ReactComponent as StatisticsVisitsSvg } from '../../../Assets/icons/statisticsVisits.svg';
import { ReactComponent as StatisticsUsersSvg } from '../../../Assets/icons/statisticsUsers.svg';
import { ReactComponent as StatisticsSalesSvg } from '../../../Assets/icons/statisticsSales.svg';
import { ReactComponent as DoubleArrowSvg } from '../../../Assets/icons/doubleArrow.svg';
import { CategoryGraphData, GraphDataInterval } from '../types';

Chart.register(CategoryScale);

const labelIcon: { [key: string]: JSX.Element } = {
  Visitors: <StatisticsVisitsSvg width={16} height={16} />,
  'Opt-In Total': <StatisticsUsersSvg width={16} height={16} />,
  'Opt-In Rate': <StatisticsSalesSvg width={16} height={16} />,
};

interface IProps {
  statisticGraphData: CategoryGraphData;
  label: string;
  isComparePreviousPeriod: boolean;
  interval: GraphDataInterval;
}
// increase the value of the graph data so that the difference between zero and small values ​​is visible. (When displayed in a tooltip, the same value is subtracted)
const graphValueIncrease = 5;

function formatDates(
  data_series: { [date: string]: number },
  interval: GraphDataInterval
) {
  return Object.keys(data_series).map((item) => {
    const date = new Date(item);
    const month = date.toLocaleString('en-US', { month: 'short' });
    const day = date.getDate();
    let dateString = `${month} ${day}`;

    if (interval === 'hour') {
      const hour = date.getHours();
      const minute = date.getMinutes();
      dateString += ` ${hour < 10 ? '0' + hour : hour}:${
        minute < 10 ? '0' + minute : minute
      }`;
    }

    return dateString;
  });
}

const StatisticGraph = ({
  statisticGraphData,
  label,
  isComparePreviousPeriod,
  interval,
}: IProps) => {
  const [statisticActualGraphLabels, setStatisticActualGraphLabels] = useState<
    string[] | null
  >(null);
  const [statisticActualValues, setStatisticActualValues] = useState<
    number[] | null
  >(null);

  const [
    statisticPreviousGraphLabels,
    setStatisticPreviousGraphLabels,
  ] = useState<string[] | null>(null);
  const [statisticPreviousValues, setStatisticPreviousValues] = useState<
    number[] | null
  >(null);
  const [activeGraphBarInfo, setActiveGraphBarInfo] = useState<{
    xPosition: string;
    yPosition: string;
    title: string;
    active: boolean;
  } | null>(null);
  useEffect(() => {
    if (!statisticGraphData) {
      return;
    }
    if (statisticGraphData.actualStatisticData) {
      setStatisticActualGraphLabels(
        formatDates(
          statisticGraphData.actualStatisticData.data_series,
          interval
        )
      );
      const actualDataSeries =
        statisticGraphData.actualStatisticData.data_series;
      setStatisticActualValues(
        Object.keys(actualDataSeries).map((key) => {
          if (actualDataSeries[key] !== 0) {
            return actualDataSeries[key] + graphValueIncrease;
          } else {
            return actualDataSeries[key];
          }
        })
      );
    }
    if (statisticGraphData.previousStatisticData && isComparePreviousPeriod) {
      setStatisticPreviousGraphLabels(
        formatDates(
          statisticGraphData.previousStatisticData.data_series,
          interval
        )
      );
      const previousDataSeries =
        statisticGraphData.actualStatisticData.data_series;
      setStatisticPreviousValues(
        Object.keys(previousDataSeries).map((key) => {
          if (previousDataSeries[key] !== 0) {
            return previousDataSeries[key] + graphValueIncrease;
          } else {
            return previousDataSeries[key];
          }
        })
      );
    }
  }, [statisticGraphData, isComparePreviousPeriod]);

  const data: ChartData<'bar'> = {
    labels: statisticActualGraphLabels ? statisticActualGraphLabels : [],
    datasets: [
      {
        label: 'Dataset 1',
        data: statisticActualValues ? statisticActualValues : [],
        backgroundColor: 'rgba(73, 87, 216, 0.2)',
        hoverBackgroundColor: 'rgba(73, 87, 216, 0.4)',
        borderColor: '#4957D8',
        barPercentage: 1,
        categoryPercentage: 1,
        minBarLength: 7,
      },
      {
        label: 'Dataset 2',
        data:
          statisticPreviousValues && isComparePreviousPeriod
            ? statisticPreviousValues
            : [],
        backgroundColor: '#FAFAFA',
        hoverBackgroundColor: '#FFFFFF',
        borderColor: '#D0D0D0',
        barPercentage: 1,
        categoryPercentage: 1,
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    elements: {
      bar: {
        borderWidth: { top: 6, right: 0, bottom: 0, left: 0 },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          maxRotation: 0,
          display: false,
          // autoSkip: false,
          // callback: function (value: any, index: number, values: any) {
          //   if (
          //     (index === 0 || index === values.length - 1) &&
          //     statisticActualGraphLabels
          //   ) {
          //     return `${statisticActualGraphLabels[index]}`;
          //   } else {
          //     return '';
          //   }
          // },
          // color: '#4957D8',
          // font: {
          //   size: 14,
          //   family: 'Quicksand',
          //   weight: '500',
          // },
          // padding: 20,
        },
      },
      y: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        xAlign: 'center',
        caretSize: 0,
        animation: false,
        backgroundColor: (context) => {
          const chart = context.chart;
          if (chart.tooltip) {
            const active = chart.tooltip.getActiveElements();
            if (active[0]) {
              const datasetIndex = active[0].datasetIndex;
              if (datasetIndex === 1) {
                return ' #5A5A5A';
              }
              if (datasetIndex === 0) {
                return ' #4957D8';
              }
            }
          }

          return '#5A5A5A';
        },
        titleAlign: 'center',
        titleFont: {
          size: 14,
          family: 'Inter',
          weight: '400',
        },
        bodyAlign: 'center',
        bodyFont: {
          size: 16,
          family: 'Inter',
          weight: '500',
        },

        displayColors: false,
        external(args: any) {
          const tooltip = args.tooltip;
          if (
            (activeGraphBarInfo?.xPosition !== `${tooltip.x}px` ||
              activeGraphBarInfo?.yPosition !== `${tooltip.y}px` ||
              activeGraphBarInfo.active !== tooltip.opacity) &&
            tooltip.dataPoints
          ) {
            const elIndex = tooltip.dataPoints[0].dataIndex;
            const dataSetIndex = tooltip.dataPoints[0].datasetIndex;
            let labelArr: string[] | null = [];
            if (dataSetIndex === 0 && statisticActualGraphLabels) {
              labelArr = statisticActualGraphLabels;
            } else if (dataSetIndex === 1 && statisticPreviousGraphLabels) {
              labelArr = statisticPreviousGraphLabels;
            }
            const title = labelArr[elIndex];

            setActiveGraphBarInfo({
              xPosition: `${tooltip.x}px`,
              yPosition: `${tooltip.y}px`,
              title: title,
              active: tooltip.opacity,
            });
          }
        },
        callbacks: {
          label: (tooltipItem) => {
            if (tooltipItem.formattedValue !== '0') {
              return `${+tooltipItem.formattedValue - graphValueIncrease}`;
            } else {
              return `${tooltipItem.formattedValue}`;
            }
          },
          title: (tooltipItem) => {
            return '';
          },
        },
      },
    },
  };

  const getSubstringBeforeDash = (date: string) => {
    const match = date.match(/(.+?)\s*-/);
    const result = match ? match[1] : date;
    return result;
  };

  if (!statisticGraphData) return null;

  return (
    <div className={s.graphBlockWrapper}>
      <div className={s.graphBlockLabel}>
        {labelIcon[label]} {label}
        <div>
          <span className={s.actualTotalValue}>
            {statisticGraphData.actualStatisticData.aggr}
          </span>
          {isComparePreviousPeriod && (
            <>
              <DoubleArrowSvg className={s.doubleArrowSvg} />
              <span className={s.previousTotalValue}>
                {statisticGraphData.previousStatisticData?.aggr}
              </span>
              {statisticGraphData.actualStatisticData.percent_aggr !== '-%' && (
                <p
                  className={
                    statisticGraphData.actualStatisticData.percent_aggr.includes(
                      '-'
                    )
                      ? `${s.differencePercent} ${s.differencePercentRed}`
                      : `${s.differencePercent} ${s.differencePercentGreen}`
                  }
                >
                  {statisticGraphData.actualStatisticData.percent_aggr}
                </p>
              )}
            </>
          )}
        </div>
      </div>
      <div className={s.graphBlock}>
        <Bar data={data} options={options} />
        {activeGraphBarInfo?.active && (
          <div
            style={{
              left: activeGraphBarInfo?.xPosition
                ? activeGraphBarInfo.xPosition
                : '10px',
            }}
            className={s.topActiveGraphTooltip}
          >
            {activeGraphBarInfo?.title && activeGraphBarInfo.title}
          </div>
        )}
        <div className={s.xLabelBlock}>
          <div>
            {statisticActualGraphLabels &&
              getSubstringBeforeDash(statisticActualGraphLabels[0])}
            <span>
              {statisticPreviousGraphLabels &&
                isComparePreviousPeriod &&
                getSubstringBeforeDash(statisticPreviousGraphLabels[0])}
            </span>
          </div>
          <div>
            {statisticActualGraphLabels &&
              getSubstringBeforeDash(
                statisticActualGraphLabels[
                  statisticActualGraphLabels.length - 1
                ]
              )}
            <span>
              {statisticPreviousGraphLabels &&
                isComparePreviousPeriod &&
                getSubstringBeforeDash(
                  statisticPreviousGraphLabels[
                    statisticPreviousGraphLabels.length - 1
                  ]
                )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticGraph;
