import React, { useEffect, useState } from 'react';
import s from './AiTemplates.module.scss';
import xSvg from '../Assets/x.svg';
import sCommon from '../Common/Common.module.scss';

interface IProps {
  name: string;
  image?: string;
  imageServer: string;
  model: string;
  onDelete: () => void;
  onChangeName: (newName: string) => void;
  onOpen: () => void;
  onPreview: () => void;
  onStartTHumbnailUpload: () => void;
}

const AiTemplateCard = (props: IProps) => {
  const [editMode, setEditMode] = useState(false);
  const [editingName, setEditingName] = useState(props.name);

  useEffect(() => {
    setEditingName(props.name);
    setEditMode(false);
  }, [props.name]);

  const handleSave = () => {
    props.onChangeName(editingName);
    setEditMode(false);
  };

  const handleStartEdit = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setEditMode(true);
  };

  return (
    <div className={s.aiTemplateCard}>
      <span onClick={(e) => handleStartEdit(e)}>
        {editMode ? (
          <input
            className={s.editName}
            type="text"
            value={editingName}
            onChange={(e) => setEditingName(e.target.value)}
            onBlur={() => handleSave()}
          />
        ) : (
          <span className={s.name}>{editingName}</span>
        )}
      </span>
      <span className={s.imageBlock}>
        <span>{props.imageServer}</span>
        <span>
          {props.image ? (
            <img
              src={props.image}
              className={s.thumbnail}
              onClick={() => props.onStartTHumbnailUpload()}
            />
          ) : (
            <button
              className={sCommon.addButton + ' ' + sCommon.small}
              onClick={() => props.onStartTHumbnailUpload()}
            >
              no image
            </button>
          )}
        </span>
      </span>
      <span className={s.listButtons}>
        <span>{props.model}</span>
        <button
          onClick={() => props.onOpen()}
          className={sCommon.addButton + ' ' + sCommon.small}
        >
          Edit
        </button>
        <button
          onClick={() => props.onPreview()}
          className={sCommon.addButton + ' ' + sCommon.small}
        >
          Preview
        </button>
      </span>
      <div className={s.closeButton} onClick={() => props.onDelete()}>
        <img src={xSvg} />
      </div>
    </div>
  );
};

export default AiTemplateCard;
