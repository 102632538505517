export const GET_FUNNELS_WITH_PAGES_QUERY = {
  operationName: 'getFunnelWithPages',
  query: `query getFunnelWithPages(
      $id: String!
    ) {
      getFunnelWithPages(
        id: $id
      ) {
        funnel {
          description
          name
          title
          _id
          archived
          mailchimpAPI
          mailchimpAudienceId
          mailchimpUsername
          domain
          headerScript
          footerScript
          stripePublicKey
          stripeSecretKey
          customSubDomain
          position
          products {
            name
            descriptor
            price
            currency
            isSubscription
            isSevenDayTrial
          },
          order {
            id
            enabled
          }
          pages {
            _id
            name
            enabled
            visited
            conversion
            archived
            headerScript
            footerScript
            createdAt
            updatedAt
            thumbnail
          }
        }
        pages {
          _id
          name
          enabled
          visited
          conversion
          archived
          headerScript
          footerScript
          createdAt
          updatedAt
          thumbnail
          status
          pages{
            image
          }
          tags {
            _id
            name
          }
        }
      }
    }`,
};
