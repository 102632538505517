import React, { useEffect, useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { ReactComponent as FilterIcon } from 'Assets/icons/filter.svg';
import { ReactComponent as CrossIcon } from 'Assets/icons/cross.svg';
import Button from 'UILib/Button/Button';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';

import s from 'Pages/PageContacts/SmartList/SmartListPopup.module.scss';
import { Tag } from '../Helper/types';

interface Props {
  smart: boolean;
  setSmart: React.Dispatch<React.SetStateAction<boolean>>;
  setSmartLists: React.Dispatch<React.SetStateAction<any[]>>;
  uniqueTags: Tag[];
  fetchUniqueTags: () => void;
  fetchSmartLists: () => void;
}

const SmartList = (props: Props) => {
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [tagsArray, setTagsArray] = useState<string[]>([]);
  const [exactTagsArray, setExactTagsArray] = useState<string[]>([]);
  const [name, setName] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

  const handleSmartAdd = async () => {
    if (!btnDisabled) {
      props.setSmart(false);
      const newListName = name.trim();
      const exact = exactTagsArray;
      const includeAll: string[] = [];
      const includeAny: string[] = [];
      const excludeAll: string[] = [];
      const maxContactAge = undefined;
      try {
        const response = await graphQlCall({
          queryTemplateObject: QUERIES.CREATE_SMART_LIST,
          values: {
            name: newListName,
            exact,
            includeAll,
            includeAny,
            excludeAll,
            maxContactAge,
          },
          headerType: 'USER-AUTH',
        });
        if (response.data && response.data.createSmartList) {
          props.setSmartLists((prevSmartLists: any[]) => [
            ...prevSmartLists,
            response.data.createSmartList,
          ]);
          setName('');
          setTagsArray([]);
          setExactTagsArray([]);
          setBtnDisabled(true);
          setDropdownVisible(false);
        } else {
          throw new Error('Failed to create smart list');
        }
      } catch (error) {
        props.fetchSmartLists();
        console.error('Error creating smart list:', error);
      }
    }
  };

  const handleSmartClose = () => {
    props.setSmart(!props.smart);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setName(value);
    updateSecondBtnDisabled(value, tagsArray);
  };

  const updateSecondBtnDisabled = (nameValue: string, tagsArray: string[]) => {
    if (nameValue.trim() === '' || tagsArray.length === 0) {
      setBtnDisabled(true);
    } else {
      setBtnDisabled(false);
    }
  };

  const handleDropdownClick = () => {
    props.fetchUniqueTags();
    setDropdownVisible(!dropdownVisible);
  };

  const handleTagClick = (tag: Tag) => {
    const tagName = tag.name;
    const tagId = tag._id;

    if (!tagsArray.includes(tagName)) {
      const updatedTags = [...tagsArray, tagName];
      setTagsArray(updatedTags);
      updateSecondBtnDisabled(name, updatedTags);

      const updatedExactTags = [...exactTagsArray, tagId];
      setExactTagsArray(updatedExactTags);
    }
    setDropdownVisible(false);
  };

  const handleTagRemove = (tagToRemove: string) => {
    const updatedTags = tagsArray.filter((tag) => tag !== tagToRemove);
    setTagsArray(updatedTags);
    updateSecondBtnDisabled(name, updatedTags);
  };

  const menu = (
    <Menu className={s.tagMenu}>
      {props.uniqueTags.map((tag) => (
        <Menu.Item
          key={tag._id}
          className={s.tagDisplay}
          onClick={() => handleTagClick(tag)}
        >
          {tag.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      {props.smart && (
        <span className={s.smartCover}>
          <div>
            <div>
              <div>
                <FilterIcon />
              </div>
              <div className={s.filterText}>
                <div className={s.filterTopText}>Create Smart List</div>
                <div className={s.filterBottomtext}>based on your tags</div>
              </div>
              <CrossIcon className={s.close} onClick={handleSmartClose} />
            </div>
            <div className={s.hr}></div>
            <p>
              Name<span>*</span>
            </p>
            <input
              placeholder={'Give a Name'}
              value={name}
              onChange={handleNameChange}
              className={s.filterInput}
            />
            <p>
              Tags<span>*</span>
            </p>
          </div>
          <div>
            <Dropdown
              overlay={menu}
              trigger={['click']}
              visible={dropdownVisible}
              onVisibleChange={(visible) => setDropdownVisible(visible)}
            >
              <input
                placeholder={'Select Tags'}
                onClick={handleDropdownClick}
                readOnly
                className={s.filterInputTags}
              />
            </Dropdown>
            <div className={s.addedTags}>
              {tagsArray.map((tag, index) => (
                <span key={index} className={s.tag}>
                  {tag}
                  <button
                    className={s.crossBtn}
                    onClick={() => handleTagRemove(tag)}
                  >
                    <CrossIcon className={s.cross} />
                  </button>
                </span>
              ))}
            </div>
          </div>
          <div className={s.filterBtns}>
            <Button className={s.whiteBtn} onClick={handleSmartClose}>
              Cancel
            </Button>
            <Button
              className={btnDisabled ? s.disabledBtn : s.blackBtn}
              onClick={handleSmartAdd}
              disabled={btnDisabled}
            >
              Create List
            </Button>
          </div>
        </span>
      )}
    </>
  );
};

export default SmartList;
