export const CHECK_SUBSCRIPTION_ACCESS = {
  operationName: 'checkSubscriptionAccess',
  query: `query checkSubscriptionAccess {
      checkSubscriptionAccess {
        bookle
        funnel
        copyCraft
        agency
      }
    }`,
};
