import s from './Logo.module.scss';
import logo from 'Assets/images/loginPage/AutofunnelLogo.svg';
import { CONSULT_URL } from 'Constants';

interface LogoProps {
  smallSize?: boolean;
}

const Logo = (props: LogoProps) => {
  const handleCLick = () => {
    window.open(CONSULT_URL);
  };
  return (
    <>
      <img
        alt="'CREATE' logo"
        className={props.smallSize ? s.logoSingIn : s.logo}
        src={logo}
        onClick={handleCLick}
      />
    </>
  );
};

export default Logo;
