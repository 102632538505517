import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IUserProduct } from 'types';
import { graphQlCall } from '../../graphql/utils';
import { RootState } from '../rootReducer';
import QUERIES from '../../graphql/queries';

export const SET_USER_PRODUCTS = 'SET_USER_PRODUCTS';
export const PRODUCTS_LOADING = 'PRODUCTS_LOADING';
export const UPDATE_POPUP = 'UPDATE_POPUP';

type DispatchType = ThunkDispatch<RootState, void, Action>;
type GetStateType = () => RootState;

export const fetchUserProductsAction = () => async (dispatch: DispatchType) => {
  dispatch(loading(true));
  try {
    let products: IUserProduct[] = await graphQlCall({
      queryTemplateObject: QUERIES.GET_USER_PRODUCTS,
      headerType: 'USER-AUTH',
    });

    const stateAction = {
      type: SET_USER_PRODUCTS,
      payload: products,
    };
    dispatch(stateAction);
  } catch (error) {
    console.error(error);
  }
  dispatch(loading(false));
};

export const addUserProductAction = (newProduct: IUserProduct) => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  dispatch(loading(true));
  const state = getState();
  const existedProducts = [...state.products.items];
  try {
    const product = await graphQlCall({
      queryTemplateObject: QUERIES.CREATE_PRODUCT_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        ...newProduct,
      },
    });
    const userProducts = [...existedProducts, product];
    const stateAction = {
      type: SET_USER_PRODUCTS,
      payload: userProducts,
    };
    dispatch(stateAction);
  } catch (error) {
    console.error(error);
    const stateAction = {
      type: SET_USER_PRODUCTS,
      payload: existedProducts,
    };
    dispatch(stateAction);
  }
  dispatch(loading(false));
};

export const updateUserProductAction = (
  updatingProduct: IUserProduct
) => async (dispatch: DispatchType, getState: GetStateType) => {
  dispatch(loading(true));
  const state = getState();
  const existedProducts = [...state.products.items];
  let userProducts = existedProducts.map((item) => {
    if (item._id === updatingProduct._id) {
      item = { ...updatingProduct };
    }
    return item;
  });
  const stateAction = {
    type: SET_USER_PRODUCTS,
    payload: userProducts,
  };
  dispatch(stateAction);
  try {
    const values: any = { ...updatingProduct };
    values.id = updatingProduct._id;
    delete values._id;

    await graphQlCall({
      queryTemplateObject: QUERIES.UPDATE_PRODUCT_MUTATION,
      headerType: 'USER-AUTH',
      values,
    });
  } catch (error) {
    console.error(error);
    const stateAction = {
      type: SET_USER_PRODUCTS,
      payload: existedProducts,
    };
    dispatch(stateAction);
  }
  dispatch(loading(false));
};

export const deleteProductAction = (id: string) => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  dispatch(loading(true));
  const state = getState();
  const existedProducts = [...state.products.items];
  let userProducts = existedProducts.filter((item) => item._id !== id);
  const stateAction = {
    type: SET_USER_PRODUCTS,
    payload: userProducts,
  };
  dispatch(stateAction);
  try {
    await graphQlCall({
      queryTemplateObject: QUERIES.DELETE_PRODUCT_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        id,
      },
    });
  } catch (error) {
    console.error(error);
    const stateAction = {
      type: SET_USER_PRODUCTS,
      payload: existedProducts,
    };
    dispatch(stateAction);
  }
  dispatch(loading(false));
};

export const productPopupAction = (payload: {
  open?: boolean;
  initialState?: IUserProduct;
}) => async (dispatch: DispatchType) => {
  const stateAction = {
    type: UPDATE_POPUP,
    payload,
  };
  dispatch(stateAction);
};

export const loading = (payload: boolean) => ({
  type: PRODUCTS_LOADING,
  payload,
});
