import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Dropdown, Menu } from 'antd';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { RootState } from 'store/rootReducer';
import { queueUpdatePage } from 'store/projects/projectsQueueActions';
import { fetchUserDetails } from 'store/user/userActions';
import { ReactComponent as PlusIcon } from 'Assets/icons/PlusContacts.svg';
import { ReactComponent as CloseIcon } from 'Assets/icons/close.svg';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import { graphQlCall } from 'graphql/utils';
import { IFunnel, ITag } from 'types';
import { Tag } from 'Pages/PageContacts/Helper/types';
import { loading } from 'store/projects/projectsActions';
import QUERIES from 'graphql/queries';
import Loader from 'UILib/Loader/Loader';
import Textarea from '../../Common/Textarea/Textarea';
import PrimaryButton from '../../Common/PrimaryButton/PrimaryButton';
import Modal from '../../Common/Modal/Modal';

import s from './PageSettings.module.scss';

type DispatchType = ThunkDispatch<RootState, void, Action>;

interface IUpdatePageData {
  headerScript: string;
  footerScript: string;
  tagsId: string[];
}

interface IProps {
  onClose: () => void;
  updatePageInfo: (data: IUpdatePageData, page: IFunnel) => void;
  page?: IFunnel;
  show: boolean;
  funnelId: string;
  pageId: string;
  userEmail: string;
  fetchUserDetails: () => void;
  getPageData: () => void;
  loading: boolean;
  handleLoading: (isLoading: boolean) => void;
}

const PageSettings: React.FC<IProps> = (props: IProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  UseOnClickOutside(modalRef, (e) => {
    if (dropdownRef.current && dropdownRef.current.contains(e.target as Node)) {
      return;
    }
    props.onClose();
  });

  const [headerScript, setHeaderScript] = useState<string>('');
  const [footerScript, setFooterScript] = useState<string>('');
  const [tags, setTags] = useState<ITag[]>([]);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [uniqueTags, setUniqueTags] = useState<Tag[]>([]);
  const [tagInput, setTagInput] = useState<string>('');
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  const handleSave = () => {
    if (!props.page) return;
    props.handleLoading(true);
    setIsUpdated(true);
    props.updatePageInfo(
      {
        headerScript,
        footerScript,
        tagsId: tags.map((e) => e._id),
      },
      props.page
    );
  };

  const handleClose = () => {
    props.getPageData();
    props.onClose();
  };

  useEffect(() => {
    if (isUpdated && !props.loading) {
      handleClose();
    }
  }, [isUpdated, props.loading]);

  useEffect(() => {
    if (props.page) {
      setHeaderScript(props.page.headerScript);
      setFooterScript(props.page.footerScript);
      setTags(props.page.tags);
    }
  }, [props.page]);

  useEffect(() => {
    props.fetchUserDetails();
  }, []);

  const fetchUniqueTags = async () => {
    try {
      const response = await graphQlCall({
        queryTemplateObject: QUERIES.GET_USER_CONTACT_TAGS,
        headerType: 'USER-AUTH',
      });
      setUniqueTags(response);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  const addTagToPerson = async (email: string, tagName: string) => {
    if (!tagName.trim()) {
      console.error('Tag name cannot be empty');
      return;
    }

    try {
      const response = await graphQlCall({
        queryTemplateObject: QUERIES.ADD_CONTACT_TAG,
        headerType: 'USER-AUTH',
        values: { tagName },
      });
      setUniqueTags((prev) => [...prev, response]);
      setTags((prev) => [...prev, response]);
    } catch (error) {
      console.error('Error adding tag:', error);
    }
  };

  const handleTagClick = async (tag: ITag) => {
    setTags([...tags, tag]);
    setDropdownVisible(false);
  };

  const handleAddTagToSelected = async (tag: {
    _id: string;
    name: string;
    createdAt: string;
  }) => {
    await addTagToPerson(props.userEmail, tag.name);

    setTagInput('');
  };

  const handleDropdownClick = () => {
    fetchUniqueTags();
    setDropdownVisible(!dropdownVisible);
  };

  const handleRemoveTag = (id: string) => {
    setTags([...tags].filter((e) => e._id !== id));
  };

  const menu = (
    <div ref={dropdownRef}>
      <Menu className={s.tagMenu}>
        {uniqueTags.map((tag: Tag) => {
          const isDisabled = !!tags.find((e) => e._id === tag._id);
          return (
            <Menu.Item
              key={tag._id}
              className={clsx(s.tagDisplay, { [s.disabled]: isDisabled })}
              onClick={() => handleTagClick(tag)}
              disabled={isDisabled}
            >
              {tag.name}
            </Menu.Item>
          );
        })}
      </Menu>
      <Menu className={s.secondMenu}>
        <Menu.Divider />
        <Menu.Item key="2">
          <div className={s.popupInput}>
            <div className={s.inputWrapper}>
              <input
                placeholder="Add New Tag"
                value={tagInput}
                onChange={(e) => setTagInput(e.target.value)}
                onClick={(e) => e.stopPropagation()}
              />
              <button
                className={s.inputButton}
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddTagToSelected({
                    _id: '',
                    name: tagInput,
                    createdAt: '',
                  });
                }}
              >
                <PlusIcon />
              </button>
            </div>
          </div>
        </Menu.Item>
      </Menu>
    </div>
  );

  return (
    <Modal displayModal={props.show}>
      <div className={s.modalBlock} ref={modalRef}>
        {props.loading && (
          <div className={s.loader}>
            <Loader size={20} />
          </div>
        )}
        <div className={s.modalHeader}>
          <button className={s.closeIcon} onClick={() => props.onClose()} />
          <h1 className={s.modalTitle}>Page Settings</h1>
        </div>
        <div className={s.modalBody}>
          <div>Add Tag to Page Action</div>
          <div className={s.tagsContainer}>
            <Dropdown
              overlay={menu}
              trigger={['click']}
              visible={dropdownVisible}
              onVisibleChange={(visible) => setDropdownVisible(visible)}
            >
              <button className={s.addTag} onClick={handleDropdownClick}>
                <PlusIcon />
              </button>
            </Dropdown>
            <div className={s.tags}>
              {tags.map((e) => (
                <div className={s.tag}>
                  {e.name}
                  <CloseIcon
                    className={s.removeTag}
                    onClick={() => handleRemoveTag(e._id)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={s.textArea}>
            <Textarea
              width={760}
              label={'Header Block'}
              placeholder={'Add HTML Code for header'}
              value={headerScript}
              setState={setHeaderScript}
            />
          </div>
          <div className={s.textArea}>
            <Textarea
              width={760}
              label={'Footer Block'}
              placeholder={'Add HTML Code for footer'}
              value={footerScript}
              setState={setFooterScript}
            />
          </div>
          <div className={s.buttonBlock}>
            <PrimaryButton onClick={handleSave}>Save</PrimaryButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  activeProject: state.projects.active,
  userEmail: state.user.details.email,
  loading: state.projects.loading,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  updatePageInfo: (data: IUpdatePageData, pageId: IFunnel) =>
    dispatch(queueUpdatePage(data, pageId._id)),
  fetchUserDetails: () => dispatch(fetchUserDetails()),
  handleLoading: (isLoading: boolean) => dispatch(loading(isLoading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageSettings);
