import React, { useState } from 'react';
import styled from 'styled-components';
import ModalStyled from '../../../Styled/ModalStyled';
import BigStyledButton from '../../../Styled/BigStyledButton';
import SmallButton from '../../../Styled/SmallStyledButton';
import { ReactComponent as CloseIcon } from '../../../Assets/icons/close.svg';
import { Select } from 'antd';
import { ISection } from 'types';

interface IPropsAdd {
  visible: boolean;
}

const StyledAdd = styled.div`
  .buttons-block {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

interface IProps {
  onSubmit: (value: string | undefined) => void;
  sections: ISection[];
}

const AddSection = (props: IProps) => {
  const [selectedSection, setSelectedSection] = useState<string | undefined>();
  const [show, setShow] = useState(false);

  const handleSubmit = () => {
    props.onSubmit(selectedSection);
    setSelectedSection(undefined);
    setShow(false);
  };

  const turnOnModal = () => {
    setSelectedSection(undefined);
    setShow(true);
  };

  const closeModal = () => {
    setSelectedSection(undefined);
    setShow(false);
  };

  return (
    <StyledAdd>
      <SmallButton onClick={() => turnOnModal()}>Add Section</SmallButton>
      <ModalStyled visible={show}>
        <div className="modal-block">
          <div className="modal-header">
            <h2 className="modal-title">Add Section</h2>
            <CloseIcon className="close-icon" onClick={closeModal} />
          </div>
          {show && (
            <div className="modal-body">
              <div>
                <Select
                  style={{ width: '100%' }}
                  placeholder="select section"
                  onChange={(v) => setSelectedSection(v)}
                >
                  {props.sections.map((section) => (
                    <Select.Option key={section._id} value={section._id}>
                      {section.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="buttons-block">
                <BigStyledButton
                  onClick={() => handleSubmit()}
                  disabled={!selectedSection}
                >
                  Submit
                </BigStyledButton>
              </div>
            </div>
          )}
        </div>
      </ModalStyled>
    </StyledAdd>
  );
};

export default AddSection;
