import { useCallback, useEffect, useMemo } from 'react';
import { Slate, Editable, withReact, RenderElementProps } from 'slate-react';
import {
  Transforms,
  createEditor,
  Node,
  Element as SlateElement,
  Descendant,
  Editor,
} from 'slate';
import { withHistory } from 'slate-history';
import AudioBlock from 'UILib/AudioBlock/AudioBlock';

import styles from './TextEditor.module.scss';

interface IProps {
  hardRender?: boolean;
  initialValue: Descendant[];
  onChange: (newValue: Descendant[]) => void;
  onHardRender?: () => void;
}

const withEmbeds = (editor: Editor) => {
  const { isVoid } = editor;
  editor.isVoid = (element) =>
    element.type === 'audio' ? true : isVoid(element);
  return editor;
};

const withLayout = (editor: Editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      for (const [child, childPath] of Node.children(editor, path)) {
        let type: 'paragraph' | 'title' | 'audio';
        const slateIndex = childPath[0];
        const enforceType = (type: 'paragraph' | 'title' | 'audio') => {
          if (SlateElement.isElement(child) && child.type !== type) {
            const newProperties: Partial<SlateElement> = { type };
            Transforms.setNodes<SlateElement>(editor, newProperties, {
              at: childPath,
            });
          }
        };

        switch (slateIndex) {
          case 0:
            type = 'title';
            enforceType(type);
            break;
          default:
            break;
        }
      }
    }

    return normalizeNode([node, path]);
  };

  return editor;
};

const TextEditor = ({
  hardRender,
  initialValue,
  onChange,
  onHardRender,
}: IProps) => {
  const renderElement = useCallback(
    (props: RenderElementProps) => <Element {...props} />,
    []
  );

  const editor = useMemo(
    () => withEmbeds(withLayout(withHistory(withReact(createEditor())))),
    []
  );

  useEffect(() => {
    if (!hardRender) return;

    const totalNodes = editor.children.length;

    if (initialValue.length <= 0) return;

    for (let i = 0; i < totalNodes - 1; i++) {
      Transforms.removeNodes(editor, {
        at: [totalNodes - i - 1],
      });
    }

    for (const value of initialValue) {
      Transforms.insertNodes(editor, value, {
        at: [editor.children.length],
      });
    }

    Transforms.removeNodes(editor, {
      at: [0],
    });
    if (onHardRender) onHardRender();
  }, [editor, hardRender, initialValue]);

  return (
    <Slate
      editor={editor}
      initialValue={initialValue}
      onChange={(e) => {
        if (!editor.operations.every((op) => op.type === 'set_selection'))
          onChange(e);
      }}
    >
      <Editable
        renderElement={renderElement}
        className={styles.editor}
        spellCheck
      />
    </Slate>
  );
};

const Element = (props: RenderElementProps) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case 'title':
      return (
        <h2 className={styles.title} {...attributes}>
          {children}
        </h2>
      );
    case 'paragraph':
      return (
        <p className={styles.paragraph} {...attributes}>
          {children}
        </p>
      );
    case 'audio':
      return (
        <div className={styles.audio} contentEditable={false}>
          <AudioBlock {...element.data} />
        </div>
      );
  }
};

export default TextEditor;
