import React, { useEffect, useState, useRef } from 'react';
import { notification } from 'antd';
import { IAccount, IAddValues } from '../types';
import { ReactComponent as CameraIcon } from '../icons/camera.svg';
import Input from './Input';
import Textarea from './Textarea';
import Button from './Button';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';
import { api } from 'utils/Utils';
import { PAGECRAFT_API_URL } from 'Constants';
import { getAgencyToken } from 'utils/Utils';
import Popup from 'UILib/Popup/Popup';
import s from './popups.module.scss';

interface IProps {
  account?: IAccount | null;
  onClose: () => void;
  onSubmit: (account: IAccount) => void;
  editUser: boolean;
}

const AddNewPopup = (props: IProps) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [notes, setNotes] = useState('');
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [image, setImage] = useState<File | null>(null);
  const [nameError, setNameError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!props.account) {
      return;
    }
    setName(props.account.name);
    if (props.account.email) {
      setEmail(props.account.email);
    }
    if (props.account.customData?.notes || props.account.notes) {
      setNotes(props.account.customData?.notes || props.account.notes || '');
    }
  }, [props.account]);

  const handleClose = () => {
    clearState();
    props.onClose();
  };

  const clearState = () => {
    setName('');
    setEmail('');
    setNotes('');
    setErrorText('');
    setImage(null);
    setNameError(false);
  };

  const handleFileUpload = async () => {
    if (!inputRef?.current) {
      return;
    }
    const files = inputRef.current.files;
    if (!files?.length) {
      return;
    }
    let file = files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (res) => {
      const content = reader.result;
      const imageSrc = String(content);
      setUploadedImage(imageSrc);
    };

    setImage(file);
  };

  const handleSubmit = async () => {
    let hasErrors = false;
    setNameError(false);
    if (!name.trim().length) {
      setNameError(true);
      hasErrors = true;
    }
    if (hasErrors) {
      return;
    }

    const accountPayload = {
      name,
      email,
      notes,
      image,
    };
    if (props.account) {
      processEditAccount(accountPayload);
    } else {
      processAddAccount(accountPayload);
    }
  };

  const processAddAccount = async (accountData: IAddValues) => {
    const { email, name, notes, image } = accountData;
    try {
      const newAccount: IAccount = await graphQlCall({
        queryTemplateObject: QUERIES.ADD_SUB_ACCOUNT,
        headerType: 'AGENCY_AUTH',
        values: {
          name,
          email,
          notes,
        },
      });
      if (image) {
        await uploadAccountImage(newAccount._id, image);
      }
      if (uploadedImage) {
        newAccount.thumbnail = uploadedImage;
      }
      props.onSubmit(newAccount);
      clearState();
    } catch (error: any) {
      notification.error({
        message: 'Error!',
        description: error.message,
        placement: 'topRight',
        duration: 2,
      });
    }
  };

  const uploadAccountImage = async (id: string, image: File) => {
    const request = new FormData();
    request.append('file', image);
    const headers = {
      authorization: getAgencyToken(),
    };
    await api(
      `${PAGECRAFT_API_URL}/users/thumbnail/${id}`,
      'POST',
      request,
      headers
    );
  };

  const processEditAccount = async (accountData: IAddValues) => {
    if (!props.account) {
      return;
    }
    const { email, name, notes, image } = accountData;

    try {
      if (props.editUser) {
        await graphQlCall({
          queryTemplateObject: QUERIES.UPDATE_USER_MUTATION,
          headerType: 'AGENCY_AUTH',
          values: {
            name,
            customData: JSON.stringify({ notes }),
          },
        });
      } else {
        await graphQlCall({
          queryTemplateObject: QUERIES.EDIT_SUB_ACCOUNT,
          values: {
            id: props.account._id,
            name,
            email,
            notes,
          },
          headerType: 'AGENCY_AUTH',
        });
      }
      if (image) {
        await uploadAccountImage(props.account._id, image);
      }
      const newAccount: IAccount = { ...props.account, ...accountData };
      if (uploadedImage) {
        newAccount.thumbnail = uploadedImage;
      }
      props.onSubmit(newAccount);
      clearState();
    } catch (error: any) {
      notification.error({
        message: 'Error!',
        description: error.message,
        placement: 'topRight',
        duration: 2,
      });
    }
  };

  return (
    <Popup onClose={handleClose}>
      <div className={s.modalBody}>
        <div
          className={s.thumbnailHolder}
          onClick={() => inputRef?.current?.click()}
        >
          {props.account?.thumbnail && !uploadedImage && (
            <img src={props.account.thumbnail} className={s.thumbnail} />
          )}
          {uploadedImage && <img src={uploadedImage} className={s.thumbnail} />}
          <CameraIcon className={s.cameraIcon} />
        </div>
        <Input type="name" onChange={setName} value={name} error={nameError} />
        {!props.editUser && (
          <Input type="email" onChange={setEmail} value={email} />
        )}
        <div className={s.errorBlock}>{errorText}</div>
        <Textarea value={notes} onChange={setNotes} placeholder="Notes" />
        <Button
          name={props.account ? 'Save' : 'Create'}
          onClick={() => handleSubmit()}
        />
        <input
          type="file"
          style={{ display: 'none' }}
          accept="image/*"
          ref={inputRef}
          onChange={() => handleFileUpload()}
        />
      </div>
    </Popup>
  );
};

export default AddNewPopup;
