import React from 'react';
import Button from '../../Components/Common/DomainButton/DomainButton';
import closeSvg from '../../Assets/icons/close.svg';
import sadSmileSvg from '../../Assets/icons/sadSmile.svg';
import Spinner from 'Components/Common/Spinner/Spinner';
import s from './popup.module.scss';

interface IProps {
  onClose: () => void;
  open: boolean;
  isLoading?: boolean;
  onDonePressed: () => void;
}

const DeleteProductPopup = (props: IProps) => {
  return props.open ? (
    <div className={s.removeDomainWrapper}>
      <div className={s.modal}>
        <img
          className={s.closeIcon}
          src={closeSvg}
          alt="x"
          onClick={() => props.onClose()}
        />
        <img className={s.worldIcon} src={sadSmileSvg} alt="O" />
        <h1 className={s.headerText}>Are You Sure? </h1>
        <div className={s.buttonsBlock}>
          {props.isLoading ? (
            <div style={{ margin: '0 auto' }}>
              <Spinner size={50} />{' '}
            </div>
          ) : (
            <>
              <Button name="No, keep product" onClick={() => props.onClose()} />
              <Button
                name="Yes, remove"
                onClick={() => props.onDonePressed()}
                isBlackBtn={true}
              />
            </>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default DeleteProductPopup;
