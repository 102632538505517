import clsx from 'clsx';
import { ReactNode, useRef, useState } from 'react';
import { ReactComponent as MoveIcon } from 'Assets/icons/move.svg';
import { ReactComponent as MoreIcon } from 'Assets/icons/more.svg';
import Tooltip from 'UILib/Tooltip/Tooltip';

import styles from './SectionElement.module.scss';

interface IProps {
  indicator: ReactNode;
  text: string;
  selected: boolean;
  tooltipElements?: {
    title: string;
    icon?: ReactNode;
    onClick: () => void;
  }[];
  onClick: () => void;
}

const SectionElement = ({
  indicator,
  text,
  selected,
  tooltipElements,
  onClick,
}: IProps) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const buttonRef = useRef(null);

  return (
    <div
      className={clsx(styles.container, { [styles.selected]: selected })}
      onClick={onClick}
    >
      <div className={styles.indicator}>
        <div className={styles.defaultIcon}>{indicator}</div>
        <MoveIcon className={styles.hoverIcon} />
      </div>
      <div className={styles.textContainer}>{text}</div>
      <div className={styles.moreIcon}>
        <MoreIcon
          ref={buttonRef}
          onClick={() => {
            if (tooltipElements && tooltipElements.length > 0) {
              setIsTooltipVisible(!isTooltipVisible);
            }
          }}
        />
        <Tooltip
          open={isTooltipVisible}
          targetRef={buttonRef}
          position="right"
          onOutsideClick={() => setIsTooltipVisible(false)}
          content={
            <div className={styles.tooltipContainer}>
              {tooltipElements?.map((element, index) => (
                <div
                  key={index}
                  className={styles.tooltipElement}
                  onClick={() => {
                    element.onClick();
                    setIsTooltipVisible(false);
                  }}
                >
                  {element.icon} {element.title}
                </div>
              ))}
            </div>
          }
        />
      </div>
    </div>
  );
};

export default SectionElement;
