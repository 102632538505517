import React, { useState, useEffect, useRef } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { validateUser } from '../../utils/Utils';
import {
  addPageAction,
  addFunnelAction,
  duplicatePage,
  fetchFunnelsAction,
  setActiveProjectId,
  loadingProjectData,
  getActiveProjectData,
} from '../../store/projects/projectsActions';
import {
  queueEditName,
  queueArchiveProject,
} from '../../store/projects/projectsQueueActions';
import { createBrowserHistory } from 'history';
import SideMenu from 'Components/SideMenu/SideMenu';
import ProjectContent from '../../Components/projects/ProjectContent/ProjectContent';
import Templates from '../../Components/Templates/Templates';
import SettingsModal from '../../Components/projects/ProjectSettings/ProjectSettings';
import PageSettingsModal from '../../Components/projects/PageSettings/PageSettings';
import burgerSvg from '../../Assets/icons/burger.svg';
import Spinner from '../../Components/Common/Spinner/Spinner';
import StartVideoBlock from 'Components/Common/StartVideoBlock/StartVideoBlock';
import ProjectPlaceholders from 'Components/projects/Placeholders/ProjectPlaceholders/ProjectPlaceholders';
import FunnelsDahsboard from 'Components/Funnel/FunnelsDashboeard/FunnelsDashboard';

import s from './PageProjects.module.scss';

const PageProjects = (props) => {
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [showProjectSettings, setShowProjectSettings] = useState(false);
  const [showDomainSetup, setShowDomainSetup] = useState(false);
  const [activePage, setActivePage] = useState(null);
  const [showPageSettings, setShowPageSettings] = useState(false);
  const [firstFunnelLoad, setFirstFunnelLoad] = useState(true);
  const [redirectingToEditor, setRedirectingToEditor] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const refreshTimer = useRef(null);
  const showTimer = useRef(null);
  const history = createBrowserHistory();

  const path = history.location.pathname;
  const regex = /\/projects\/([^/]+)/;
  const match = path.match(regex);
  const pageId = match ? match[1] : '';

  useEffect(() => {
    if (pageId && !props.activeProjectId) {
      props.setActiveProjectId(pageId);
    }

    const mediaQuery = window.matchMedia('(max-width: 1000px)');
    if (mediaQuery.matches) {
      setIsSidebarOpen(false);
    } else {
      setIsSidebarOpen(true);
    }

    const handleResize = () => {
      if (window.innerWidth <= 1000) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      if (refreshTimer.current) {
        clearInterval(refreshTimer.current);
      }
      if (showTimer.current) {
        clearTimeout(showTimer.current);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [props.activeProjectId, pageId]);

  useEffect(() => {
    if (props.activeProjectId !== null) {
      props.getActiveProjectData(props.activeProjectId);
    } else {
      props.setLoadingProjectData(false);
    }
  }, [props.activeProjectId]);

  const handleAddProduct = (firstFunnelLoad) => {
    setShowTemplateModal(true);
    setFirstFunnelLoad(firstFunnelLoad);
  };

  const handleDomainSetupPopup = (isOpen) => {
    setShowDomainSetup(isOpen);
  };

  const handleCloseTemplatesModal = () => {
    setShowTemplateModal(false);
  };

  const handleCloseProjectModal = () => {
    setShowProjectSettings(false);
  };

  const openProjectSettings = () => {
    setShowProjectSettings(true);
  };

  const createProductPage = (template) => {
    const isAI = template['name'] === 'AI Page';
    props.addProduct(template, isAI);
  };

  const handleClosePageModal = () => {
    setActivePage(null);
    setShowPageSettings(false);
  };

  const handleOpenPageSettings = (page) => {
    setActivePage(page);
    setShowPageSettings(true);
  };

  const handleSpinnerOnRedirect = () => {
    setRedirectingToEditor(true);
  };

  const userAuthed = validateUser();
  if (!userAuthed) {
    return <Redirect to={{ pathname: '/console/login' }} />;
  }

  const project = props.activeProject;

  return (
    <>
      <div className={s.container}>
        {project && (
          <>
            <Templates
              projectsLoading={props.projectsLoading}
              handleSpinnerOnRedirect={handleSpinnerOnRedirect}
              project={project}
              firstFunnelLoad={firstFunnelLoad}
              show={showTemplateModal}
              onSelect={createProductPage}
              onClose={handleCloseTemplatesModal}
            />
            <SettingsModal
              show={showProjectSettings}
              onClose={handleCloseProjectModal}
              project={project}
            />
            <PageSettingsModal
              show={showPageSettings}
              onClose={handleClosePageModal}
              page={activePage}
              getPageData={() =>
                props.getActiveProjectData(props.activeProjectId)
              }
            />
          </>
        )}

        <div className={s.pageWrapper}>
          <StartVideoBlock
            src="https://s3.us-east-2.amazonaws.com/cdn.autofunnel.ai/Autofunnel+Walkthrough.mp4"
            name="pageConsoleVideoPlayed"
            redirectUrl="/console/new-funnel"
          />
          <div
            className={
              isSidebarOpen
                ? s.pageProjectsNavigation
                : `${s.pageProjectsNavigation} ${s.moveLeft}`
            }
          >
            <div
              className={s.sidebarToggleBtnBlock}
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            >
              <img src={burgerSvg} alt="burger svg" />
            </div>
            <SideMenu />
          </div>
          <div
            className={
              isSidebarOpen
                ? s.projectContent
                : `${s.projectContent} ${s.contentMoveLeft}`
            }
          >
            {!props.projectDataLoading ? (
              project && pageId ? (
                <ProjectContent
                  showDomainSetup={showDomainSetup}
                  onClose={() => handleDomainSetupPopup(false)}
                  project={project}
                  onNameEdited={props.setNewName}
                  onAddProductClicked={handleAddProduct}
                  onConfirmArchive={props.setArchive}
                  onDuplicate={props.duplicate}
                  onSettingsClicked={openProjectSettings}
                  onDomainSetupPopup={() => handleDomainSetupPopup(true)}
                  onOpenPageSettings={handleOpenPageSettings}
                />
              ) : (
                <FunnelsDahsboard />
              )
            ) : (
              <ProjectPlaceholders />
            )}
          </div>
        </div>
      </div>
      {redirectingToEditor && (
        <div className={s.mask}>
          <div className={s.loading}>
            <div className={s.spinner}>
              <Spinner size={110} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  templates: state.templates,
  projects: state.projects.items,
  activeProject: state.projects.active,
  projectsLoading: state.projects.loading,
  statistic: state.projects.statistic,
  activeProjectId: state.projects.activeProjectId,
  projectDataLoading: state.projects.loadingProjectData,
  projectsNameData: state.projects.projectsName,
});

const mapDispatchToProps = (dispatch) => ({
  getProjects: (id) => dispatch(fetchFunnelsAction(id)),
  setLoadingProjectData: (state) => dispatch(loadingProjectData(state)),
  getActiveProjectData: (id) => dispatch(getActiveProjectData(id)),
  setActiveProjectId: (id) => dispatch(setActiveProjectId(id)),
  setArchive: () => dispatch(queueArchiveProject()),
  setNewName: (name) => dispatch(queueEditName(name)),
  addProduct: (...data) => dispatch(addPageAction(...data)),
  createProject: () => dispatch(addFunnelAction()),
  duplicate: (id, funnelId) => dispatch(duplicatePage(id, funnelId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PageProjects));
