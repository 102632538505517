import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { notification } from 'antd';
import s from './PageSignup.module.scss';
import { AUTH_USER_TOKEN_KEY } from '../../Constants';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import InputV2 from '../../Components/Common/Inputs/InputV2/InputV2';
import InputPassword from '../../Components/Common/Inputs/InputPassword/InputPassword';
import PrimaryButton from '../../Components/Common/PrimaryButton/PrimaryButton';
import InputSubdomain from '../../Components/Common/Inputs/InputSubdomain/InputSubdomain';
import LoadingSpinner from '../../Components/Common/LoadingSpinner/LoadingSpinner';
import QUERIES from '../../graphql/queries';
import { graphQlCall } from '../../graphql/utils';
import { username } from 'utils/Utils';
import Logo from 'Components/Logo/Logo';
import { ReactComponent as DoneSvg } from '../../Assets/icons/done-icon.svg';
import { ReactComponent as RemoveSvg } from '../../Assets/icons/remove-icon.svg';

interface IProps {
  email?: string;
}

interface PasswordCriteriaProps {
  isValid: boolean;
  children: React.ReactNode;
}

const PageSignup = (props: IProps) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  interface IValues {
    firstName: string;
    lastName: string;
    email: string;
    subdomain: string;
    password: string;
    confirmPassword: string;
  }

  interface ICreateUser {
    email: string;
    name: string;
    subdomain: string;
  }

  const createUserCall = async (data: ICreateUser) => {
    return await graphQlCall({
      queryTemplateObject: QUERIES.GET_OR_CREATE_USER_MUTATION,
      values: data,
    });
  };

  const onFinish = async (values: IValues) => {
    try {
      let { firstName, lastName, email, subdomain, password } = values;

      setLoading(true);
      const name = `${firstName} ${lastName}`;
      const storedCognitoUser = Document.prototype.cognitoUser;
      if (props.email) {
        if (storedCognitoUser) {
          const user = await createUserCall({
            email,
            name,
            subdomain,
          });

          const cognitoUser = await Auth.completeNewPassword(
            storedCognitoUser,
            password,
            {
              name: name,
            }
          );

          await Auth.currentAuthenticatedUser().then((user) => {
            console.log(user);
          });

          localStorage.setItem(
            AUTH_USER_TOKEN_KEY,
            cognitoUser.signInUserSession.accessToken.jwtToken
          );
          localStorage.setItem('USER_ID', user._id);
          localStorage.setItem('USER_ROLE', user.role);
          localStorage.setItem('USER_EMAIL', user.email);

          notification.success({
            message: 'Success!',
            description: 'Your account was updated',
            placement: 'topRight',
            duration: 2.5,
            onClose: () => {
              history.replace({ pathname: '/console/projects' });
            },
          });
        } else {
          notification.error({
            message: 'Error',
            description: 'Something went wrong',
            placement: 'topRight',
          });

          setLoading(false);
        }
      }

      await Auth.signIn({
        username: email,
        password,
      });

      notification.success({
        message: 'Success!',
        description: 'Your account was created',
        placement: 'topRight',
        duration: 2.5,
        onClose: () => {
          history.replace({ pathname: '/console/settings' });
        },
      });
    } catch (err: any) {
      handleError(err);
    }
  };

  const handleError = (err: any) => {
    notification.error({
      message: 'Error',
      description: err.message ? err.message : err.toString(),
      placement: 'topRight',
    });
    console.error(err);
    setLoading(false);
  };

  const PasswordCriteria = ({ isValid, children }: PasswordCriteriaProps) => (
    <div>
      <span className={s.iconForValidate}>
        {isValid ? <DoneSvg /> : <RemoveSvg fill="#ff4f4f" />}
      </span>
      <span>{children}</span>
    </div>
  );

  return (
    <div className={s.container}>
      <div className={s.logoContainer}>
        <Logo smallSize={true} />
      </div>
      <div className={s.formContainer}>
        <div className={s.innerContainer}>
          <div className={s.header}>
            <h3>Congratulations!</h3>
            <p>Finish settings up your account</p>
          </div>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: props.email ?? '',
              subdomain: '',
              password: '',
              confirmPassword: '',
            }}
            validationSchema={Yup.object({
              firstName: Yup.string()
                .required('First name is required')
                .matches(/^[aA-zZ\s]+$/, 'Please, enter only alphabets'),
              lastName: Yup.string()
                .required('Last name is required')
                .matches(/^[aA-zZ\s]+$/, 'Please, enter only alphabets'),
              email: Yup.string()
                .required('Email is required')
                .email('Please, enter valid email'),
              // subdomain: Yup.string().required('Subdomain is required'),
              password: Yup.string()
                .required('Password is required')
                .min(10, 'Please, enter minimum 10 characters')
                .matches(/(?=.*\d)/, 'Password must include a number')
                .matches(
                  /(?=.*[A-Z])/,
                  'Password must include a capital letter'
                )
                .matches(
                  /(?=.*[!@#$%^&*])/,
                  'Password must include a special character'
                ),
            })}
            onSubmit={(values, { setSubmitting }) => {
              onFinish(values);
              setSubmitting(false);
            }}
          >
            {({ errors, touched, values }) => (
              <Form className={s.form}>
                <div className={s.userInfo}>
                  <div className={s.field1}>
                    <InputV2
                      width={155}
                      placeholder="First Name"
                      name="firstName"
                      type="text"
                    />
                  </div>
                  <div className={s.field2}>
                    <InputV2
                      width={155}
                      placeholder="Last Name"
                      name="lastName"
                      type="text"
                    />
                  </div>
                </div>
                <div className={s.field3}>
                  <InputV2
                    width={330}
                    placeholder="Email"
                    name="email"
                    type="text"
                  />
                </div>
                <div className={s.field4}>
                  <InputSubdomain
                    width={330}
                    placeholder="Workspace Name"
                    name="subdomain"
                  />
                </div>
                <div className={s.field5}>
                  <InputPassword
                    width={330}
                    placeholder="Password"
                    name="password"
                  />
                </div>
                <div className={s.validatePasswordContainer}>
                  <p>New password must include at least:</p>
                  <div className={s.valiedateOptions}>
                    <PasswordCriteria
                      isValid={Boolean(
                        values.password && values.password.length >= 10
                      )}
                    >
                      10 Characters
                    </PasswordCriteria>
                    <PasswordCriteria
                      isValid={Boolean(
                        values.password && /[!@#$%^&*]/.test(values.password)
                      )}
                    >
                      Special Character
                    </PasswordCriteria>
                    <PasswordCriteria
                      isValid={Boolean(
                        values.password && /\d/.test(values.password)
                      )}
                    >
                      Number
                    </PasswordCriteria>
                    <PasswordCriteria
                      isValid={Boolean(
                        values.password && /[A-Z]/.test(values.password)
                      )}
                    >
                      Capital Letter
                    </PasswordCriteria>
                  </div>
                </div>
                <div className={s.submitButton}>
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <PrimaryButton
                      width={330}
                      borderRadius={50}
                      type="submit"
                      isBlackBtn={true}
                    >
                      Sign Up
                    </PrimaryButton>
                  )}
                </div>
              </Form>
            )}
          </Formik>
          <div className={s.footer}>
            <p className={s.logInBlock}>
              Already have an account?{' '}
              <span className={s.link}>
                {' '}
                <Link to="/console/login">Log In</Link>
              </span>
            </p>
            <div className={s.additionalInfo}>
              <p>By clicking "Sign Up" you agree to AutoFunnel’s</p>
              <p>
                <span>Privacy Policy</span> and <span> Terms of Service</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageSignup;
