import React, { useEffect, useState, useRef } from 'react';
import AiTemplateBuilder from 'Components/Admin/AdminAiTemplates/AiTemplateGBuilder/AiTemplateBuilder';
import s from './AiTemplates.module.scss';
import sCommon from '../Common/Common.module.scss';
import plusIcon from '../Assets/plus.svg';
import AiTemplateCard from './AiTemplateCard';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';
import { AiTemplate } from '../aiTemplateTypes';
import { api } from 'utils/Utils';
import { PAGECRAFT_API_URL } from 'Constants';

interface IAiTemplatesShort {
  _id: string;
  name: string;
  settings: {
    chaptGptModel: string;
    imageServer: string;
  };
  thumbnail: string;
}

const AiTemplates = () => {
  const [aiTemplates, setAiTemplates] = useState<IAiTemplatesShort[]>([]);
  const [aiTemplate, setAiTemplate] = useState<AiTemplate | null>(null);
  const [editingTemplateId, setEditingTemplateId] = useState<string | null>(
    null
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const fetchAiTemplates = async () => {
    const newAiTemplates = await graphQlCall({
      queryTemplateObject: QUERIES.GET_ALL_AI_TEMPLATES,
      headerType: 'USER-AUTH',
    });

    setAiTemplates(newAiTemplates);
  };

  useEffect(() => {
    fetchAiTemplates();
  }, []);

  const handleCreateNewTemplate = async () => {
    const newTemplate = await graphQlCall({
      queryTemplateObject: QUERIES.CREATE_AI_TEMPLATE,
      headerType: 'USER-AUTH',
      values: {
        data: JSON.stringify({
          chaptGptModel: 'gpt3.5-turbo',
          imageServer: 'mj',
        }),
      },
    });

    const newAiTemplates = [...aiTemplates, newTemplate];
    setAiTemplates(newAiTemplates);
  };

  const handleEditAiTemplateName = async (newName: string, id: string) => {
    await graphQlCall({
      queryTemplateObject: QUERIES.UPDATE_AI_TEMPLATE,
      headerType: 'USER-AUTH',
      values: {
        id,
        data: JSON.stringify({ name: newName }),
      },
    });
  };

  const handleDeleteAiTemplate = async (id: string) => {
    const newAiTemplates = aiTemplates.filter((current) => current._id !== id);
    setAiTemplates(newAiTemplates);
    await graphQlCall({
      queryTemplateObject: QUERIES.DELETE_AI_TEMPLATE,
      headerType: 'USER-AUTH',
      values: {
        id,
      },
    });
  };

  const fetchTemplateAi = async (id: string) => {
    return await graphQlCall({
      queryTemplateObject: QUERIES.GET_AI_TEMPLATE,
      headerType: 'USER-AUTH',
      values: { id },
    });
  };

  const handleOpenTemplate = async (id: string) => {
    const newTemplateAi = await fetchTemplateAi(id);
    setAiTemplate(newTemplateAi);
  };

  const handleSave = async (newAiTemplate: AiTemplate) => {
    const id = newAiTemplate._id;
    const updatedData: any = { ...newAiTemplate };
    delete updatedData._id;
    await graphQlCall({
      queryTemplateObject: QUERIES.UPDATE_AI_TEMPLATE,
      headerType: 'USER-AUTH',
      values: {
        id,
        data: JSON.stringify(updatedData),
      },
    });
    const newAiTemplates = aiTemplates.map((aiTemplate) => {
      if (aiTemplate._id === id) {
        aiTemplate.settings = newAiTemplate.settings;
      }
      return aiTemplate;
    });

    setAiTemplates(newAiTemplates);
  };

  const handlePreview = (templateId: string) => {
    window.open(`/edit/ai-template/${templateId}`, '_self');
  };

  const handleStartUpload = (templateId: string) => {
    console.log('handleStartUpload', templateId);
    if (inputRef?.current) {
      setEditingTemplateId(templateId);
      inputRef.current.click();
    }
  };

  const handleFileUpload = async () => {
    if (!inputRef?.current) {
      return;
    }
    const files = inputRef.current.files;
    if (!files?.length) {
      return;
    }
    let file = files[0];
    console.log('file', file);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (res) => {
      const content = reader.result;
      const imageSrc = String(content);
      const newTemplates = aiTemplates.map((template) => {
        if (template._id === editingTemplateId) {
          template.thumbnail = imageSrc;
        }
        return template;
      });
      console.log('newTemplates', newTemplates);
      setAiTemplates(newTemplates);
    };

    const request = new FormData();
    request.append('file', file);
    await api(
      `${PAGECRAFT_API_URL}/ai-template/upload-thumbnail/${editingTemplateId}`,
      'POST',
      request
    );
  };

  return (
    <div>
      {!aiTemplate && (
        <section className={s.templatesSelector}>
          <h1 className={s.header}>
            <span>AI Templates</span>
            <button
              className={sCommon.addButton}
              onClick={() => handleCreateNewTemplate()}
            >
              <img src={plusIcon} alt="+" />
              Add
            </button>
          </h1>
          <div className={s.tableHeader}>
            <span>Name</span>
            <span>Server</span>
            <span>Model</span>
          </div>
          <div>
            {aiTemplates.map((aiTemplate) => (
              <AiTemplateCard
                key={aiTemplate._id}
                name={aiTemplate.name}
                imageServer={aiTemplate.settings.imageServer}
                model={aiTemplate.settings.chaptGptModel}
                onDelete={() => handleDeleteAiTemplate(aiTemplate._id)}
                onChangeName={(newName) =>
                  handleEditAiTemplateName(newName, aiTemplate._id)
                }
                onOpen={() => handleOpenTemplate(aiTemplate._id)}
                onPreview={() => handlePreview(aiTemplate._id)}
                image={aiTemplate.thumbnail}
                onStartTHumbnailUpload={() => handleStartUpload(aiTemplate._id)}
              />
            ))}
          </div>
        </section>
      )}
      {aiTemplate && (
        <AiTemplateBuilder
          aiTemplate={aiTemplate}
          onClose={() => setAiTemplate(null)}
          onSave={handleSave}
        />
      )}
      <input
        type="file"
        style={{ display: 'none' }}
        accept="image/*"
        ref={inputRef}
        onChange={() => handleFileUpload()}
      />
    </div>
  );
};

export default AiTemplates;
