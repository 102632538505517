import React, { useState } from 'react';
import styled from 'styled-components';

import AdminNavigation from './AdminNavigation';
import AdminTemplates from './AdminTemplates/AdminTemplates';
import AdminSectionMenu from './AdminSectionMenu/SectionsMenu';
import AdminBookRequests from './AdminBookRequest/AdminBookRequests';
import AiTemplates from 'Components/Admin/AdminAiTemplates/AiTemplates/AiTemplates';
import AdminStatistic from './AdminStatistic/AdminStatistic';

const StyledAdminSection = styled.section`
  display: grid;
  grid-template-columns: max-content auto;
`;

const AdminSection = () => {
  const [tab, setTab] = useState<string>('');

  const handleChangeTab = (newTab: string) => {
    setTab(newTab);
  };

  const getContent = () => {
    let panel = null;

    switch (tab) {
      case 'templates':
        panel = <AdminTemplates />;
        break;
      case 'sections-menu':
        panel = <AdminSectionMenu />;
        break;
      case 'book-requests':
        panel = <AdminBookRequests />;
        break;
      case 'ai-templates':
        panel = <AiTemplates />;
        break;
      case 'statistic':
        panel = <AdminStatistic />;
        break;
      default:
        break;
    }

    return panel;
  };

  return (
    <StyledAdminSection>
      <AdminNavigation onChangeTab={(newTab) => handleChangeTab(newTab)} />
      <div>{getContent()}</div>
    </StyledAdminSection>
  );
};

export default AdminSection;
