import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

interface IStyledProps {
  size?: string;
  autohide?: boolean;
}

const StyledEdit = styled.div<IStyledProps>`
  font-size: ${(props) => props.size};
  padding: 0px 5px 0px 5px;
  display: ${(props) => (props.autohide ? 'inline-block' : 'flex')};
  cursor: text;
  border: 1px transparent solid;

  &:hover {
    border: 1px #777 solid;
  }

  .text {
    color: #000;
    font-size: ${(props) => props.size};
  }
`;

const StyleEditing = styled.div`
  color: black;
`;

interface IProps {
  text: string;
  onClick: () => void;
  size?: string;
  onEdit: (text: string) => void;
  opened?: boolean;
}

const EditableLandingText = (props: IProps) => {
  const [text, setText] = useState(props.text);
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const onEditClicked = () => {
    props.onClick();
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 500);

    setEditing(true);
  };

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  const onTextBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    props.onEdit(text);
    setEditing(false);
  };

  const onTextKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      props.onEdit(text);
      setEditing(false);
    }
  };

  let out;

  useEffect(() => {
    setText(props.text);
  }, [props.text]);

  let opened = false;
  if (props.opened === undefined) {
    opened = editing;
  } else {
    opened = props.opened;
  }

  if (!opened) {
    out = (
      <StyledEdit className="text-editor" onClick={() => onEditClicked()}>
        {<span className="text"> {text || 'None'}</span>}
      </StyledEdit>
    );
  } else {
    out = (
      <StyleEditing>
        <input
          ref={inputRef}
          style={{
            fontSize: props.size,
            padding: '0px 5px 0px 5px',
            border: '1px #777 solid',
          }}
          type="text"
          onChange={(e) => onTextChange(e)}
          onBlur={(e) => onTextBlur(e)}
          onKeyPress={(e) => onTextKeyPress(e)}
          value={text}
        />
      </StyleEditing>
    );
  }

  return <div>{out}</div>;
};

export default EditableLandingText;
