import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../Styled/constants';

const StyledSwitch = styled.span`
  display: 'inline-block';

  margin: 0 2px;
  overflow: hidden;

  color: #000;

  > label {
    cursor: pointer;
    display: inline-block;
    height: 26px;

    > input {
      display: none;
    }

    > input + div {
      border: 1px ${colors.primary} solid;
      border-radius: 5px;
    }

    > input:checked + div {
      background: ${colors.primary};
      border-color: ${colors.primary};

      > span {
        color: #fff;
      }
    }

    > div {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
    }
  }
`;

interface IProps {
  onlyRegular: boolean;
  onChange: (checked: boolean) => void;
}

const ExitPopupSwitch = (props: IProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e.target.checked);
  };

  return (
    <StyledSwitch>
      <label>
        <input
          type="checkbox"
          checked={props.onlyRegular}
          onChange={(e) => handleChange(e)}
        />
        <div>
          <span>{props.onlyRegular ? 'Regular only' : 'Regular & Popup'}</span>
        </div>
      </label>
    </StyledSwitch>
  );
};

export default ExitPopupSwitch;
