export const GET_CURRENT_USER_QUERY = {
  operationName: 'getCurrentUserDetails',
  query: `query getCurrentUserDetails {
      getCurrentUserDetails {
        _id
        name
        owner
        email
        scopes
        role
        subdomain
        stripePublicKey
        stripeSecretKey
        stripeClientId
        active
        customData
        agencyEmail
        aiCredits
        agencyName
        thumbnail
        paymentMethod {
          exp_month
          exp_year
          last4
        }
        plans
      }
    }`,
};
