import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { RootState } from 'store/rootStore';
import {
  fetchUserProductsAction,
  addUserProductAction,
  deleteProductAction,
  productPopupAction,
} from 'store/products/productActions';
import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';
import { IUserProduct } from 'types';
import SearchInput from 'UILib/SearchInput/SearchInput';
import Button from 'UILib/Button/Button';
import Loader from 'UILib/Loader/Loader';
import ProductCard from './ProductCard/ProductCard';
import DeleteProductPopup from './DeleteProductPopup';

import s from './Product.module.scss';

interface IProps {
  products: IUserProduct[];
  loading: boolean;
  fetchProducts: () => void;
  triggerPopup: (payload: {
    open?: boolean;
    initialState?: IUserProduct;
  }) => void;
  addUserProduct: (product: IUserProduct) => void;
  deleteProduct: (id: string) => void;
}

const Products = (props: IProps) => {
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [productSearch, setProductSearch] = useState('');
  const [sortingBy, setSortingBy] = useState<'Name' | 'Price'>('Name');
  const [filteredProducts, setFilteredProducts] = useState(props.products);
  const [selectedProduct, setSelectedProduct] = useState<IUserProduct>();

  useEffect(() => {
    props.fetchProducts();
  }, []);

  useEffect(() => {
    let newProducts = [...props.products];

    if (productSearch) {
      newProducts = newProducts.filter(
        (product) =>
          product.name.includes(productSearch) ||
          product.description.includes(productSearch)
      );
    }
    if (sortingBy) {
      newProducts = newProducts.sort((product1, product2) => {
        if (sortingBy === 'Name')
          return product1.name.localeCompare(product2.name);
        if (sortingBy === 'Price')
          return (product2.price ?? 0) - (product1.price ?? 0);

        return 0;
      });
    }

    setFilteredProducts(newProducts);
  }, [productSearch, sortingBy, props.products]);

  const handleDuplicate = (product: IUserProduct) => {
    const { _id, ...rest } = product;
    props.addUserProduct(rest);
  };

  const handleCloseDeletePopup = () => {
    setOpenDeletePopup(false);
    setSelectedProduct(undefined);
  };

  const processDelete = () => {
    const id = selectedProduct?._id;
    if (!id) {
      return;
    }
    props.deleteProduct(id);
    handleCloseDeletePopup();
  };

  return (
    <>
      <div className={s.header}>
        <h2>Your Products</h2>
        <Button
          appearance="highlighted"
          width={220}
          height={40}
          onClick={() => props.triggerPopup({ open: true })}
          postfixIcon={<PlusIcon width={13} height={13} />}
        >
          New Product
        </Button>
      </div>
      <div className={s.filters}>
        <div className={s.searchBlock}>
          <SearchInput
            border="stroke"
            value={productSearch}
            onChange={setProductSearch}
          />
          {`${filteredProducts.length} ${
            filteredProducts.length === 1 ? 'Product' : 'Products'
          }`}
        </div>
        <div className={s.sortBlock}>
          Sort by:
          <Dropdown
            placement="bottomRight"
            menu={{
              items: [
                {
                  key: 'name',
                  label: (
                    <div onClick={() => setSortingBy('Name')}>Product Name</div>
                  ),
                },
                {
                  key: 'price',
                  label: (
                    <div onClick={() => setSortingBy('Price')}>
                      Product Price
                    </div>
                  ),
                },
              ],
            }}
          >
            <span
              className={s.selectedOption}
              onClick={(e) => e.preventDefault()}
            >
              <Space>
                {sortingBy}
                <DownOutlined color="#a0a0a0" />
              </Space>
            </span>
          </Dropdown>
        </div>
      </div>
      {props.loading ? (
        <div className={s.loaderWrapper}>
          <Loader />
        </div>
      ) : (
        <div className={s.productsList}>
          {filteredProducts.map((product) => (
            <ProductCard
              product={product}
              onClick={() => {
                props.triggerPopup({ open: true, initialState: product });
              }}
              onDelete={() => {
                setOpenDeletePopup(true);
                setSelectedProduct(product);
              }}
              onDuplicate={() => {
                handleDuplicate(product);
              }}
            />
          ))}
        </div>
      )}
      <DeleteProductPopup
        onClose={handleCloseDeletePopup}
        open={openDeletePopup}
        onDonePressed={processDelete}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  products: state.products.items,
  loading: state.products.loading,
});

const mapDispatchToProps = {
  fetchProducts: () => fetchUserProductsAction(),
  triggerPopup: (payload: { open?: boolean; initialState?: IUserProduct }) =>
    productPopupAction(payload),
  addUserProduct: (product: IUserProduct) => addUserProductAction(product),
  deleteProduct: (id: string) => deleteProductAction(id),
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
