import { connect } from 'react-redux';
import { ReactComponent as GlobeIcon } from 'Assets/icons/domainIcon.svg';
import { domainAddCNAMEPopup } from 'store/domains/domainsActions';
import { RootState } from 'store/rootStore';
import Button from 'UILib/Button/Button';
import Popup from 'UILib/Popup/Popup';

import styles from './AddCNAMEPopup.module.scss';

interface IProps {
  openDomainAddCNAMEPopup: boolean;
  toggleAddCNAMEPopup: (payload: boolean) => void;
}

const AddCNAMEPopup = ({
  openDomainAddCNAMEPopup,
  toggleAddCNAMEPopup,
}: IProps) => {
  if (!openDomainAddCNAMEPopup) return null;

  const handleOpenArticle = () => {
    window.open(
      'https://autofunnel.freshdesk.com/support/solutions/articles/202000060373',
      '_blank'
    );
  };

  return (
    <Popup
      onClose={() => toggleAddCNAMEPopup(false)}
      wrapperClassName={styles.container}
    >
      <div className={styles.content}>
        <GlobeIcon width={50} height={50} fill="black" />
        <div className={styles.title}>Add CNAME</div>
        <div className={styles.subtitle}>
          In order for your domain integration to work, you will need to log
          into wherever you purchased your domain and add a CNAME record that
          points ftw.com at <b>live.autofunnel.ai</b>. If you are not sure on
          how to do this, you can review our articles below for instructions on
          several popular domain registrars. You can also get this information
          from your domain registrar directly.
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button height={40} appearance="stroke" onClick={handleOpenArticle}>
          How to Add?
        </Button>
        <Button
          height={40}
          appearance="highlighted"
          onClick={() => toggleAddCNAMEPopup(false)}
        >
          I've done this
        </Button>
      </div>
    </Popup>
  );
};

const mapStateToProps = (state: RootState) => ({
  openDomainAddCNAMEPopup: state.domains.openDomainAddCNAMEPopup,
});

const mapDispatchToProps = {
  toggleAddCNAMEPopup: (payload: boolean) => domainAddCNAMEPopup(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCNAMEPopup);
