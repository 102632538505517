import React, { useState } from 'react';
import { AutoComplete } from 'antd';
import StyledButton from '../../../Styled/PrimaryStyledButton';

import styled from 'styled-components';
import { IFunnel } from 'types';

const StyledAddTemplate = styled.div`
  display: grid;
  grid-template-columns: 150px 200px 100px 100px;
  align-items: center;

  button {
    margin-left: 20px;
  }
`;

interface IProps {
  funnels: IFunnel[];
  onSelect: (selectedId: string, type: string) => void;
}

const AddTemplate = (props: IProps) => {
  const [options, setOptions] = useState<IOption[]>([]);
  const [selectedId, setSelectedId] = useState<string | undefined>();
  const [value, setValue] = useState('');
  const [type, setType] = useState('template');

  interface IOption {
    value: string;
  }

  const onSearch = (searchText: string) => {
    const filtered = props.funnels.filter(
      (funnel) => funnel._id.indexOf(searchText) === 0
    );
    const newOptions = filtered.map((funnel) => ({
      value: funnel._id,
    }));
    setOptions(newOptions);
  };

  const onSelect = (id: string) => {
    setSelectedId(id);
  };

  const onSubmit = () => {
    if (!value) {
      // return;
    }
    props.onSelect(value, type);
    setSelectedId(undefined);
    setOptions([]);
    setValue('');
  };

  const onChange = (data: string) => {
    setValue(data);
  };

  return (
    <StyledAddTemplate>
      <h2>Add template</h2>
      <AutoComplete
        value={value}
        options={options}
        style={{ width: 200 }}
        onSelect={onSelect}
        onSearch={onSearch}
        onChange={onChange}
        placeholder="funnel id"
      />
      <select onChange={(e) => setType(e.target.value)}>
        <option value="template">template</option>
        <option value="aipage">ai page</option>
      </select>
      <StyledButton onClick={() => onSubmit()}>ADD</StyledButton>
    </StyledAddTemplate>
  );
};

export default AddTemplate;
