import React, { useState, useEffect } from 'react';
import { RootState } from '../../store/rootStore';
import { connect } from 'react-redux';
import s from './Billing.module.scss';
import {
  fetchUserSubscriptionPlanDataAction,
  userDeleteAction,
} from '../../store/user/userActions';
import { IUserSubscriptionPlanData } from 'types';
import { ReactComponent as CoinSvg } from '../../Assets/icons/coin.svg';
import { ReactComponent as InfoSvg } from '../../Assets/icons/info.svg';
import { ReactComponent as CardSvg } from '../../Assets/icons/card.svg';
import ChoosePlan from 'Components/Common/ChoosePlan/ChoosePlan';
import UpdatePlanPopup from 'Components/projects/popups/UpdatePlanPopup';
import UpdatePaymentMethodPopup from 'Components/projects/popups/UpdatePaymentMethodPopup';
import { IUserDetails } from 'types';
import { fetchUserDetails } from '../../store/user/userActions';

interface IProps {
  fetchUserDetails: () => void;
  userDetails: IUserDetails;
  // userSubscriptionPlanData: IUserSubscriptionPlanData;
  // userDeleteAction: () => void;
  // fetchUserSubscriptionPlanDataAction: () => void;
}

const Billing = (props: IProps) => {
  const [updatePaymentOpenPopup, setUpdatePaymentOpenPopup] = useState(false);
  const [updatePlanOpenPopup, setUpdatePlanOpenPopup] = useState(false);

  // //mocked data
  // const currentCredits = 120;
  // const buyCredits = 200;
  // //

  useEffect(() => {
    props.fetchUserDetails();
  }, []);

  const paymentMethodString = () => {
    if (!props.userDetails.paymentMethod) {
      return '';
    } else {
      return `xxxx${props.userDetails.paymentMethod.last4} 
              expiration:${props.userDetails.paymentMethod.exp_month}/${props.userDetails.paymentMethod.exp_year}`;
    }
  };
  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <h1>Billing</h1>
        {/* <div className={s.balance}>
          Your Balance:<span className={currentCredits > 0 ? s.creditsCount : s.zeroCreditsCount}>{currentCredits}</span>
          <CoinSvg />
        </div>
        {currentCredits ? (
          <div className={s.messageAboutEnoughtBalance}>
            <span>Not enough AI credits?</span>
            <span>Choose the higher one and upgrade it below:</span>
          </div>
        ) : (
          <div className={s.messageAboutZeroBalance}>
            <span>Oh, your balance is exhausted!</span>
            <span>Don’t stop, It’s time to upgrade your balance and continue rocking!</span>
          </div>
        )}
        */}
        <div className={s.line}></div>
        {props.userDetails.plans?.length != 0 && (
          <div className={s.planName}>
            Your Plan: <b>{props.userDetails.plans}</b>
          </div>
        )}
        {/* <div className={s.creditsPerPlan}>
          <CoinSvg /> <span>Credits: {buyCredits}</span><span className={s.creditsPerPlanTime}> /mo</span><InfoSvg className={s.info}/>
        </div>  */}
        <div className={s.cardContain}>
          <CardSvg /> <span>Payment Method: {paymentMethodString()}</span>
        </div>
        <div className={s.buttonContainer}>
          {props.userDetails.scopes?.includes('BOOKLE_GENERAL_ACCESS') && (
            <button
              type="button"
              onClick={() => {
                setUpdatePlanOpenPopup(true);
              }}
            >
              Change Plan
            </button>
          )}
          <button
            type="button"
            onClick={() => {
              setUpdatePaymentOpenPopup(true);
            }}
          >
            Update Payment Method
          </button>
        </div>
        {/* <ChoosePlan showModal={showModal} setShowModal={setShowModal} /> */}
      </div>
      <UpdatePlanPopup
        open={updatePlanOpenPopup}
        onClose={() => setUpdatePlanOpenPopup(false)}
      />
      <UpdatePaymentMethodPopup
        open={updatePaymentOpenPopup}
        onClose={() => {
          setUpdatePaymentOpenPopup(false);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  userDetails: state.user.details,
});

const mapDispatchToProps = {
  fetchUserDetails: () => fetchUserDetails(),
};

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
