import { useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as VisibleIcon } from 'Assets/icons/visible.svg';
import { ReactComponent as NotVisibleIcon } from 'Assets/icons/not-visible.svg';

import styles from './Input.module.scss';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  theme?: 'light' | 'dark';
  border?: 'stroke' | 'one-line' | 'one-line-hidden';
  prefixIcon?: React.ReactNode;
  error?: string;
  hideArrows?: boolean;
}

const Input = ({
  theme = 'light',
  border = 'one-line',
  prefixIcon,
  className,
  hideArrows,
  error,
  ...rest
}: IProps) => {
  const [showContent, setShowContent] = useState<boolean>(false);

  const handleShow = () => {
    setShowContent(!showContent);
  };

  return (
    <div
      className={clsx(
        styles.container,
        styles[theme],
        {
          [styles[`border-${border}`]]: border,
        },
        className
      )}
    >
      {!!prefixIcon && <div className={styles.prefixIcon}>{prefixIcon}</div>}
      <input
        {...rest}
        className={clsx(styles.input, {
          [styles.addPrefixIcon]: !!prefixIcon,
          [styles.addPostfixIcon]: rest.type === 'password',
          [styles.hideArrows]: hideArrows,
          [styles.error]: error,
        })}
        type={showContent ? 'text' : rest.type}
      />
      {rest.type === 'password' &&
        (showContent ? (
          <VisibleIcon onClick={handleShow} className={styles.postfixIcon} />
        ) : (
          <NotVisibleIcon onClick={handleShow} className={styles.postfixIcon} />
        ))}
      {error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  );
};

export default Input;
