export const GET_EPISODE_INFO = {
  operationName: 'getEpisodeInfo',
  query: `query getEpisodeInfo ($id: String!) {
    getEpisodeInfo(id: $id) {
      episode {
        _id
        name
        tone
        voice
        prompt
        text
        audioUrl
        coverData
        image
        sections {
          _id
          title
          text
          notes
          state
        }
        music {
          id
          introUrl
          outroUrl
          transitionUrl
          introStart
          transitionStart
          transitionEnd
         outroEnd
        }
        createdAt
        updatedAt
      }
      podcastInfo {
       id
        name
        hostName
      }
      episodeNumber
    }
  }`,
};
