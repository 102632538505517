import React, { useState } from 'react';
import Button from './Button';
import PersonIcon from '../icons/person.svg';
import Input from './Input';
import Popup from 'UILib/Popup/Popup';
import s from './popups.module.scss';

interface IProps {
  confirmTransfer: (email: string) => void;
  onClose: () => void;
}

const TransferPopup = (props: IProps) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const handleConfirm = () => {
    const regex = /^(\w|\d)+(\.(\w|\d)+)?@(\w|\d)+(\.(\w|\d)+)+$/gi;
    if (!regex.test(email)) {
      setEmailError(true);
      return;
    }
    props.confirmTransfer(email);
    setEmail('');
    setEmailError(false);
  };

  return (
    <Popup onClose={props.onClose}>
      <div className={s.modalBody}>
        <img src={PersonIcon} className={s.personIcon} />
        <h2 className={s.popupHeader}>Transfer Account</h2>
        <p className={s.popupText}>
          By transferring an agency profile to another AutoFunnel user, you'll
          lose access to all of the content in this profile, including books,
          funnels, and pages. If you need access back, please contact the
          account owner.
        </p>
        <Input
          type="email"
          onChange={setEmail}
          value={email}
          error={emailError}
        />
        <Button name="Transfer" onClick={() => handleConfirm()} />
      </div>
    </Popup>
  );
};

export default TransferPopup;
