import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ITopic } from 'types';
import { truncateString } from 'utils/helpers';
import { ReactComponent as Topic } from 'Assets/icons/copyCraft/topic.svg';
import { ReactComponent as Mail } from 'Assets/icons/copyCraft/mail.svg';
import { ReactComponent as Brain } from 'Assets/icons/copyCraft/brain.svg';
import { ReactComponent as DeleteIcon } from '../../Accounts/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../Accounts/icons/edit.svg';
import OptionMenu from 'Components/OptionMenu/OptionMenu';

import styles from './CopyCraftItem.module.scss';

interface CopyCraftItemProps {
  item: ITopic;
  onDelete: (id: string) => void;
  onEdit: (item: ITopic) => void;
}

enum MenuItems {
  DELETE,
  EDIT,
}

const menuItems = [
  {
    label: 'Delete',
    key: MenuItems.DELETE,
    icon: <DeleteIcon />,
  },
  {
    label: 'Edit',
    key: MenuItems.EDIT,
    icon: <EditIcon />,
  },
];

const itemTheme = {
  brain: {
    color: '#e09531',
    backgroundClor: '#f7f0e6',
    icon: <Brain />,
  },
  email: {
    color: '#4792d6',
    backgroundClor: '#e8f0f6',
    icon: <Mail />,
  },
  sales: {
    color: '#25bb73',
    backgroundClor: '#e5f4ed',
    icon: <Topic />,
  },
};

const CopyCraftItem = ({ item, onDelete, onEdit }: CopyCraftItemProps) => {
  const history = useHistory();
  const [theme, setTheme] = useState<{
    color?: string;
    backgroundClor?: string;
    icon?: JSX.Element;
  }>();

  const handleMenuClick = (e: any) => {
    e.domEvent.stopPropagation();
    switch (+e.key) {
      case MenuItems.DELETE: {
        onDelete(item._id);
        break;
      }
      case MenuItems.EDIT: {
        onEdit(item);
        break;
      }
    }
  };

  useEffect(() => {
    switch (item.type) {
      case 'Email Copy': {
        setTheme(itemTheme.email);
        break;
      }
      case 'Brain': {
        setTheme(itemTheme.brain);
        break;
      }
      case 'Sales Copy': {
        setTheme(itemTheme.sales);
        break;
      }
      default:
        setTheme(itemTheme.sales);
        break;
    }
  }, [item]);

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  const handleClick = () => {
    history.push(`/console/copy-craft/${item._id}`);
  };

  return (
    <div className={styles.container} onClick={handleClick}>
      <div className={styles.content}>
        <div
          className={styles.icon}
          style={{ backgroundColor: theme?.backgroundClor }}
        >
          {theme?.icon}
        </div>
        <div className={styles.topicInfo}>
          <div className={styles.line}>
            <div className={styles.name}>{item.name}</div>
            <div
              className={styles.type}
              style={{
                backgroundColor: theme?.backgroundClor,
                color: theme?.color,
              }}
            >
              {item.type}
            </div>
          </div>
          <div className={styles.line}>
            <div className={styles.prompt}>Prompt:</div>
            <div className={styles.promptText}>
              {truncateString(item?.prompt)}
            </div>
          </div>
        </div>
      </div>
      <OptionMenu menuProps={menuProps} />
    </div>
  );
};

export default CopyCraftItem;
