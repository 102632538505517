import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { IDomain } from 'types';
import { isAdmin } from 'utils/Utils';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';
import { RootState } from '../rootReducer';

export const DOMAINS_FETCHED = 'domains_fetched';
export const DOMAINS_LOADING = 'domains_loading';
export const DOMAIN_LOADING = 'domain_loading';
export const DOMAIN_HAS_BEEN_DELETE = 'domain_has_been_delete';

export const STATUS_CHANGED = 'status_changed';
export const SELECT_DOMAIN = 'SELECT_DOMAIN';
export const ADDING_CNAME_STATUS = 'adding_cname';
export const ADDING_DOMAIN_STATUS = 'adding_domain';
export const NOT_AT_ALL_STATUS = 'not_at_all';
export const WOO_HOO_STATUS = 'woo-hoo';
export const ALREADY_EXISTS_STATUS = 'already_exists';
export const NEUTRAL_STATUS = 'neutral';
export const SET_DOMAIN_ERROR = 'SET_DOMAIN_ERROR';
export const ADD_DOMAIN_POPUP = 'ADD_DOMAIN_POPUP';
export const DOMAIN_CNAME_POPUP = 'DOMAIN_CNAME_POPUP';
export const REMOVE_DOMAIN_POPUP = 'REMOVE_DOMAIN_POPUP';

type DispatchType = ThunkDispatch<RootState, void, Action>;
type GetStateType = () => RootState;

export const domainsFetched = (payload: IDomain[]) => ({
  type: DOMAINS_FETCHED,
  payload,
});

export const fetchDomains = () => async (dispatch: DispatchType) => {
  dispatch({ type: DOMAINS_LOADING, payload: true });
  const domains = await graphQlCall({
    queryTemplateObject: QUERIES.GET_USER_DOMAINS_QUERY,
    headerType: 'USER-AUTH',
  });

  dispatch(domainsFetched(domains as IDomain[]));
};

export const addDomain = (name: string, projectId?: string) => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  try {
    dispatch({ type: DOMAINS_LOADING, payload: true });
    try {
      const domain = await graphQlCall({
        queryTemplateObject: QUERIES.ADD_DOMAIN_MUTATION,
        values: {
          name,
          funnelId: projectId,
        },
        headerType: 'USER-AUTH',
      });
      const state = getState();
      const existedDomains = [...state.domains.items];

      existedDomains.push(domain as IDomain);
      dispatch(domainsFetched(existedDomains));
      const newStatus =
        domain.status === 'Verified' ? WOO_HOO_STATUS : ADDING_CNAME_STATUS;
      dispatch(setDomainAddingStatus(newStatus));
      if (isAdmin()) dispatch(domainAddCNAMEPopup(true));
    } catch (error: any) {
      let message = 'API ERROR';
      if (error.message.includes('already exists')) {
        message = `Error: Domain already in use.`;
      }
      dispatch({
        type: SET_DOMAIN_ERROR,
        payload: message,
      });
    }
  } catch (error: any) {
    console.log(error.message);
  }
  dispatch({ type: DOMAINS_LOADING, payload: false });
};

export const clearDomainError = () => (dispatch: DispatchType) => {
  dispatch({
    type: SET_DOMAIN_ERROR,
    payload: null,
  });
};

export const deleteDomain = (id: string) => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  try {
    dispatch({ type: DOMAINS_LOADING, payload: true });
    await graphQlCall({
      queryTemplateObject: QUERIES.DELETE_DOMAIN_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        id,
      },
    });
    const state = getState();
    const existedDomains = [...state.domains.items];
    const filteredDomain = existedDomains.filter((domain) => domain._id !== id);
    dispatch(domainsFetched(filteredDomain));
    dispatch({ type: DOMAIN_HAS_BEEN_DELETE, payload: true });
    dispatch(selectDomain());
  } catch (error: any) {
    console.error(error.message);
  }
  dispatch({ type: DOMAINS_LOADING, payload: false });
};

export const selectFunnel = (id: string, projectId: string) => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  try {
    await graphQlCall({
      queryTemplateObject: QUERIES.UPDATE_DOMAIN_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        id,
        funnelId: projectId,
      },
    });
    const state = getState();
    const existedDomains = [...state.domains.items];
    existedDomains.forEach((domain: IDomain) => {
      if (domain._id === id) {
        domain.projectId = projectId;
      }
    });
    dispatch(domainsFetched(existedDomains));
  } catch (error: any) {
    console.error(error.message);
  }
};

export const setDomainAddingStatus = (payload: string) => ({
  type: STATUS_CHANGED,
  payload,
});

export const removeFromProject = (id: string) => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  try {
    dispatch({ type: DOMAINS_LOADING, payload: true });
    await graphQlCall({
      queryTemplateObject: QUERIES.DISCONNECT_FUNNEL_DOMAIN_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        id,
      },
    });
    const state = getState();
    const domains = state.domains.items.map((domain) => {
      if (domain._id === id) {
        domain.projectId = undefined;
      }
      return domain;
    });
    dispatch(domainsFetched(domains));
  } catch (error: any) {
    console.error(error.message);
  }
};

export const refreshDomain = (id: string) => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  const state = getState();
  const stateDomains = state.domains;
  try {
    dispatch({ type: DOMAIN_LOADING, payload: true });
    const updatedDomain = await graphQlCall({
      queryTemplateObject: QUERIES.CHECK_DOMAIN_STATUS_QUERY,
      headerType: 'USER-AUTH',
      values: {
        id,
      },
    });
    const domains = stateDomains.items.map((domain) => {
      if (domain._id === id) {
        domain.status = updatedDomain.status;
      }
      return domain;
    });
    dispatch(domainsFetched(domains));
    dispatch(selectDomain());
  } catch (error: any) {
    console.error(error.message);
    dispatch(domainsFetched(stateDomains.items));
  }
};

export const selectDomain = (payload?: string) => (dispatch: DispatchType) => {
  const stateAction = {
    type: SELECT_DOMAIN,
    payload,
  };
  dispatch(stateAction);
};

export const addDomainPopupAction = (payload: boolean) => (
  dispatch: DispatchType
) => {
  const stateAction = {
    type: ADD_DOMAIN_POPUP,
    payload,
  };
  dispatch(stateAction);
};

export const domainAddCNAMEPopup = (payload: boolean) => (
  dispatch: DispatchType
) => {
  const stateAction = {
    type: DOMAIN_CNAME_POPUP,
    payload,
  };
  dispatch(stateAction);
};

export const removeDomainPopup = (payload: boolean) => (
  dispatch: DispatchType
) => {
  const stateAction = {
    type: REMOVE_DOMAIN_POPUP,
    payload,
  };
  dispatch(stateAction);
};
