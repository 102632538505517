import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { notification } from 'antd';
import s from './PageSignup.module.scss';
import logo from '../../Assets/images/signupPage/logo.svg';
import bookleLogo from '../../Assets/images/signupPage/bookleLogo.svg';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import InputV2 from '../../Components/Common/Inputs/InputV2/InputV2';
import InputPassword from '../../Components/Common/Inputs/InputPassword/InputPassword';
import PrimaryButton from '../../Components/Common/PrimaryButton/PrimaryButton';
import SpinnerWhite from '../../Components/Common/SpinnerWhite/SpinnerWhite';
import QUERIES from '../../graphql/queries';
import { graphQlCall } from '../../graphql/utils';
import { useParams } from 'react-router-dom';
import Spinner from 'Components/Common/Spinner/Spinner';
import ResentTokenPopup from './ResentTokenPopup';
import ErrorMessage from 'Components/Common/ErrorMessage/ErrorMessage';

const PageFinishSignup = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [initialFirstName, setInitialFirstName] = useState('');
  const [initialLastName, setInitialLastName] = useState('');
  const [openRegeneratePopup, setOpenRegeneratePopup] = useState(false);

  interface IValues {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
  }

  const getTokenInfo = async () => {
    setLoading(true);
    try {
      const { email, name } = await graphQlCall({
        queryTemplateObject: QUERIES.GET_SIGNUP_TOKEN_INFO,
        values: {
          token,
        },
      });
      setEmail(email);
      // setInitialNames(name);
    } catch (e: any) {
      if (e.message.includes('token expired')) {
        setOpenRegeneratePopup(true);
      } else {
        console.error(e);
        handleError(e);
      }
    }
    setLoading(false);
  };

  const setInitialNames = (text: string) => {
    if (text.includes(' ')) {
      const parts = text.split(' ');
      setInitialFirstName(parts[0]);
      setInitialLastName(parts[1]);
    } else {
      setInitialFirstName(text);
    }
  };

  useEffect(() => {
    getTokenInfo();
  }, []);

  const onFinish = async (values: IValues) => {
    try {
      setLoading(true);
      const payload = {
        name: `${values.firstName} ${values.lastName}`,
        email: values.email,
        token,
        password: values.password,
      };

      await graphQlCall({
        queryTemplateObject: QUERIES.FINISH_SIGNUP,
        values: payload,
      });

      const cognitoUser = await Auth.signIn(values.email, values.password);
      Document.prototype.cognitoUser = cognitoUser;
      notification.success({
        message: 'Sign up successfully',
        placement: 'topRight',
        duration: 1.5,
        onClose: () => {
          history.replace({ pathname: '/console/autologin' });
        },
      });
    } catch (err: any) {
      handleError(err);
    }
  };

  const handleError = (err: any) => {
    notification.error({
      message: 'Error',
      description: err.message ? err.message : err.toString(),
      placement: 'topRight',
      duration: 1.5,
    });
    console.error(err);
    setLoading(false);
  };

  return (
    <div className={s.container}>
      <div className={s.innerContainer}>
        <div className={s.formContainer}>
          <div className={s.logoContainer}>
            {params.get('app') == 'bookle' ? (
              <img alt="Bookle Logo" className={s.logo} src={bookleLogo} />
            ) : (
              <img alt="Autofunnel Logo" className={s.logo} src={logo} />
            )}
          </div>
          <div className={s.innerContainer}>
            <div className={s.header}>
              <p>Finish creating your account</p>
            </div>
            {!loading && (
              <Formik
                initialValues={{
                  firstName: initialFirstName,
                  lastName: initialLastName,
                  email: email,
                  password: '',
                  confirmPassword: '',
                }}
                validationSchema={Yup.object({
                  firstName: Yup.string()

                    .required('First name is required')
                    .matches(/^[aA-zZ\s]+$/, 'Please, enter only alphabets'),
                  lastName: Yup.string()
                    .required('Last name is required')
                    .matches(/^[aA-zZ\s]+$/, 'Please, enter only alphabets'),
                  email: Yup.string()
                    .required('Email is required')
                    .email('Please, enter valid email'),
                  password: Yup.string()
                    .required('Password is required')
                    .min(8, 'Please, enter minimum 8 characters'),
                  confirmPassword: Yup.string()
                    .oneOf([Yup.ref('password'), null], 'Passwords must match')
                    .required('Confirm password is required'),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  onFinish(values);
                  setSubmitting(false);
                }}
              >
                {({ errors, touched }) => (
                  <Form className={s.form}>
                    <div className={s.userInfo}>
                      <div className={s.field1}>
                        <InputV2
                          width={175}
                          placeholder="First Name"
                          name="firstName"
                          type="text"
                        />
                      </div>
                      <div className={s.field2}>
                        <InputV2
                          width={175}
                          placeholder="Last Name"
                          name="lastName"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className={s.field3}>
                      <InputV2
                        width={370}
                        placeholder="Email"
                        name="email"
                        type="text"
                        readOnly={true}
                      />
                    </div>
                    <div className={s.field4}></div>
                    <div className={s.field5}>
                      <InputPassword
                        width={370}
                        placeholder="Password"
                        name="password"
                      />
                      {touched.password && (
                        <ErrorMessage message={errors.password} />
                      )}
                    </div>
                    <div className={s.field6}>
                      <InputPassword
                        width={370}
                        placeholder="Confirm Password"
                        name="confirmPassword"
                      />
                      {touched.confirmPassword && (
                        <ErrorMessage message={errors.confirmPassword} />
                      )}
                    </div>
                    <div className={s.submitButton}>
                      <PrimaryButton
                        width={230}
                        type="submit"
                        isBlackBtn={true}
                      >
                        {loading ? <SpinnerWhite size={27} /> : 'Register'}
                      </PrimaryButton>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
            {loading && <Spinner size={300} />}

            {/* <div className={s.footer}>
              <p>Forgot your password?</p>
              <div className={s.link}>
                <Link to="/console/reset-password">Click here to reset</Link>
              </div>
          </div> */}
          </div>
        </div>
      </div>
      {openRegeneratePopup && (
        <ResentTokenPopup
          token={token}
          onClose={() => setOpenRegeneratePopup(false)}
        />
      )}
    </div>
  );
};

export default PageFinishSignup;
