import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Popconfirm } from 'antd';
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { TEMPLATES_URL } from '../../../Constants';
import {
  IUpdateSectionPayload,
  fetchSectionsAction,
  fetchSectionsMenuAction,
  updateSectionsMenuAction,
  updateSectionMenuImage,
  updateSectionAction,
} from '../../../store/sections/sectionsActions';
import SmallButton from '../../../Styled/SmallStyledButton';
import EditText from '../../projects/EditableLandingText';
import AddMenu from './AddMenu';
import AddDevider from './AddDevider';
import AddSection from './AddSection';
import Image from './MenuImage';
import OtherSections from './OtherSections';
import SectionImage from '../ImageWithUpload';
import UploadSectionData from './UploadSectionData';
import ExitPopupSwitch from './ExitPopupSwitch';
import { IMenuItem, ISection, ISectionMenu, ISectionSubMenuItem } from 'types';
import { RootState } from 'store/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

type DispatchType = ThunkDispatch<RootState, void, Action>;

const StyledSectionsMenu = styled.section`
  padding: 20px;

  .folder {
    margin-left: 10px;
    margin-bottom: 30px;

    .items-list {
      list-style: none;

      .item {
        margin-top: 20px;

        .item-header {
          display: grid;
          grid-template-columns: 120px auto 550px;

          .actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }

        .item-content {
          list-style: none;
          align-items: baseline;
          border-left: 2px #999 solid;
          margin-bottom: 30px;

          .devider-name {
            display: flex;
          }

          .devider {
            align-items: center;
            margin-top: 10px;
            display: grid;
            grid-template-columns: 35px auto 220px;

            &:hover {
              background: rgba(0, 0, 0, 0.07);
              .hidden {
                visibility: visible;
              }
            }
            .hidden {
              visibility: hidden;
            }
          }

          .section {
            margin-top: 5px;
            margin-bottom: 5px;
            display: grid;
            grid-template-columns: 35px auto 300px;
            align-items: center;

            &:hover {
              background: rgba(0, 0, 0, 0.07);
              .hidden {
                visibility: visible;
              }
            }

            .hidden {
              visibility: hidden;
            }

            .name {
              padding-left: 20px;
            }

            .section-actions {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
          }

          .bad-section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 40px;
            font-weight: bold;
            background-color: red;

            .name {
              color: yellow;
            }

            .hidden {
              visibility: hidden;
            }

            &:hover {
              background-color: yellow;

              .name {
                color: black;
              }

              .hidden {
                visibility: visible;
              }
            }
          }
        }
      }

      .add-item {
        display: flex;
        width: 500px;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .arrow {
    cursor: pointer;
    width: 40px;

    > span {
      > svg {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

interface IProps {
  sections: ISection[];
  sectionsMenu: ISectionMenu;
  getSections: () => void;
  getSectionsMenu: () => void;
  updateSectionsMenu: (data: IMenuItem[]) => void;
  updateSectionMenuImage: (file: File, index: string) => void;
  updateSection: (data: IUpdateSectionPayload) => void;
}

const SectionsMenu = (props: IProps) => {
  const [editingItem, setEditingItem] = useState('');
  const [visibleItem, setVisibleItem] = useState('');
  const [addMenu, setAddMenuOpen] = useState(false);

  useEffect(() => {
    props.getSections();
    props.getSectionsMenu();
  }, []);

  const handleAddMenu = (name: string) => {
    let menu = { ...props.sectionsMenu };
    menu.items.push({
      type: 'menu',
      name,
      items: [],
    });
    props.updateSectionsMenu(menu.items);
  };

  const handleAddDevider = (label: string, index: number) => {
    const menu = props.sectionsMenu;
    menu.items = props.sectionsMenu.items.map((item, i) => {
      if (i === index && item.type === 'menu') {
        item.items.push({
          type: 'devider',
          label,
        });
      }
      return item;
    });
    props.updateSectionsMenu(menu.items);
  };

  const handleChangeSectionImage = (image: File, id: string) => {
    props.updateSection({ fields: { image }, id });
  };

  const handleEditSectionText = (name: string, id: string) => {
    setEditingItem('');
    props.updateSection({ fields: { name }, id });
  };

  const handleChangeSectionData = (data: any, id: string) => {
    props.updateSection({ fields: { data }, id });
  };

  const generateItem = (
    item: IMenuItem,
    menuIndex: number,
    itemIndex: number
  ) => {
    if (item.type === 'devider') {
      return (
        <div className="devider">
          <div className="hidden" style={{ display: 'flex' }}>
            <div
              className="arrow"
              onClick={() => handleUpItem(menuIndex, itemIndex)}
            >
              <ArrowUpOutlined />
            </div>
            <div
              className="arrow"
              onClick={() => handleDownItem(menuIndex, itemIndex)}
            >
              <ArrowDownOutlined />
            </div>
          </div>
          <h3 className="devider-name">
            <EditText
              text={item.label}
              onEdit={(v) => handleEditDevider(v, menuIndex, itemIndex)}
              onClick={() => setEditingItem(`devider-${itemIndex}`)}
              opened={editingItem === `devider-${itemIndex}`}
            />
          </h3>
          <Popconfirm
            title="Delete devider?"
            onConfirm={() => handleDeleteItem(menuIndex, itemIndex)}
          >
            <SmallButton className="hidden">
              <DeleteOutlined />
            </SmallButton>
          </Popconfirm>
        </div>
      );
    }
    if (item.type === 'section') {
      let existedSection: ISection | undefined;
      props.sections.forEach((section) => {
        if (section._id === item.section) {
          existedSection = section;
        }
      });
      if (!existedSection) {
        return (
          <div className="bad-section">
            <h3 className="name">Bad section</h3>
            <div className="section-actions hidden">
              <Popconfirm
                title="Delete section?"
                onConfirm={() => handleDeleteItem(menuIndex, itemIndex)}
                className="hidden"
              >
                <SmallButton>
                  <DeleteOutlined />
                </SmallButton>
              </Popconfirm>
            </div>
          </div>
        );
      } else
        return (
          <div className="section">
            <div className="hidden" style={{ display: 'flex' }}>
              <div
                className="arrow"
                onClick={() => handleUpItem(menuIndex, itemIndex)}
              >
                <ArrowUpOutlined />
              </div>
              <div
                className="arrow"
                onClick={() => handleDownItem(menuIndex, itemIndex)}
              >
                <ArrowDownOutlined />
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SectionImage
                image={
                  existedSection.image
                    ? `${TEMPLATES_URL}/${existedSection.image}`
                    : process.env.PUBLIC_URL +
                      '/console/assets/template-section.png'
                }
                onChange={(file) =>
                  handleChangeSectionImage(file, existedSection?._id || '')
                }
              />
              <h3 className="name">
                <EditText
                  text={existedSection.name}
                  onEdit={(v) =>
                    handleEditSectionText(v, existedSection?._id || '')
                  }
                  onClick={() => setEditingItem(`section-${itemIndex}`)}
                  opened={editingItem === `section-${itemIndex}`}
                />
              </h3>
            </div>
            <div className="section-actions">
              <UploadSectionData
                data={existedSection.data}
                onSubmit={(v) =>
                  handleChangeSectionData(v, existedSection?._id || '')
                }
                hidden={visibleItem !== `sub-item-${menuIndex}-${itemIndex}`}
              />

              <Popconfirm
                title="Delete section?"
                onConfirm={() => handleDeleteItem(menuIndex, itemIndex)}
                className="hidden"
              >
                <SmallButton>
                  <DeleteOutlined />
                </SmallButton>
              </Popconfirm>
            </div>
          </div>
        );
    }
    return null;
  };

  const handleAddSection = (sectionId: string | undefined, index: number) => {
    if (!sectionId) {
      return;
    }
    const menu = props.sectionsMenu;
    menu.items = props.sectionsMenu.items.map((item, i) => {
      if (i === index && item.type === 'menu') {
        item.items.push({
          type: 'section',
          name: '',
          section: sectionId,
        });
      }
      return item;
    });
    props.updateSectionsMenu(menu.items);
  };

  const handleEditMenuName = (newName: string, index: number) => {
    setEditingItem('');
    const menu = props.sectionsMenu;
    menu.items = props.sectionsMenu.items.map((item, i) => {
      if (i === index && item.type === 'menu') {
        item.name = newName;
      }
      return item;
    });
    props.updateSectionsMenu(menu.items);
  };

  const handleEditDevider = (
    newLabel: string,
    menuIndex: number,
    itemIndex: number
  ) => {
    setEditingItem('');
    const menu = props.sectionsMenu;
    menu.items = props.sectionsMenu.items.map((item, i) => {
      if (i === menuIndex && item.type === 'menu') {
        item.items = item.items.map((subitem, j) => {
          if (j === itemIndex && subitem.type === 'devider') {
            subitem.label = newLabel;
          }
          return subitem;
        });
      }
      return item;
    });
    props.updateSectionsMenu(menu.items);
  };

  const handleDeleteItem = (menuIndex: number, itemIndex: number) => {
    const menu = props.sectionsMenu;
    menu.items = props.sectionsMenu.items.map((item, i) => {
      if (i === menuIndex && item.type === 'menu') {
        item.items = item.items.filter((_subitem, j) => j !== itemIndex);
      }
      return item;
    });
    props.updateSectionsMenu(menu.items);
  };

  const handleDeleteMenu = (menuIndex: number) => {
    const menu = props.sectionsMenu;
    menu.items = props.sectionsMenu.items.filter((item, i) => i !== menuIndex);
    props.updateSectionsMenu(menu.items);
  };

  const handleUpItem = (menuIndex: number, itemIndex: number) => {
    const currentItem = props.sectionsMenu.items[
      menuIndex
    ] as ISectionSubMenuItem;
    if (
      itemIndex === 0 ||
      (currentItem.type === 'menu' && currentItem.items.length < 1)
    ) {
      return;
    }
    const menu = props.sectionsMenu;
    menu.items = props.sectionsMenu.items.map((item, i) => {
      if (i === menuIndex && item.type === 'menu') {
        let newItems: IMenuItem[] = [];
        item.items.forEach((subitem, i) => {
          if (i === itemIndex - 1) {
            newItems.push(item.items[itemIndex]);
            newItems.push(subitem);
          } else if (i !== itemIndex) {
            newItems.push(subitem);
          }
        });
        item.items = newItems;
      }
      return item;
    });
    props.updateSectionsMenu(menu.items);
  };

  const handleDownItem = (menuIndex: number, itemIndex: number) => {
    const currentItem = props.sectionsMenu.items[
      menuIndex
    ] as ISectionSubMenuItem;
    if (itemIndex + 1 >= currentItem.items.length) {
      return;
    }
    const menu = props.sectionsMenu;
    menu.items = props.sectionsMenu.items.map((item, i) => {
      if (i === menuIndex && item.type === 'menu') {
        let newItems: IMenuItem[] = [];
        item.items.forEach((subitem, i) => {
          if (i === itemIndex + 1) {
            newItems.push(subitem);
            newItems.push(item.items[itemIndex]);
          } else if (i !== itemIndex) {
            newItems.push(subitem);
          }
        });
        item.items = newItems;
      }
      return item;
    });
    props.updateSectionsMenu(menu.items);
  };

  const handleUpMenu = (index: number) => {
    if (index === 0 || props.sectionsMenu.items.length < 1) {
      return;
    }
    let newItems: IMenuItem[] = [];

    props.sectionsMenu.items.forEach((item, i) => {
      if (i === index - 1) {
        newItems.push(props.sectionsMenu.items[index]);
        newItems.push(item);
      } else if (i !== index) {
        newItems.push(item);
      }
    });
    props.updateSectionsMenu(newItems);
  };

  const handleDownMenu = (index: number) => {
    if (index + 1 >= props.sectionsMenu.items.length) {
      return;
    }
    let newItems: IMenuItem[] = [];

    props.sectionsMenu.items.forEach((item, i) => {
      if (i === index + 1) {
        newItems.push(item);
        newItems.push(props.sectionsMenu.items[index]);
      } else if (i !== index) {
        newItems.push(item);
      }
    });
    props.updateSectionsMenu(newItems);
  };

  const handleChangeImage = (file: File, index: number) => {
    props.updateSectionMenuImage(file, String(index));
  };

  const getUnlinkedSection = () => {
    if (!props.sectionsMenu.items) {
      return [];
    }
    let sections: ISection[] = [];
    props.sections.forEach((section) => {
      let notFound = true;
      props.sectionsMenu.items.forEach((item) => {
        if (item.type === 'menu') {
          item.items.forEach((subitem) => {
            if (subitem.type === 'section' && subitem.section === section._id) {
              notFound = false;
            }
          });
        }
      });
      if (notFound) {
        sections.push(section);
      }
    });
    return sections;
  };

  const handleChangePopup = (v: boolean, menuIndex: number) => {
    const menu = props.sectionsMenu;
    menu.items = props.sectionsMenu.items.map((item, i) => {
      if (item.type === 'menu' && i === menuIndex) {
        item.onlyRegular = v;
      }
      return item;
    });
    props.updateSectionsMenu(menu.items);
  };

  return (
    <StyledSectionsMenu>
      <h2>Sections menu</h2>
      <AddMenu
        onSubmit={handleAddMenu}
        onClose={() => setAddMenuOpen(false)}
        open={addMenu}
      />
      <SmallButton onClick={() => setAddMenuOpen(true)}>Add menu</SmallButton>
      <div className="folder">
        {props.sectionsMenu && props.sectionsMenu.items && (
          <ul className="items-list">
            {props.sectionsMenu.items.map((item, index) => (
              <li key={index} className="item">
                <div className="item-header">
                  <Image
                    image={
                      item.type !== 'devider' && item.image
                        ? `${TEMPLATES_URL}/${item.image}`
                        : ''
                    }
                    onChange={(file) => handleChangeImage(file, index)}
                  />
                  <h2>
                    <EditText
                      text={item.type !== 'devider' ? item.name : ''}
                      onEdit={(v) => handleEditMenuName(v, index)}
                      onClick={() => setEditingItem(`menu-${index}`)}
                      opened={editingItem === `menu-${index}`}
                    />
                  </h2>
                  <div className="actions">
                    <AddDevider onSubmit={(v) => handleAddDevider(v, index)} />
                    <AddSection
                      sections={getUnlinkedSection()}
                      onSubmit={(v) => handleAddSection(v, index)}
                    />
                    <ExitPopupSwitch
                      onlyRegular={
                        item.type === 'menu' && item.onlyRegular === true
                      }
                      onChange={(v) => handleChangePopup(v, index)}
                    />
                    <div className="arrow" onClick={() => handleUpMenu(index)}>
                      <ArrowUpOutlined />
                    </div>
                    <div
                      className="arrow"
                      onClick={() => handleDownMenu(index)}
                    >
                      <ArrowDownOutlined />
                    </div>
                    <Popconfirm
                      title="Delete menu?"
                      onConfirm={() => handleDeleteMenu(index)}
                    >
                      <SmallButton>
                        <DeleteOutlined />
                      </SmallButton>
                    </Popconfirm>
                  </div>
                </div>
                {item.type === 'menu' && (
                  <ul className="item-content">
                    {item.items.map((subitem, j) => (
                      <li
                        key={`sub-item-${index}-${j}`}
                        onMouseEnter={() =>
                          setVisibleItem(`sub-item-${index}-${j}`)
                        }
                        onMouseLeave={() => setVisibleItem('')}
                      >
                        {generateItem(subitem, index, j)}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
      <hr />
      <OtherSections sections={getUnlinkedSection()} />
    </StyledSectionsMenu>
  );
};
const mapStateToProps = (state: RootState) => ({
  sections: state.sections.items,
  sectionsMenu: state.sections.menu,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  getSections: () => dispatch(fetchSectionsAction()),
  getSectionsMenu: () => dispatch(fetchSectionsMenuAction()),
  updateSectionsMenu: (data: IMenuItem[]) =>
    dispatch(updateSectionsMenuAction(data)),
  updateSectionMenuImage: (file: File, index: string) =>
    dispatch(updateSectionMenuImage(file, index)),
  updateSection: (data: IUpdateSectionPayload) =>
    dispatch(updateSectionAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SectionsMenu);
