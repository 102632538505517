import { ENVIRONMENT } from 'Constants';

import s from './InputSubdomain.module.scss';

interface IProps {
  name: string;
  placeholder: string;
  width: number;
  setState: (stateValue: string) => void;
  value: string;
  label?: string;
  onBlur?: () => void;
  error?: string | null;
}

function InputSubdomain(props: IProps) {
  return (
    <div className={s.container}>
      {props.label && <p className={s.label}>{props.label}</p>}
      <div className={s.whiteBackground}>
        <div className={s.https}>https://</div>
        <input
          autoComplete="off"
          maxLength={20}
          name={props.name}
          type={'text'}
          placeholder={props.placeholder}
          className={s.inputSubdomain}
          style={{ width: props.width }}
          value={props.value}
          onChange={(e) => props.setState(e.target.value)}
          onBlur={props.onBlur}
        />
        <div className={s.mech}>
          {ENVIRONMENT === 'DEV' ? '.dev.autofunnel.ai' : '.autofunnel.ai'}
        </div>
        {props.error && <small className={s.errorInline}>{props.error}</small>}
      </div>
    </div>
  );
}

export default InputSubdomain;
