import React, { useState } from 'react';
import downSvg from '../Assets/down.svg';
import s from './Common.module.scss';

interface IOption<T> {
  label: string;
  value: T;
}

interface IProps<T> {
  options: IOption<T>[];
  value: T;
  prefix?: string;
  onChange: (newValue: T) => void;
}

const Select = <T extends unknown>(props: IProps<T>) => {
  const [open, setOpen] = useState(false);
  const selectedOption = props.options.find(
    (option) => option.value === props.value
  );

  if (selectedOption === undefined) {
    throw new Error(`wrong value=${props.value}`);
  }

  const handleClickSelected = () => {
    setOpen(!open);
  };

  const handleSelect = (option: IOption<T>) => {
    setOpen(false);
    props.onChange(option.value);
  };

  return (
    <div className={s.Select}>
      <div className={s.selected} onClick={() => handleClickSelected()}>
        {props.prefix && <span className={s.prefix}>{props.prefix}</span>}
        {selectedOption.label}
        <img src={downSvg} className={`${open ? s.rotated : ''}`} />
      </div>
      {open && (
        <div>
          <div className={s.back} onClick={() => setOpen(false)}></div>
          <div className={s.selector}>
            {props.options.map((option, index) => (
              <div
                key={index}
                className={s.item}
                onClick={() => handleSelect(option)}
              >
                {option.label}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Select;
