import React from 'react';
import Button from '../../Components/Common/DomainButton/DomainButton';
import closeSvg from '../../Assets/icons/close.svg';
import worldSvg from '../../Assets/icons/world.svg';
import s from './popup.module.scss';

interface IProps {
  onClose: () => void;
  open: boolean;
  onHowToPressed: () => void;
  onDonePressed: () => void;
  domainName: string;
}

const AddCnamePopup = (props: IProps) => {
  const handleHowToPressed = () => {
    window.open(
      'https://autofunnel.freshdesk.com/support/solutions/articles/202000060373',
      '_blank'
    );
  };

  return props.open ? (
    <div className={s.addDomainWrapper}>
      <div className={s.modal}>
        <img
          className={s.closeIcon}
          src={closeSvg}
          alt="x"
          onClick={() => props.onClose()}
        />
        <img className={s.worldIcon} src={worldSvg} alt="O" />
        <h1 className={s.headerText}>Add CNAME</h1>
        <p className={s.addCnameText}>
          In order for your domain integration to work, you will need to log
          into wherever you purchased your domain and add a CNAME record that
          points {props.domainName} at <b>live.autofunnel.ai</b>. If you are not
          sure on how to do this, you can review our articles below for
          instructions on several popular domain registrars. You can also get
          this information from your domain registrar directly.
        </p>
        <div className={s.buttonsBlock}>
          <Button
            name="How to Add and Manage CNAME Records"
            onClick={() => handleHowToPressed()}
          />
          <Button
            name="I`ve Done This"
            onClick={() => props.onDonePressed()}
            isBlackBtn={true}
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default AddCnamePopup;
