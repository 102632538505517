import React from 'react';
import s from './Modal.module.scss';

interface IProps {
  closeModal?: () => void;
  displayModal: boolean;
  children: JSX.Element[] | JSX.Element;
}

const Modal = (props: IProps) => {
  function closeModal(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    if (props.closeModal) {
      props.closeModal();
    }
  }

  return (
    <div
      className={s.modal}
      onClick={closeModal}
      style={{ display: props.displayModal ? 'block' : 'none' }}
    >
      <div className={s.modalContent} onClick={(e) => e.stopPropagation()}>
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
