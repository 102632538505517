import styled from 'styled-components';
import { colors } from './constants';

export default styled.button`
  width: 230px;
  height: 50px;
  border: none;
  background: ${colors.primary};
  border-radius: 2px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background: ${colors.primaryHovered};
  }
`;
