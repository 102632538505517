import { connect } from 'react-redux';
import { useEffect } from 'react';
import { RootState } from 'store/rootStore';
import { IDomain, IGeneralProjectName } from 'types';
import {
  fetchDomains,
  addDomainPopupAction,
} from 'store/domains/domainsActions';
import { fetchFunnelsNameAction } from 'store/projects/projectsActions';
import Button from 'UILib/Button/Button';
import Loader from 'UILib/Loader/Loader';
import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';
import { ReactComponent as GlobeIcon } from 'Assets/icons/domainIcon.svg';
import { ReactComponent as ConnectIcon } from 'Assets/icons/connect.svg';
import { ReactComponent as CheckMarkIcon } from 'Assets/icons/checkMarkInnerCircle.svg';
import DomainItem from './DomainItem/DomainItem';

import s from './Domains.module.scss';

interface IProps {
  projects: IGeneralProjectName[];
  domains: IDomain[];
  isLoading: boolean;
  fetchDomains: () => void;
  getProjectsName: () => void;
  toggleAddDomainPopup: (value: boolean) => void;
}

const Domains = (props: IProps) => {
  useEffect(() => {
    props.getProjectsName();
    props.fetchDomains();
  }, []);

  return (
    <div className={s.blockWrapper}>
      <div className={s.header}>
        <div>
          <h2 className={s.title}>Domains</h2>
          <div className={s.subtitle}>
            Manage your domains and assign funnels
          </div>
        </div>
        {!props.isLoading && (
          <Button
            width={220}
            height={40}
            className={s.addFirstDomainBtn}
            postfixIcon={<PlusIcon />}
            onClick={() => {
              props.toggleAddDomainPopup(true);
            }}
          >
            {props.domains.length > 0 ? 'Add Domain' : 'Add First Domain'}
          </Button>
        )}
      </div>

      {props.isLoading ? (
        <div className={s.loaderContainer}>
          <Loader />
        </div>
      ) : props.domains.length === 0 ? (
        <div className={s.addFirstDomainWrapper}>
          <p className={s.domainsContentText}>
            Your account has no connected domains. You can connect the first one
            here:
          </p>
        </div>
      ) : (
        <div className={s.domainItemsBlockWrapper}>
          <div className={s.domainItemHeaderBlock}>
            <div>
              <GlobeIcon />
              Domain Name
            </div>
            <div>
              <ConnectIcon /> Associated
            </div>
            <div>
              <CheckMarkIcon /> Status
            </div>
            <div></div>
          </div>
          <div className={s.domainItemsWrapper}>
            {props.domains.map((domain: IDomain) => (
              <DomainItem
                key={domain._id}
                name={domain.name}
                status={domain.status}
                projectId={domain.projectId ? domain.projectId : undefined}
                id={domain._id}
                funnels={props.projects}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  projects: state.projects.projectsName,
  domains: state.domains.items,
  isLoading: state.domains.loading,
});

const mapDispatchToProps = {
  fetchDomains: () => fetchDomains(),
  getProjectsName: () => fetchFunnelsNameAction(),
  toggleAddDomainPopup: (value: boolean) => addDomainPopupAction(value),
};

export default connect(mapStateToProps, mapDispatchToProps)(Domains);
