import React from 'react';
import s from './Common.module.scss';
import Select from './Select';
import AiTextarea from './AiTextArea';
import AiInput from './AiInput';
import burgerSvg from '../Assets/burger.svg';
import xSvg from '../Assets/x.svg';
import { Prompt } from '../aiTemplateTypes';

interface IProps {
  prompt: Prompt;
  groupId: number;
  onChange: (newPrompt: Prompt) => void;
  onChangeGroup: (newGroupId: number) => void;
  onDelete: () => void;
  totalGroups: number;
  index: number;
}

const typeOptions = [
  {
    label: 'Text',
    value: 'text',
  },
  {
    label: 'Image',
    value: 'image',
  },
];

const PromptComponent = (props: IProps) => {
  const totalGroups = props.totalGroups + 1;
  const groupOptions = Array.from({ length: totalGroups }).map(
    (_id: any, index: number) => ({
      label: `${index + 1}`,
      value: index + 1,
    })
  );

  const handleChangeType = (newType: string) => {
    const newPrompt = { ...props.prompt };
    newPrompt.type = newType;
    props.onChange(newPrompt);
  };

  const handleChangeId = (newId: string) => {
    const newPrompt = { ...props.prompt };
    newPrompt.id = newId;
    props.onChange(newPrompt);
  };

  const handleChangePrompt = (newText: string) => {
    const newPrompt = { ...props.prompt };
    newPrompt.prompt = newText;
    props.onChange(newPrompt);
  };

  return (
    <div className={s.listItem}>
      <h3 className={s.header}>
        <span></span>
        <span>Prompt {props.index}</span>
      </h3>
      <div className={s.closeButton} onClick={() => props.onDelete()}>
        <img src={xSvg} alt="x" />
      </div>
      <AiInput name="ID" value={props.prompt.id} onChange={handleChangeId} />
      <div className={s.promptSelectCluster}>
        <div className={s.selectGroup}>
          <label>Type</label>
          <Select
            onChange={handleChangeType}
            value={props.prompt.type}
            options={typeOptions}
          />
        </div>
        <div className={s.selectGroup}>
          <label>Group</label>
          <Select
            onChange={props.onChangeGroup}
            value={props.groupId}
            options={groupOptions}
          />
        </div>
      </div>
      <AiTextarea
        name="Prompt"
        onChange={handleChangePrompt}
        value={props.prompt.prompt}
      />
    </div>
  );
};

export default PromptComponent;
