import React, { useState } from 'react';
import styled from 'styled-components';
import ModalStyled from '../../../Styled/ModalStyled';
import BigStyledButton from '../../../Styled/BigStyledButton';
import SmallButton from '../../../Styled/SmallStyledButton';
import { ReactComponent as CloseIcon } from '../../../Assets/icons/close.svg';
import { Input } from 'antd';

interface IPropsAdd {
  visible: boolean;
}

const StyledAdd = styled.div`
  .buttons-block {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

interface IProps {
  onSubmit: (label: string) => void;
}

const AddDevider = (props: IProps) => {
  const [label, setLabel] = useState('');
  const [show, setShow] = useState(false);

  const handleSubmit = () => {
    props.onSubmit(label);
    setLabel('');
    setShow(false);
  };

  const turnOnModal = () => {
    setLabel('');
    setShow(true);
  };

  const closeModal = () => {
    setLabel('');
    setShow(false);
  };

  return (
    <StyledAdd>
      <SmallButton onClick={() => turnOnModal()}>Add Devider</SmallButton>
      <ModalStyled visible={show}>
        <div className="modal-block">
          <div className="modal-header">
            <h2 className="modal-title">Devider Label</h2>
            <CloseIcon className="close-icon" onClick={closeModal} />
          </div>
          {show && (
            <div className="modal-body">
              <Input
                value={label}
                onChange={(e) => setLabel(e.target.value)}
                placeholder="label..."
              />
              <div className="buttons-block">
                <BigStyledButton
                  onClick={() => handleSubmit()}
                  disabled={!label.length}
                >
                  Submit
                </BigStyledButton>
              </div>
            </div>
          )}
        </div>
      </ModalStyled>
    </StyledAdd>
  );
};

export default AddDevider;
