import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import ModalStyled from '../../../Styled/ModalStyled';
import StyledInput from '../../../Styled/FormInputStyled';
import BigStyledButton from '../../../Styled/BigStyledButton';
import { ReactComponent as CloseIcon } from '../../../Assets/icons/close.svg';

const StyledCategoryModal = styled.div`
  .buttons-block {
    margin-top: 30px;
    text-align: center;
  }
`;

interface IProps {
  onSubmit: (newName: string) => void;
  onClose: () => void;
  show: boolean;
}

const NewCategoryModal = (props: IProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [newName, setNewName] = useState('');

  const handleSubmit = () => {
    props.onSubmit(newName);
    setNewName('');
    props.onClose();
  };

  return (
    <ModalStyled visible={props.show}>
      <div className="modal-block" ref={modalRef}>
        <div className="modal-header">
          <h2 className="modal-title">Create new category</h2>
          <CloseIcon className="close-icon" onClick={() => props.onClose()} />
        </div>
        {props.show && (
          <div className="modal-body">
            <StyledCategoryModal>
              <StyledInput
                value={newName}
                onChange={(e) => setNewName(e.currentTarget.value)}
              />
              <div className="buttons-block">
                <BigStyledButton onClick={() => handleSubmit()}>
                  Submit
                </BigStyledButton>
              </div>
            </StyledCategoryModal>
          </div>
        )}
      </div>
    </ModalStyled>
  );
};

export default NewCategoryModal;
