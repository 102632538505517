import React, { useEffect, useState } from 'react';
import s from './Common.module.scss';
import xIcon from 'Assets/icons/book/x.svg';
import burgerSvg from '../Assets/burger.svg';
import { ISection } from 'types';
import Select from './Select';
import { Content } from '../aiTemplateTypes';
import { TEMPLATES_URL } from 'Constants';
import downIcon from '../Assets/down.svg';

interface IProps {
  index: number;
  onDelete: () => void;
  allSections: ISection[];
  selectedSectionName: string;
  content: Content[];
  onSelectTemplate: (newTEmplateName: string) => void;
  allIds: string[];
  onChangeAiField: (aiField: string, id: string) => void;
}

interface IAIOption {
  label: string;
  value: string;
}

const initialAiOption: IAIOption = {
  label: 'not set',
  value: '',
};

const SectionComponent = (props: IProps) => {
  const [aiOptions, setAiOptions] = useState<IAIOption[]>([initialAiOption]);
  const [aiFields, setAiFields] = useState<string[]>([]);
  const [showFields, setShowFields] = useState(false);

  const options = props.allSections.map((dbSection) => ({
    label: dbSection.name,
    value: dbSection.name,
  }));

  options.push({
    label: 'Not selected',
    value: '',
  });

  const getAiFields = (section?: ISection) => {
    let allAiFields: string[] = [];
    if (section) {
      allAiFields = getAiFieldsFromBlocks(section.data);
    }
    setAiFields(allAiFields);
  };

  const getAiFieldsFromBlocks = (data: any): string[] => {
    let aiFields: string[] = [];
    if (data.blocks?.length) {
      data.blocks.forEach((block: any) => {
        if (block.aiField) {
          aiFields.push(block.aiField);
        }
      });
      aiFields.push(data.aiField as string);
    }
    if (data.aiField) {
      aiFields.push(data.aiField as string);
    }
    if (data.children?.length) {
      data.children.forEach((child: any) => {
        const childrenFields = getAiFieldsFromBlocks(child);
        aiFields = [...aiFields, ...childrenFields];
      });
    }
    return aiFields;
  };

  useEffect(() => {
    const newAiOptions = props.allIds.map((id) => ({
      label: id,
      value: id,
    }));
    newAiOptions.push(initialAiOption);
    setAiOptions(newAiOptions);
  }, [props.allIds]);

  useEffect(() => {
    const section = props.allSections.find(
      (dbSection) => dbSection.name === props.selectedSectionName
    );
    getAiFields(section);
  }, [props.selectedSectionName, props.allIds]);

  const getConnectedId = (aiField: string) => {
    let connectedId = '';
    props.content.map((connection) => {
      if (connection.aiField === aiField) {
        connectedId = connection.content;
      }
    });
    return connectedId;
  };

  const section = props.allSections.find(
    (s) => s.name === props.selectedSectionName
  );

  return (
    <div className={`${s.listItem} ${s.SectionItem}`}>
      <div className={`${section ? s.sectionSelectBlock : ''}`}>
        <div>
          <h3 className={s.header}>
            <img src={burgerSvg} />
            <span>Section {props.index}</span>
          </h3>
          <Select
            onChange={props.onSelectTemplate}
            options={options}
            value={props.selectedSectionName}
          />
        </div>

        {section && <img src={`${TEMPLATES_URL}/${section.image}`} alt="" />}
      </div>
      <div onClick={() => props.onDelete()} className={s.closeButton}>
        <img src={xIcon} alt="x" />
      </div>
      {showFields && (
        <div>
          {aiFields.map((aiField) => (
            <div className={s.selectGroup}>
              <label>{aiField}</label>
              <Select
                key={aiField}
                options={aiOptions}
                onChange={(id) => props.onChangeAiField(aiField, id)}
                value={getConnectedId(aiField)}
              />
            </div>
          ))}
        </div>
      )}
      {props.allIds.length ? (
        <div
          className={s.fieldsSwitcher}
          onClick={() => setShowFields(!showFields)}
        >
          <div className={s.switcherHeader}>
            <span>{showFields ? 'Hide' : 'Show'} AI Settings</span>
            <img src={downIcon} className={showFields ? s.active : ''} />
          </div>
        </div>
      ) : (
        <div className={s.sectionBlankFooter}></div>
      )}
    </div>
  );
};

export default SectionComponent;
