import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Popconfirm } from 'antd';

import {
  fetchCategoriesAction,
  fetchTemplatesAction,
  addCategoryAction,
  updateCategoryAction,
  deleteCategoryAction,
  changeCategoryAction,
  updateTemplateAction,
  deleteTemplateAction,
  fetchFunnelsIdsAction,
  createTemplateAction,
  syncTemplateAction,
  updateTemplateImageAction,
} from '../../../store/templates/templatesActions';
import PrimaryButton from '../../../Styled/PrimaryStyledButton';
import SecondaryButton from '../../../Styled/SecondaryStyledButton';
import NewCategoryModal from './NewCategoryModal';
import EditText from '../../projects/EditableLandingText';
import TemplatesList from './TemplatesList';
import AddTemplate from './AddTemplate';

const StyledAdminTemplates = styled.div`
  padding: 20px;

  .categories-list {
    margin: 20px 0;
    padding: 0;
    list-style: none;

    .category-item {
      .category-details {
        display: grid;
        max-width: 500px;
        grid-template-columns: max-content auto 200px;
        margin-bottom: 20px;
      }
    }
  }
`;

const AdminTemplates = (props) => {
  const [openNewCategoryModal, setOpenNewCategoryModal] = useState(false);
  const [editingCategory, setEditingCategory] = useState(0);
  const [editingTemplate, setEditingTemplate] = useState(0);

  useEffect(() => {
    props.getCategories();
    props.getTemplates();
    //props.fetchFunnelsIds();
  }, []);

  const handleAddCategory = (name) => {
    props.addCategory(name);
  };

  const updateCategoryName = (newName, id) => {
    props.updateCategory(newName, id);
    setEditingCategory(0);
  };

  const handleDeleteCategory = (id) => {
    props.deleteCategory(id);
  };

  const generateTemplatesList = (categoryId = null) => {
    const templates = props.templates.filter(
      (template) => template.category === categoryId
    );
    return (
      <TemplatesList
        templates={templates}
        categories={props.categories}
        onChangeCategory={(data) => props.changeCategory(data)}
        onUpdateTemplate={(data) => props.updateTemplate(data)}
        onDeleteTemplate={(template) => props.deleteTemplate(template)}
        editingTemplate={editingTemplate}
        onSetEdited={(id) => setEditingTemplate(id)}
        onSyncTemplate={(id) => props.syncTemplate(id)}
        onUpdateTemplateImage={(data) => props.updateTemplateImage(data)}
      />
    );
  };

  const handleAddTemplate = (id, type) => {
    props.createTemplate(id, type);
  };

  return (
    <StyledAdminTemplates>
      <h2 className="category-title">Templates and Categories </h2>
      <PrimaryButton onClick={() => setOpenNewCategoryModal(true)}>
        Add category
      </PrimaryButton>
      <ul className="categories-list">
        {props.categories.map((category) => (
          <li key={category._id} className="category-item">
            <div className="category-details">
              <EditText
                text={category.name}
                size="18px"
                onClick={() => setEditingCategory(category._id)}
                onEdit={(value) => updateCategoryName(value, category._id)}
                opened={editingCategory === category._id}
              />
              <div className="empty"></div>
              <div className="category-actions">
                <Popconfirm
                  title="Delete category?"
                  onConfirm={() => handleDeleteCategory(category._id)}
                >
                  <SecondaryButton>delete</SecondaryButton>
                </Popconfirm>
              </div>
            </div>
            {generateTemplatesList(category._id)}
          </li>
        ))}
      </ul>
      <h3>templates without category:</h3>
      {generateTemplatesList()}
      <hr />
      <AddTemplate funnels={props.funnelIds} onSelect={handleAddTemplate} />
      <NewCategoryModal
        show={openNewCategoryModal}
        onClose={() => setOpenNewCategoryModal(false)}
        onSubmit={(name) => handleAddCategory(name)}
      />
    </StyledAdminTemplates>
  );
};

const mapStateToProps = (state) => ({
  categories: state.templates.categories,
  templates: state.templates.items,
  funnelIds: state.templates.funnelIds,
});

const mapDispatchToProps = (dispatch) => ({
  getCategories: () => dispatch(fetchCategoriesAction()),
  getTemplates: () => dispatch(fetchTemplatesAction()),
  addCategory: (name) => dispatch(addCategoryAction({ name })),
  updateCategory: (name, id) => dispatch(updateCategoryAction({ name }, id)),
  deleteCategory: (id) => dispatch(deleteCategoryAction(id)),
  changeCategory: (data) => dispatch(changeCategoryAction(data)),
  updateTemplate: (data) => dispatch(updateTemplateAction(data)),
  deleteTemplate: (template) => dispatch(deleteTemplateAction(template)),
  fetchFunnelsIds: () => dispatch(fetchFunnelsIdsAction()),
  createTemplate: (id, type) => dispatch(createTemplateAction(id, type)),
  syncTemplate: (id) => dispatch(syncTemplateAction(id)),
  updateTemplateImage: (data) => dispatch(updateTemplateImageAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminTemplates);
