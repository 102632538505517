import { ReactComponent as DeleteIcon } from '../../../Components/Accounts/icons/delete.svg';
import { IPodcast } from 'types';
import { useHistory } from 'react-router-dom';
import { graphQlCall } from 'graphql/utils';
import OptionMenu from 'Components/OptionMenu/OptionMenu';
import coverImg from 'Assets/images/hardcodeCover.png';
import queries from 'graphql/queries';

import styles from './PagePodcastItem.module.scss';

enum MenuItems {
  DELETE,
}

const PagePodcastItem = ({
  item,
  fetchPodcasts,
}: {
  item: IPodcast;
  fetchPodcasts: () => void;
}) => {
  const history = useHistory();

  const menuItems = [
    {
      label: 'Delete',
      key: MenuItems.DELETE,
      icon: <DeleteIcon />,
    },
  ];

  const handleClick = () => {
    history.push(`/console/podcaster/${item._id}`);
  };

  const handleDeletePodcast = async (): Promise<void> => {
    try {
      await graphQlCall({
        queryTemplateObject: queries.DELETE_PODCAST,
        values: {
          id: item._id,
        },
        headerType: 'USER-AUTH',
      });

      fetchPodcasts();
    } catch (error) {
      console.error(error);
    }
  };

  const handleMenuClick = (e: any) => {
    e.domEvent.stopPropagation();
    switch (+e.key) {
      case MenuItems.DELETE: {
        handleDeletePodcast();
        break;
      }
    }
  };

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  return (
    <div className={styles.container} onClick={handleClick}>
      <div className={styles.infoContainer}>
        <div className={styles.image}>
          <img
            crossOrigin="anonymous"
            src={item.image || coverImg}
            alt="cover"
          />
        </div>
        <div className={styles.info}>
          <div className={styles.name}>{item.name}</div>
          <div className={styles.episodes}>
            {item.episodesCount}{' '}
            {item.episodesCount > 1 ? 'Episodes' : 'Episode'}
          </div>
        </div>
      </div>
      <OptionMenu menuProps={menuProps} />
    </div>
  );
};

export default PagePodcastItem;
