import React, { useState, useEffect } from 'react';
import closeSvg from '../../Assets/icons/close.svg';
import s from './popup.module.scss';
interface IProps {
  isOpen: boolean;
  onClose: () => void;
  isMailchimpIntegrationSuccesfull: boolean;
}
function MailchimpIntegrationSuccesfullPopup(props: IProps) {
  useEffect(() => {
    if (props.isOpen) {
      setTimeout(() => {
        handleClose();
      }, 3000);
    }
  }, [props.isOpen]);

  const handleClose = () => {
    props.onClose();
  };
  return (
    <div
      className={`${s.mailchimpIntegrationSuccesfullPopup} ${
        props.isOpen ? s.show : ''
      }`}
      style={props.isMailchimpIntegrationSuccesfull ? {} : { height: '70px' }}
    >
      <img
        className={s.closeIcon}
        src={closeSvg}
        alt="x"
        onClick={() => handleClose()}
      />
      <div></div>
      {props.isMailchimpIntegrationSuccesfull ? (
        <>
          <div className={s.mailchimpIntegrationSuccesfullPopupMainText}>
            Congratulations!
          </div>
          <p>Your Mailchimp has been connected</p>
        </>
      ) : (
        <div
          className={s.mailchimpIntegrationSuccesfullPopupMainText}
          style={{ color: 'red' }}
        >
          Connection Failed
        </div>
      )}
    </div>
  );
}

export default MailchimpIntegrationSuccesfullPopup;
