import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Popconfirm } from 'antd';
import { TEMPLATES_URL } from '../../../Constants';
import {
  createSectionAction,
  updateSectionAction,
  deleteSectionAction,
  IUpdateSectionPayload,
  ICreateSectionPayload,
} from '../../../store/sections/sectionsActions';
import CreateSection from './CreateSection';
import SecondaryButton from '../../../Styled/SecondaryStyledButton';
import EditText from '../../projects/EditableLandingText';
import UploadSectionData from './UploadSectionData';
import Image from '../ImageWithUpload';
import { RootState } from 'store/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ISection } from 'types';

type DispatchType = ThunkDispatch<RootState, void, Action>;

const StyledAdminSections = styled.section`
  padding: 20px;

  .section-item {
    display: grid;
    grid-template-columns: 120px 200px 500px;
    align-items: center;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

interface IProps {
  createSection: (data: ICreateSectionPayload) => void;
  updateSection: (data: IUpdateSectionPayload) => void;
  deleteSection: (id: string) => void;
  sections: ISection[];
}

const OtherSections = (props: IProps) => {
  const [editingSection, setEditingSection] = useState<string | null>(null);

  const handleEditName = (name: string, id: string) => {
    props.updateSection({ fields: { name }, id });
    setEditingSection(null);
  };

  const handleChangeData = (data: any, id: string) => {
    props.updateSection({
      id,
      fields: { data },
    });
  };

  const handleChangeImage = (image: File, id: string) => {
    props.updateSection({ fields: { image }, id });
  };

  return (
    <StyledAdminSections>
      <h2>Unlinked sections:</h2>
      {props.sections.map((section) => (
        <div className="section-item" key={section._id}>
          <Image
            image={
              section.image ? `${TEMPLATES_URL}/${section.image}` : undefined
            }
            onChange={(file) => handleChangeImage(file, section._id)}
          />
          <h3>
            <EditText
              text={section.name}
              size="18px"
              onClick={() => setEditingSection(section._id)}
              onEdit={(name) => handleEditName(name, section._id)}
              opened={editingSection === section._id}
            />
          </h3>
          <div className="buttons">
            <UploadSectionData
              data={section.data}
              onSubmit={(v) => handleChangeData(v, section._id)}
            />
            <Popconfirm
              title="Delete section?"
              onConfirm={() => props.deleteSection(section._id)}
            >
              <SecondaryButton>delete</SecondaryButton>
            </Popconfirm>
          </div>
        </div>
      ))}
      <hr />
      <CreateSection onSubmit={(data) => props.createSection(data)} />
    </StyledAdminSections>
  );
};

const mapDispatchToProps = (dispatch: DispatchType) => ({
  createSection: (data: ICreateSectionPayload) =>
    dispatch(createSectionAction(data)),
  updateSection: (data: IUpdateSectionPayload) =>
    dispatch(updateSectionAction(data)),
  deleteSection: (id: string) => dispatch(deleteSectionAction(id)),
});

export default connect(null, mapDispatchToProps)(OtherSections);
