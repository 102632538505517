import { useEffect, useState } from 'react';
import AudioBlock from 'UILib/AudioBlock/AudioBlock';

import styles from './SelectVoiceForm.module.scss';

interface IProps {
  handleChange: (key: string, value: string) => void;
  validation: { validate: () => boolean };
  data: { [key: string]: string | string[] };
}

const voices = [
  {
    label: 'Alloy',
    value: 'openai-alloy',
    audioUrl: 'https://cdn.autofunnel.ai/audio_samples/openai-alloy.mp3',
    image: 'https://cdn.autofunnel.ai/audio_samples/openai-alloy.jpg',
  },
  {
    label: 'Nova',
    value: 'openai-nova',
    audioUrl: 'https://cdn.autofunnel.ai/audio_samples/openai-nova.mp3',
    image: 'https://cdn.autofunnel.ai/audio_samples/openai-nova.jpg',
  },

  {
    label: 'Echo',
    value: 'openai-echo',
    audioUrl: 'https://cdn.autofunnel.ai/audio_samples/openai-echo.mp3',
    image: 'https://cdn.autofunnel.ai/audio_samples/openai-echo.jpg',
  },
  {
    label: 'Fable',
    value: 'openai-fable',
    audioUrl: 'https://cdn.autofunnel.ai/audio_samples/openai-fable.mp3',
    image: 'https://cdn.autofunnel.ai/audio_samples/openai-fable.jpg',
  },
  {
    label: 'Onyx',
    value: 'openai-onyx',
    audioUrl: 'https://cdn.autofunnel.ai/audio_samples/openai-onyx.mp3',
    image: 'https://cdn.autofunnel.ai/audio_samples/openai-onyx.jpg',
  },
  {
    label: 'Shimmer',
    value: 'openai-shimmer',
    audioUrl: 'https://cdn.autofunnel.ai/audio_samples/openai-shimmer.mp3',
    image: 'https://cdn.autofunnel.ai/audio_samples/openai-shimmer.jpg',
  },
];

const SelectVoiceForm = ({ data, handleChange, validation }: IProps) => {
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    validation.validate = () => {
      setError(!data.selectedVoice);
      return !!data.selectedVoice;
    };
  }, [validation, data.selectedVoice]);

  return (
    <>
      <div className={styles.container}>
        {voices.map((el, index) => (
          <AudioBlock
            selectable
            key={index}
            selected={data.selectedVoice === el.value}
            onClick={() => handleChange('selectedVoice', el.value)}
            audioUrl={el.audioUrl}
            imageSrc={el.image}
            label={el.label}
          />
        ))}
      </div>
      {error && (
        <div className={styles.errorMessage}>Please Select A Voice</div>
      )}
    </>
  );
};

export default SelectVoiceForm;
