import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';

import s from './Common.module.scss';

interface IProps {
  name: string;
  onClick: () => void;
}

const AddButton = (props: IProps) => {
  return (
    <div className={s.addButtonSection}>
      <button onClick={() => props.onClick()} className={s.addButton}>
        <PlusIcon fill="#6c68ff" />
        &nbsp;&nbsp;
        {props.name}
      </button>
    </div>
  );
};

export default AddButton;
