import styled from 'styled-components';
import { Input } from 'antd';
import { colors } from './constants';

export default styled(Input)`
  width: 100%;
  height: 50px;
  border-radius: 2px;

  input {
    font-size: 14px;
    font-weight: 400;
    padding-left: 17px;

    &::placeholder {
      color: ${colors.placeHolder};
    }
  }
`;
