import SectionGenerationElement from 'UILib/SectionGenerationElement/SectionGenerationElement';
import styles from './SectionCoverGeneration.module.scss';
import { CONSULT_URL } from 'Constants';
import { ReactComponent as ArrowLeft } from 'Assets/arrow-left-back.svg';
import { ReactComponent as PodcastIcon } from 'Assets/icons/podcast.svg';
import ButtonUI from 'UILib/Button/Button';
import { useEffect, useState } from 'react';
import CoverEditor from 'CoverEditor/CoverEditor';
import ColorScheme from 'UILib/ColorScheme/ColorScheme';
import DragAndDrop from 'UILib/DragAndDrop/DragAndDrop';
import {
  getTemplate,
  getTemplateById,
} from 'CoverEditor/PodcastEpisodesCoverData';
import Loader from 'UILib/Loader/Loader';

export interface ISection {
  title: string;
  notes?: string;
}

export interface ICoverTemplate {
  templateId: string;
  colorSchemeId: string;
}

interface IProps {
  title: string;
  episodeNumber: number;
  podcastName: string;
  coverImages: string[];
  sections: ISection[];
  defaultCoverTemplate?: ICoverTemplate;
  onPreviousPage: () => void;
  onGenerate: () => void;
  handleChangeCover: (data: any) => void;
  onSectionsChange: (sections: ISection[]) => void;
  loading: boolean;
}

const SectionCoverGeneration = ({
  title,
  coverImages,
  onPreviousPage,
  sections,
  onSectionsChange,
  onGenerate,
  handleChangeCover,
  loading,
  episodeNumber,
  defaultCoverTemplate,
  podcastName,
}: IProps) => {
  const [coverDataCatalog, setCoverDataCatalog] = useState<any[]>([]);
  const [coverData, setCoverData] = useState<any>(null);
  const [selectedCoverDataIndex, setSelectedCoverDataIndex] = useState(0);
  const [coverEditorScale, setCoverEditorScale] = useState(1.0);
  const width = 400;
  const height = 400;
  const thumbnailScaleFactor = 0.1666;

  useEffect(() => {
    if (coverImages) {
      const catalog: any = [];
      let index = 0;

      if (defaultCoverTemplate && coverImages.length > 0) {
        const defaultTemplate = getTemplateById(
          defaultCoverTemplate.templateId
        );
        const d = {
          ...defaultTemplate,
          image: coverImages[0],
          index: index,
          selectedColors: Number(defaultCoverTemplate.colorSchemeId),
        };
        catalog.push(d);
        index += 1;
      }

      //process images in to templates
      const _data = getTemplate();
      for (const image of coverImages) {
        for (const data of _data) {
          const d = {
            ...data,
            image: image,
            index: index,
            selectedColors: index % data.colors.length,
          };
          catalog.push(d);
          index += 1;
        }
      }

      setCoverData(catalog[0]);
      setCoverDataCatalog(catalog);
    }
  }, [coverImages]);

  useEffect(() => {
    handleChangeCover(coverData);
  }, [coverData]);

  const handleClickOnCover = (data: any) => {
    setSelectedCoverDataIndex(data.index);
    setCoverData({ ...data });
  };

  const handleClickOnColor = (colors: string[], index?: number) => {
    const data = { ...coverData, selectedColors: index };
    setCoverData(data);
  };

  const handleSectionTitleChange = (indicator: number, value: string) => {
    sections[indicator - 1].title = value;
    onSectionsChange(sections);
  };

  const handleSectionNoteChange = (indicator: number, value: string) => {
    sections[indicator - 1].notes = value;
    onSectionsChange(sections);
  };

  const redirectToPodcastMenu = () => {
    window.open(`${CONSULT_URL}/console/podcaster`, '_self');
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.scrollContainer}>
          <div className={styles.backButton} onClick={redirectToPodcastMenu}>
            <ArrowLeft />
            Dashboard
          </div>
          
          {sections && (
            <div className={styles.sections}>
              <div className={styles.label}> Episode Title</div>
              <div className={styles.title}>{title}</div>
              <div className={styles.label}> Sections </div>

              <DragAndDrop
                droppableId="sections"
                elements={sections}
                onDragEnd={(orderedSections) => {
                  onSectionsChange(orderedSections as ISection[]);
                }}
                draggableNodes={sections.map((section, index) => (
                  <SectionGenerationElement
                    indicator={index + 1}
                    onSectionNoteChange={handleSectionNoteChange}
                    onSectionTitleChange={handleSectionTitleChange}
                    notes={section.notes}
                    text={section.title}
                  />
                ))}
              />
            </div>
          )}
        </div>
        <div className={styles.buttonsContainer}>
          <ButtonUI
            width={160}
            height={50}
            appearance="stroke"
            prefixIcon={<ArrowLeft />}
            onClick={onPreviousPage}
            disabled={loading}
          >
            Back
          </ButtonUI>
          <ButtonUI
            width={160}
            height={50}
            appearance="highlighted"
            postfixIcon={!loading && <PodcastIcon />}
            onClick={onGenerate}
            disabled={loading}
          >
            {loading ? <Loader size={16} color="#ffffff" /> : 'Generate'}
          </ButtonUI>
        </div>
      </div>
      <div className={styles.cover}>
        {coverData ? (
          <>
            <div>
              <CoverEditor
                titles={[title, podcastName, `Episode: ${episodeNumber}`]}
                width={width * coverEditorScale}
                height={height * coverEditorScale}
                data={coverData}
                readOnly={true}
              />

              <div className={styles.colorScheme}>
                {coverData.colors &&
                  (coverData.colors as any[]).map((item, i) => (
                    <ColorScheme
                      key={i}
                      colors={item}
                      index={i}
                      onClick={handleClickOnColor}
                      isSelected={i === coverData.selectedColors}
                    />
                  ))}
              </div>
            </div>

            <div className={styles.thumbnails} style={{ height: height }}>
              {coverDataCatalog.map((item, i) => (
                <div className={styles.thubmnail}>
                  {selectedCoverDataIndex === i ? (
                    <>
                      <div
                        className={
                          styles.selectedFrame + ' ' + styles.zoomFadeAnimate
                        }
                      />
                      <div className={styles.selectedFrame} />
                    </>
                  ) : null}
                  <CoverEditor
                    titles={[title]}
                    onChangeData={handleClickOnCover}
                    width={width * thumbnailScaleFactor}
                    height={height * thumbnailScaleFactor}
                    readOnly={true}
                    data={item}
                  />
                </div>
              ))}
            </div>
          </>
        ) : (
          <div>
            <Loader size={24} color="#ffffff" />
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionCoverGeneration;
