import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import EditText from '../../projects/EditableLandingText';
import { Popconfirm } from 'antd';
import SecondaryButton from '../../../Styled/SecondaryStyledButton';
import { TEMPLATES_URL, DATA_URL } from '../../../Constants';
import { ICategory, ITemplateItem } from 'types';

const StyledTemplatesList = styled.div`
  .template-list {
    list-style: none;

    .template-item {
      display: grid;
      grid-template-columns: 100px 200px auto 250px 220px;
      align-items: center;
      margin-bottom: 20px;

      .template-actions {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
      }

      .image-block {
        cursor: pointer;

        .image {
          width: 100px;
          height: 50px;
          margin-right: 10px;
          border: 1px #777 solid;
          border-radius: 5px;
          overflow: hidden;

          > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .sync {
        display: flex;
        align-items: center;
        justify-content: center;

        .funnel {
          color: #000;
          font-size: 11px;
          width: 150px;
        }

        .buttons {
          margin-left: 25px;
        }
      }
    }
  }
`;

interface IChangeCategoryData {
  template: ITemplateItem;
  categoryId: string;
}

interface IUpdateTemplateData {
  template: ITemplateItem;
  fields?: {
    [key: string]: any;
  };
  file?: File;
}

interface IUpdateImageData {
  template: ITemplateItem;
  file: File;
}

interface IProps {
  onChangeCategory: (data: IChangeCategoryData) => void;
  onSetEdited: (id: string) => void;
  onUpdateTemplate: (data: IUpdateTemplateData) => void;
  templates: ITemplateItem[];
  onSyncTemplate: (templateId: string) => void;
  onUpdateTemplateImage: (data: IUpdateImageData) => void;
  categories: ICategory[];
  editingTemplate: string;
  onDeleteTemplate: (template: ITemplateItem) => void;
}

const TemplatesList = (props: IProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [
    templateForUpload,
    setTemplateForUpload,
  ] = useState<ITemplateItem | null>(null);

  const handleChangeCategory = (
    template: ITemplateItem,
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const categoryId = e.target.value;
    props.onChangeCategory({ template, categoryId });
  };

  const updateTemplateName = (name: string, template: ITemplateItem) => {
    props.onSetEdited('');
    props.onUpdateTemplate({
      fields: { name },
      template,
    });
  };

  // const updateTemplateDescription = (description: string, id: string) => {
  //   props.onSetEdited('');
  //   props.onUpdateTemplate({
  //     fields: { description },
  //     id
  //   });
  // }

  interface IGetImagePayload {
    schemeImage?: string;
    image?: string;
    bigImage?: string;
    smallImage?: string;
    type: string;
  }

  const getImage = ({
    schemeImage,
    image,
    bigImage,
    smallImage,
    type,
  }: IGetImagePayload) => {
    const templateImage = schemeImage || image || bigImage || smallImage;
    if (templateImage) {
      if (templateImage.includes('http')) {
        return <img src={`${templateImage}`} alt="template" />;
      }
      if (!templateImage.includes('pageImages')) {
        return <img src={`${TEMPLATES_URL}/${templateImage}`} alt="template" />;
      } else {
        return <img src={`${DATA_URL}/${templateImage}`} alt="ai-page" />;
      }
    } else {
      return <img src="/console/assets/template.png" alt="screenshot" />;
    }
  };

  const handleImageClick = (template: ITemplateItem) => {
    setTemplateForUpload(template);
    if (inputRef?.current) {
      inputRef.current.click();
    }
  };

  const handleFileUpload = () => {
    if (!inputRef?.current) {
      return;
    }
    const files = inputRef.current.files;
    if (!files?.length) {
      return;
    }
    let file = files[0];
    if (file && templateForUpload) {
      props.onUpdateTemplateImage({ template: templateForUpload, file });
      setTemplateForUpload(null);
    }
  };

  return (
    <StyledTemplatesList>
      <ul className="template-list">
        {props.templates.map((template) => (
          <li key={template._id} className="template-item">
            <div className="image-block">
              <div className="image" onClick={() => handleImageClick(template)}>
                {getImage(template)}
              </div>
            </div>
            <h3>
              <EditText
                text={template.name}
                size="16px"
                onClick={() => props.onSetEdited(template._id)}
                onEdit={(value) => updateTemplateName(value, template)}
                opened={props.editingTemplate === template._id}
              />
            </h3>
            <h4>{template.type}</h4>
            <div>
              {template.funnel && (
                <div className="sync">
                  <span className="funnel">{template.funnel}</span>
                  <Popconfirm
                    title="sync with funnel?"
                    onConfirm={() => props.onSyncTemplate(template._id)}
                  >
                    <div className="buttons">
                      <SecondaryButton style={{ width: '50px' }}>
                        SYNC
                      </SecondaryButton>
                    </div>
                  </Popconfirm>
                </div>
              )}
              {template.page && (
                <div className="sync">
                  <span className="funnel">{template.page}</span>
                </div>
              )}
            </div>
            <div className="template-actions">
              <Popconfirm
                title="Delete template?"
                onConfirm={() => props.onDeleteTemplate(template)}
              >
                <SecondaryButton style={{ width: '80px' }}>
                  DELETE
                </SecondaryButton>
              </Popconfirm>
              <select
                onChange={(e) => handleChangeCategory(template, e)}
                defaultValue={template.category}
              >
                <option key={0} value={0}>
                  without category
                </option>
                {props.categories.map((category) => (
                  <option
                    value={category._id}
                    key={category._id}
                    defaultChecked={category._id === template.category}
                  >
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
          </li>
        ))}
      </ul>
      <input
        type="file"
        style={{ display: 'none' }}
        accept="image/*"
        ref={inputRef}
        onChange={() => handleFileUpload()}
      />
    </StyledTemplatesList>
  );
};

export default TemplatesList;
