import { useEffect, useState } from 'react';
import Input from 'UILib/Input/Input';
import Dropdown from 'UILib/Dropdown/Dropdown';

import styles from './CreatePodcastForm.module.scss';

const designs = [
  { label: 'Organic', value: 'Organic' },
  { label: 'Noir', value: 'Noir' },
  { label: 'Tattoo', value: 'Tattoo' },
  { label: 'Comic Book', value: 'Comic Book' },
  { label: 'Vintage Newspaper', value: 'Vintage Newspaper' },
  { label: 'Bohemian', value: 'Bohemian' },
  { label: 'Retro 80s', value: 'Retro 80s' },
  { label: 'Chalkboard', value: 'Chalkboard' },
  { label: 'Graffiti', value: 'Graffiti' },
  { label: 'Pop Art', value: 'Pop Art' },
  { label: 'Sci-Fi', value: 'Sci-Fi' },
  { label: 'Grunge', value: 'Grunge' },
  { label: 'Steampunk', value: 'Steampunk' },
  { label: 'Cyberpunk', value: 'Cyberpunk' },
  { label: 'Gothic', value: 'Gothic' },
  { label: 'Modern Flat', value: 'Modern Flat' },
  { label: 'Anime', value: 'Anime' },
  { label: 'Country Western', value: 'Country Western' },
  { label: 'Neon', value: 'Neon' },
  { label: 'Black & White', value: 'Black & White' },
  { label: 'Hand-Drawn Sketch', value: 'Hand-Drawn Sketch' },
  { label: 'Stencil Art', value: 'Stencil Art' },
  { label: 'Fantasy', value: 'Fantasy' },
  { label: 'Folk Art', value: 'Folk Art' },
  { label: 'Futuristic', value: 'Futuristic' },
  { label: 'Pastel', value: 'Pastel' },
  { label: 'Rustic', value: 'Rustic' },
  { label: 'Industrial', value: 'Industrial' },
  { label: 'Monochrome', value: 'Monochrome' },
  { label: '8-Bit Pixel Art', value: '8-Bit Pixel Art' },
  { label: 'Polaroid', value: 'Polaroid' },
  { label: 'Vintage Travel Poster', value: 'Vintage Travel Poster' },
  { label: 'Hollywood Glamour', value: 'Hollywood Glamour' },
  { label: 'Woodcut', value: 'Woodcut' },
  { label: 'Doodle Art', value: 'Doodle Art' },
  { label: 'Whimsical', value: 'Whimsical' },
  { label: 'Zen', value: 'Zen' },
];

interface IProps {
  handleChange: (key: string, value: string) => void;
  validation: { validate: () => boolean };
  data: { [key: string]: string | string[] };
}

const CreatePodcastForm = ({ handleChange, validation, data }: IProps) => {
  const [error, setError] = useState({
    name: '',
    host: '',
  });

  useEffect(() => {
    validation.validate = () => {
      let errorsObj = {
        name: '',
        host: '',
      };

      if (!data.podcastName) {
        errorsObj = {
          ...errorsObj,
          name: 'Podcast Name Is Required',
        };
      }
      if (!data.podcastHostName) {
        errorsObj = {
          ...errorsObj,
          host: 'Host Is Required',
        };
      }

      setError(errorsObj);
      return !!data.podcastName && !!data.podcastHostName;
    };
  }, [validation, data.podcastName, data.podcastHostName]);

  return (
    <div className={styles.container}>
      <div>
        Name Your Podcast:
        <Input
          value={data.podcastName}
          className={styles.input}
          border="stroke"
          placeholder="What's your podcast called?"
          onChange={(e) => handleChange('podcastName', e.target.value)}
          error={error.name}
        />
      </div>
      <div>
        Name Your Host
        <Input
          value={data.podcastHostName}
          className={styles.input}
          error={error.host}
          border="stroke"
          placeholder="Host Name"
          onChange={(e) => handleChange('podcastHostName', e.target.value)}
        />
      </div>
      <div>
        Cover Design
        <Dropdown
          type="stroke"
          label="Select Design"
          value={data.podcastCoverDesign}
          options={designs}
          onChange={(newValue) =>
            handleChange('podcastCoverDesign', newValue as string)
          }
        />
      </div>
    </div>
  );
};

export default CreatePodcastForm;
