import styled from 'styled-components';
import { colors } from './constants';

interface IProps {
  disabled?: boolean;
}

export default styled.button`
  margin: 5px;
  width: 90px;
  height: 30px;
  border: none;
  background: ${colors.primary};
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  cursor: ${(props: IProps) => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background: ${colors.primaryHovered};
  }
`;
