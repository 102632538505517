import { truncateString } from 'utils/helpers';
import { IAccount } from '../types';
import { ReactComponent as ClockIcon } from '../icons/clock.svg';
import AccountMenu from './AccountMenu';
import BlancThumbnailIcon from 'Assets/icons/blankThumbnail.svg';

import s from './AgenciesManage.module.scss';

interface IProps {
  account: IAccount;
  onStartEdit: () => void;
  onStartTransfer: () => void;
  onStartDelete: () => void;
  onClickOpenedMenu: () => void;
  active: boolean;
  openMenu: boolean;
  onCloseMenu: () => void;
  onSetActive: () => void;
  isUserCard: boolean;
}

const AccountCard = (props: IProps) => {
  return (
    <aside
      className={`${s.accountCard} ${props.active ? s.active : ''}`}
      onClick={props.onSetActive}
    >
      <div className={s.cardContent}>
        <div className={s.info}>
          <div className={s.thumbnailBlock}>
            {props.account?.thumbnail ? (
              <img
                src={props.account.thumbnail}
                alt="thumbnail"
                className={s.thumbnail}
              />
            ) : (
              <div>
                <img src={BlancThumbnailIcon} alt="thumbnail" />
              </div>
            )}
          </div>
          <div className={s.textContent}>
            <p className={s.name}>
              {props.account?.workspace || props.account?.name || 'no name'}
              {props.account?.requestedAgencyId && (
                <ClockIcon className={s.clocks} />
              )}
            </p>
            <p className={s.description}>
              {truncateString(props.account?.notes)}
            </p>
          </div>
        </div>
        <AccountMenu
          onEdit={() => props.onStartEdit()}
          onTransfer={() => props.onStartTransfer}
          onDelete={() => props.onStartDelete()}
          isUserCard={props.isUserCard}
        />
      </div>
    </aside>
  );
};

export default AccountCard;
