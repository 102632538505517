import styled from 'styled-components';

interface IProps {
  visible: boolean;
  background?: string;
  padding?: string;
}

export default styled.div`
  display: ${(props: IProps) => (props.visible ? 'block' : 'none')};
  width: 100%;
  height: 100vh;
  /* overflow: hidden; */
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2;
  font-family: 'Roboto';

  .modal-block {
    box-shadow: 1px 0px 30px rgb(0, 0, 0, 0.3);
    width: 100%;
    max-height: 100%;

    max-width: 860px;
    background: ${(props) => props.background || '#E5E5E5'};
    margin: 50px auto;
    position: relative;
    color: #000;
    overflow: hidden;

    .close-icon {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }

    .modal-header {
      .modal-title {
        text-align: center;
        font-size: 32px;
        margin: 0;
        padding-top: 40px;
      }

      .modal-description {
        font-size: 12px;
        width: 310px;
        margin: 10px auto 0 auto;
        text-align: center;
        line-height: 150%;
      }
    }

    .modal-body {
      // height: calc(100vh - 200px);
      overflow-y: auto;
      padding: ${(props) => props.padding || '50px'};
    }
  }
`;
