import clsx from 'clsx';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import funnelPreview from 'Assets/images/funnelPreview.svg';
import { ReactComponent as ProfileIcon } from 'Assets/icons/profile.svg';
import { ReactComponent as PagesIcon } from 'Assets/icons/pages.svg';
import { ReactComponent as CartIcon } from 'Assets/icons/cart.svg';
import { ReactComponent as EyeIcon } from 'Components/Admin/AdminAiTemplates/Assets/eye.svg';
import { DATA_URL } from 'Constants';

import styles from './StatisticsTable.module.scss';

export interface DataType {
  key: React.Key;
  pageImage: string;
  pageName: string | '-';
  viewsAll: [number | '-'];
  viewsUnique: [number | '-'];
  viewsAvg: [string | '-'];
  leadsTotal: [number | '-'];
  leadsConv: [string | '-'];
  leadsAvg: [string | '-'];
  salesTotal: [number | '-'];
  salesConv: [string | '-'];
  salesAvg: [string | '-'];
  salesTotalValue: [string | '-'];
}

const TableCell = (data: Array<number | string>) => {
  return (
    <div className={styles.tableCell}>
      {data.length > 1 ? (
        <>
          <div className={styles.currentValue}>{data[1]}</div>
          <div className={styles.oldValue}>{data[0]}</div>
        </>
      ) : (
        <div className={styles.currentValue}>{data[0]}</div>
      )}
    </div>
  );
};

const columns: ColumnsType<DataType> = [
  {
    title: () => (
      <div className={clsx(styles.title, styles.pagesTitle)}>
        <PagesIcon width={16} height={16} />
        Pages
      </div>
    ),
    align: 'center',
    className: clsx(styles.pagesColumnColor, styles.columnHeader),
    children: [
      {
        dataIndex: 'pageImage',
        key: 'pageImage',
        fixed: 'left',
        width: 120,
        onHeaderCell: () => ({
          style: { padding: 0, borderRight: 'none' },
        }),
        className: clsx(styles.imageCell, styles.pagesColumnColor),
        render: (text) => (
          <img
            className={styles.previewImage}
            src={text ? `${DATA_URL}/${text}` : funnelPreview}
            alt="preview"
          />
        ),
      },
      {
        dataIndex: 'pageName',
        key: 'pageName',
        width: 130,
        className: styles.pagesColumnColor,
        onHeaderCell: () => ({
          style: { padding: 0 },
        }),
      },
    ],
  },
  {
    title: () => (
      <div className={styles.title}>
        <EyeIcon width={16} height={14} />
        Page Views
      </div>
    ),
    align: 'center',
    className: clsx(styles.viewsColumnColor, styles.columnHeader),
    children: [
      {
        title: () => <span className={styles.subTitle}>All</span>,
        align: 'center',
        className: styles.viewsColumnColor,
        onHeaderCell: () => ({
          style: { padding: 0, borderRight: 'none' },
        }),
        render: TableCell,
        dataIndex: 'viewsAll',
        key: ' viewsAll',
      },
      {
        title: () => <span className={styles.subTitle}>Unique</span>,
        align: 'center',
        className: styles.viewsColumnColor,
        onHeaderCell: () => ({
          style: { padding: 0, borderRight: 'none' },
        }),
        render: TableCell,
        dataIndex: 'viewsUnique',
        key: 'viewsUnique',
      },
      {
        title: () => <span className={styles.subTitle}>Avg Value</span>,
        align: 'center',
        className: styles.viewsColumnColor,
        onHeaderCell: () => ({
          style: { padding: 0 },
        }),
        render: TableCell,
        dataIndex: 'viewsAvg',
        key: 'viewsAvg',
      },
    ],
  },
  {
    title: () => (
      <div className={styles.title}>
        <ProfileIcon width={14} height={16} fill="#a0a0a0" />
        Leads
      </div>
    ),
    align: 'center',
    className: clsx(styles.leadsColumnColor, styles.columnHeader),
    children: [
      {
        title: () => <span className={styles.subTitle}>Total</span>,
        align: 'center',
        className: styles.leadsColumnColor,
        onHeaderCell: () => ({
          style: { padding: 0, borderRight: 'none' },
        }),
        render: TableCell,
        dataIndex: 'leadsTotal',
        key: ' leadsTotal',
      },
      {
        title: () => <span className={styles.subTitle}>Conv. Rate</span>,
        align: 'center',
        className: styles.leadsColumnColor,
        onHeaderCell: () => ({
          style: { padding: 0, borderRight: 'none' },
        }),
        render: TableCell,
        dataIndex: 'leadsConv',
        key: 'leadsConv',
      },
      {
        title: () => <span className={styles.subTitle}>Avg Value</span>,
        align: 'center',
        className: styles.leadsColumnColor,
        onHeaderCell: () => ({
          style: { padding: 0 },
        }),
        render: TableCell,
        dataIndex: 'leadsAvg',
        key: 'leadsAvg',
      },
    ],
  },
  {
    title: () => (
      <div className={styles.title}>
        <CartIcon width={16} height={16} />
        Sales
      </div>
    ),
    align: 'center',
    className: clsx(styles.salesColumnColor, styles.columnHeader),
    children: [
      {
        title: () => <span className={styles.subTitle}>Total</span>,
        align: 'center',
        className: styles.salesColumnColor,
        onHeaderCell: () => ({
          style: { padding: 0, borderRight: 'none' },
        }),
        render: TableCell,
        dataIndex: 'salesTotal',
        key: ' salesTotal',
      },
      {
        title: () => <span className={styles.subTitle}>Conv. Rate</span>,
        align: 'center',
        className: styles.salesColumnColor,
        onHeaderCell: () => ({
          style: { padding: 0, borderRight: 'none' },
        }),
        render: TableCell,
        dataIndex: 'salesConv',
        key: 'salesConv',
      },
      {
        title: () => <span className={styles.subTitle}>Avg Value</span>,
        align: 'center',
        className: styles.salesColumnColor,
        onHeaderCell: () => ({
          style: { padding: 0, borderRight: 'none' },
        }),
        render: TableCell,
        dataIndex: 'salesAvg',
        key: 'salesAvg',
      },
      {
        title: () => <span className={styles.subTitle}>Total Value</span>,
        align: 'center',
        className: styles.salesColumnColor,
        onHeaderCell: () => ({
          style: { padding: 0 },
        }),
        render: TableCell,
        dataIndex: 'salesTotalValue',
        key: 'salesTotalValue',
      },
    ],
  },
];

interface IProps {
  data: DataType[];
}

const StatisticsTable = ({ data }: IProps) => {
  return (
    <Table
      className={styles.table}
      columns={columns}
      dataSource={data}
      bordered
      rowClassName={styles.tableRow}
      size="middle"
      pagination={false}
    />
  );
};

export default StatisticsTable;
